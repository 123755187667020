import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../axios';
import auth from './auth.module'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from "@vuex-orm/plugin-axios";
import Device, {device} from "@/store/models/device.model";
import DeviceState, {deviceState} from "@/store/models/device-state.model";
import CallRegister, {callRegister} from "@/store/models/call-register.model";
import Contact, {contact} from "@/store/models/contact.model";
import Chat from "@/store/models/chat.model";
import ChatMessage, {chatMessage} from "@/store/models/chat-message.model";
import Relevance, {relevance} from "@/store/models/relevance.model";
import Transcription from "@/store/models/transcription.model";
import Synthesis from '@/store/models/synthesis.model';
import File, {file} from "@/store/models/file.model";
import Console, {consoleModule} from "@/store/models/console.model";
import ConsoleCommand from "@/store/models/console-command.model";
import socket from './socket.module'
import TranscriptionEvent from "@/store/models/relevance-event.model";
import Sensor from "@/store/models/sensor.model";
import GpsPosition from "@/store/models/gps-position.model";
import Schedule from "@/store/models/schedule.model";
import Keylog from "@/store/models/keylog.model";
import Notification, {notification} from "@/store/models/notification.model";
import WiFi from "@/store/models/wifi.model";
import User from "@/store/models/user.model";
import BatteryRegister from "@/store/models/battery-register.model";
import TrafficRegister from "@/store/models/traffic-register.model";
import {PAGE_LOG_UPDATE,NOTIFICATION_UPDATE} from "@/store/actions.type";
import { BehaviorSubject } from 'rxjs';
Vue.use(Vuex)
VuexORM.use(VuexORMAxios, {axios})

const database = new VuexORM.Database()
database.register(Device, device);
database.register(DeviceState, deviceState);
database.register(CallRegister, callRegister);
database.register(Contact, contact);
database.register(Chat);
database.register(ChatMessage);
database.register(Relevance, relevance);
database.register(Transcription);
database.register(Synthesis);
database.register(TranscriptionEvent);
database.register(File, file);
database.register(Console, consoleModule);
database.register(ConsoleCommand);
database.register(Sensor);
database.register(GpsPosition);
database.register(Schedule);
database.register(Keylog);
database.register(Notification, notification);
database.register(WiFi);
database.register(User);
database.register(BatteryRegister);
database.register(TrafficRegister);
const jsonDataSubject = new BehaviorSubject(null);
const notificationSubject = new BehaviorSubject(null);
export default new Vuex.Store({
  state: {
    jsonData: jsonDataSubject.asObservable(),
    notificationData: notificationSubject.asObservable()
  },
  mutations: {
    SET_JSON_DATA(state, data) {
      state.jsonData = data;
    },
    SET_NOTIFICATION_DATA(state, data) {
      console.log('set notification data', data)
      state.notificationData = data;
    }
  },
  actions: {
    [PAGE_LOG_UPDATE](context, params){
      jsonDataSubject.next(params.data.data);
    },
    [NOTIFICATION_UPDATE](context, params){
      notificationSubject.next(params.data);
    }
  },
  modules: {
    auth,
    socket,
    consoleModule,
    file,
    deviceState,
    notification,
    callRegister,
    contact,
    relevance,
    chatMessage
  },
  plugins: [VuexORM.install(database)]
})
