<template>
	<div>
		<v-row class="pa-3">
			<v-col cols="9" class="d-flex justify-start align-center">
				<v-card @click="filterCall(null)" class="mx-4" style="width: 15%">
					<v-card-title>
						<v-icon color="grey darken-1" class="mr-1">mdi-playlist-check</v-icon> Tutte
					</v-card-title>
					<v-card-text>
						{{totalLength}} chiamate
					</v-card-text>
				</v-card>
				<v-card @click="filterCall(service)" v-for="(service,index) in services" class="mx-4" style="width: 15%" :key="index">
					<v-card-title>
						<v-icon :color="service.call_register_service | chatServiceColor" class="mr-1">{{service.call_register_service | chatServiceIcon}}</v-icon> {{service.call_register_service | callService}}
					</v-card-title>
					<v-card-text>
						{{service.count}} chiamate
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row align="center" class="pa-4">
			<v-col cols="6" v-if="callRegister" align-self="start">
				<v-card>
					<v-card-title>
						<v-icon class="ma-1">mdi-phone</v-icon>
						Chiamate recenti
						<v-spacer></v-spacer>
						Ordina:
						<v-icon class="ml-1" @click="toggleSort()">{{ `mdi-sort-clock-${sortOrder === 'ASC' ? 'descending' : 'ascending'}` }}</v-icon>
						<v-btn color="green"
									 block
									 @click="exportWord()"
									 :loading="exportWordLoad"
									 class="btn-export">
							<v-icon class="font-size__10px">mdi-file-word</v-icon>
						</v-btn>
					</v-card-title>
					<template>
						<v-col cols="">
							<div style="display: flex;align-items: center;height:0px;">
								<span>
									Seleziona tutti
								</span>
								<v-checkbox :label="''"
														color="primary"
														@click.stop
														@change="selectAllCall"
														v-model="selectAll" />
							</div>

						</v-col>
						<v-container>
							<v-list>
								<v-list-item-group>
									<template v-for="(item, index) in callRegister">
										<v-divider :key="'d'+item.id"></v-divider>
										<v-list-item @click.prevent="selectCall(item)">
											<v-checkbox :label="''"
																	color="primary"
																	@click.stop
																	:value="callIDS.includes(item.id) || selectAll"
																	@change="selectSingleCall(index, item.id)" />
											<v-list-item-avatar size="50px">
												<v-icon size="45px" :color="item.service | chatServiceColor" class="mr-1">
													{{ item.service | chatServiceIcon }}
												</v-icon>
											</v-list-item-avatar>
											<v-list-item-content>
												<v-list-item-title>
													{{ item.contact.known ? item.contact.name : 'Contatto sconosciuto' }}
												</v-list-item-title>
												<v-list-item-subtitle>
													{{ item.contact.phone }}
												</v-list-item-subtitle>
												<v-list-item-subtitle>
													<span :class="{lost: item.lost}">
														<v-icon>{{ item.type | comDirectionIcon }}</v-icon>
														Chiamata {{item.type | comDirectionLabel}}
														<span v-if="item.lost">(persa)</span>
													</span>
												</v-list-item-subtitle>
											</v-list-item-content>
											<v-list-item-action>
												<span class="text-caption">{{ getDataDifference(item.created_at) }}</span>
												<v-btn @click.prevent="selectCall(item)" icon>
													<v-icon color="grey lighten-1">mdi-information</v-icon>
												</v-btn>
											</v-list-item-action>
										</v-list-item>
									</template>
								</v-list-item-group>
							</v-list>
						</v-container>
					</template>
					<v-pagination v-model="page"
												:total-visible="10"
												@input="getNewData"
												:length="Math.ceil(totalLengthPagionation / 10)"
												prev-icon="mdi-menu-left"
												next-icon="mdi-menu-right">
					</v-pagination>
				</v-card>
			</v-col>
			<v-col cols="4" align-self="start">
				<v-subheader><v-icon class="ma-1">mdi-phone</v-icon>Dettagli chiamata</v-subheader>
				<v-divider></v-divider>
				<v-card v-if="!selectedCall">
					<v-card-text>
						Seleziona una chiamata dal registro per visualizzarne i dettagli
					</v-card-text>
				</v-card>
				<v-expand-transition>
					<v-card v-if="selectedCall">
						<v-list>
							<v-list-item>
								<v-row>
									<v-col cols="6">Inizio chiamata</v-col>
									<v-col cols="6" class="text-caption">
										<span>
											{{new Date(selectedCall.created_at).toLocaleString()}}
										</span>
									</v-col>
								</v-row>
							</v-list-item>

							<v-list-item v-if="!selectedCall.lost && selectedCall.ended_at">
								<v-row>
									<v-col cols="6">Fine chiamata</v-col>
									<v-col cols="6" class="text-caption">
										<span>
											{{new Date(selectedCall.ended_at).toLocaleString()}}
										</span>
									</v-col>
								</v-row>
							</v-list-item>

							<v-list-item v-if="!selectedCall.lost">
								<v-row>
									<v-col cols="6">Durata</v-col>
									<v-col cols="6" class="text-caption">
										<span>
											{{selectedCall.duration}}
										</span>
									</v-col>
								</v-row>
							</v-list-item>
						</v-list>
					</v-card>
				</v-expand-transition>

				<v-expand-transition>
					<v-card v-if="selectedCall">
						<v-subheader><v-icon class="ma-1">mdi-account</v-icon>Dettagli contatto</v-subheader>
						<v-divider></v-divider>
						<v-list-item>
							<v-row>
								<v-col cols="6">Nome</v-col>
								<v-col cols="6" class="text-caption">
									<span v-if="selectedCall.contact.known">{{selectedCall.contact.name}}</span>
									<span v-else>Sconosciuto<br />(id: {{selectedCall.contact.name}})</span>
								</v-col>
							</v-row>
						</v-list-item>
						<v-list-item>
							<v-row>
								<v-col cols="6">Telefono</v-col>
								<v-col cols="6" class="text-caption">
									<span>{{selectedCall.contact.phone}}</span>
								</v-col>
							</v-row>
						</v-list-item>
					</v-card>
				</v-expand-transition>

				<v-expand-transition>
					<v-card v-if="selectedCall">
						<v-subheader><v-icon class="ma-1">mdi-account-details</v-icon>Attività contatto</v-subheader>
						<v-divider></v-divider>
						<contact-activity-info :contact="selectedCall.contact"></contact-activity-info>
					</v-card>
				</v-expand-transition>

				<v-divider></v-divider>

				<v-card v-if="selectedCall?.relevanceId">
					<v-btn :to="`/device/relevance/${selectedCall.relevanceId}`">Vai alla pagina dell'acquisizione</v-btn>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Avatar from 'vue-avatar';
import ContactActivityInfo from "@/components/contact/ContactActivityInfo";
import DeviceInfo from "@/components/device/DeviceInfo";
import CallRegister from "@/store/models/call-register.model";
import {apiUrl} from "@/common/endpoint.const";
import moment from "moment";
import {addOneHour} from "@/common/utils";
import axios from '../../axios';
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  ImageRun,
  AlignmentType,
  TableCell,
  TableRow,
  Table,
} from "docx";
export default {
  name: "DeviceCallRegister",
  data(){
    return {
      selectedCall: null,
      sortOrder: 'DESC',
      sortField: 'name',
      searchField: '',
      contact: [],
      page: 1,
      selectAll: false,
      apiUrl,
      serviceFilter: "NULL",
      callRegister: [],
      callIDS: [],
      services: [],
      exportWordLoad: false,
      totalLength: 0,
      totalLengthPagionation: 0,
    }
  },
  computed: {
    // calls(){
    //   if(this.device && this.device.callRegister){
    //     return this.device.callRegister.filter(call => this.serviceFilter ? call.service === this.serviceFilter : true)
    //   }
    //   return [];
    // },
  },
  components: {
    Avatar,
    ContactActivityInfo,
    DeviceInfo,
  },
  methods: {
    ProxyAddOneHour(date){
      return addOneHour(date);
    },
    getDataDifference(date1) {
      const dateCall = new Date(date1);
      const today = new Date();
      const ms = 1000, s = 60, m = 60, h = 24;

      const diffTime = Math.abs(today - dateCall); // Differenza in millisecondi
      const diffDays = Math.floor(diffTime / (h * m * s * ms));
      const diffHours = Math.floor(diffTime / (m * s * ms));
      const diffMinutes = Math.floor(diffTime / (s * ms));
      
      let returnString = '';
      if (diffDays > 0) {
          returnString = `${diffDays} giorni fa`;
      } else if (diffHours > 0) {
          returnString = `${diffHours} ore fa`;
      } else {
          returnString = `${diffMinutes} minuti fa`;
      }

      return returnString;
    },
    selectAllCall(){
      if(this.selectAll){
        this.callRegister.map((call,index) => {
          this.callIDS.push(call.id);
        });
      }
      else{
        this.callIDS = [];
      }
    },
    async selectSingleCall(index,id){
      if(this.callIDS.includes(id) === false){
        this.callIDS.push(id);
      }
      else{
        this.callIDS = this.callIDS.filter(call => call !== id);
      }
    },
    async getDataCallRegister() {
        const result = await CallRegister.api().post(`${apiUrl}/device/call-register`, {
            page: this.page,
            sortOrder: this.sortOrder,
            filter: {
                service: this.serviceFilter
            }
        });
      return result.response.data;
    },
    async getDataServiceUsage() {
      // return this.lodash.groupBy(this.device.callRegister, 'service')
      const result = await CallRegister.api().get(`${apiUrl}/device/service-usage`);
      console.log("Result: ", result.response.data);
      return result.response.data;
    },
    selectCall(call){
      this.selectedCall = call;
    },
    async toggleSort(){
      this.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC';
      let callRegisterData = await this.getDataCallRegister();
      this.callRegister = callRegisterData.response;
      this.totalLengthPagionation = callRegisterData.count;
    },
    async getNewData(){
      let callRegisterData = await this.getDataCallRegister();
      console.log("Call Register Data: ", callRegisterData);
      this.callRegister = callRegisterData.response;
      this.totalLength = callRegisterData.count;
      this.totalLengthPagionation = callRegisterData.count;
    },
    filterCall(service){
      let parsingJSON = JSON.parse(JSON.stringify(service));
      if(parsingJSON == null){
        this.serviceFilter = "NULL";
      }else{
        // get value of service that is an observer
        this.serviceFilter = parsingJSON.call_register_service;
      }
      this.page = 1;
      this.getNewData();
    },
    checkService(service){
      console.log("Service: ", service)
      switch(service){
        case "0":
          return "Telefono";
        case "1":
          return "WhatsApp";
        case "2":
          return "Telegram";
        case "3":
          return "Messenger";
        case "4":
          return "Instagram";
        case "5":
          return "WickrMe";
        default:
          return "Unknown";
      }
    },
    async exportWord(){
      try{
        this.exportWordLoad = true;
        console.log("Exporting word");
        console.log("Selected calls: ", this.selectElement);
        console.log("Selected calls IDS: ", this.callIDS);
        let calls;
        if(this.selectAll){
          await axios.post(`${apiUrl}/device/getAllCalls`, {serviceFilter:this.serviceFilter}).then((response) => {
            calls = response.data;
          }).catch((error) => {
            console.log("Error: ", error);
          });
        }
        else{
          await axios.post(`${apiUrl}/device/getCallFromIDS`, {callIDS: this.callIDS}).then((response) => {
            calls = response.data;
          }).catch((error) => {
            console.log("Error: ", error);
          });
        }
        if(calls != undefined){
          let today = new Date();
          let dd = today.getDate();
          let mm = today.getMonth() + 1;
          let yyyy = today.getFullYear();
          let hh = today.getHours();
          let min = today.getMinutes() + "";
          console.log(dd + "/" + mm + "/" + yyyy + " " + hh + ":" + min);
          let oggetto = window.config.documentExport.oggetto
            .replace(new RegExp(this.escapeRegExp('{{progressivo}}'), 'g'), "Esportazione chiamate");
          let info_modulo = window.config.documentExport.info_modulo
            .replace(new RegExp(this.escapeRegExp('{{anno}}'), 'g'), yyyy)
            .replace(new RegExp(this.escapeRegExp('{{mese}}'), 'g'), mm)
            .replace(new RegExp(this.escapeRegExp('{{giorno}}'), 'g'), dd)
            .replace(new RegExp(this.escapeRegExp('{{orario}}'), 'g'), hh + ":" + min)
            .replace(new RegExp(this.escapeRegExp('{{firmatario}}'), 'g'), window.config.documentExport.firmatario)
            .replace(new RegExp(this.escapeRegExp('{{RIT}}'), 'g'), window.config.documentExport.rit_acquisizioni);
          // Creazione del paragrafo per il testo "Ufficiale di P.G."
          const firmaText = new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: window.config.documentExport.titolo_firma,
                size: 25,
                color: '000000',
              }),
            ],
          });

          // Creazione del paragrafo per la linea
          const linea = new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: '____________________',
                bold: true,
                size: 20,
                color: '000000',
              }),
            ],
          });

          // Creazione del paragrafo per il firmatario
          const firmatario = new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: window.config.documentExport.firmatario,
                size: 18,
                color: '000000',
              }),
            ],
          });

          let callsTable = [];
          calls.map((call) => {
            console.log("Call: ", call);
            callsTable.push(
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: new Date(call.created_at).toLocaleString(), // Inizio Chiamata
                            bold: true,
                            size: 20,
                            color: '000000',
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: new Date(call.ended_at).toLocaleString(), // Fine chiamata
                            bold: true,
                            size: 20,
                            color: '000000',
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: call.duration, // durata
                            bold: true,
                            size: 20,
                            color: '000000',
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: call.phone_number, // Telefono
                            bold: true,
                            size: 20,
                            color: '000000',
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: call.contact.name, // Nome contatto
                            bold: true,
                            size: 20,
                            color: '000000',
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: this.checkService(call.service), // servizio
                            bold: true,
                            size: 20,
                            color: '000000',
                          }),
                        ],
                      }),
                    ],
                  }),
                ]
              })
            );
          });

          const doc = new Document({
              sections: [{
                properties: {},
                children: [
                  // logo iniziale centrale
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new ImageRun({
                        data: window.config.reportImageLogo,
                        transformation: {
                          width: 100,
                          height: 100,
                        },
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                  }),
                  // GUARDIA DI FINANZA
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: window.config.documentExport.titolo,
                        bold: true,
                        color: '000000',
                        size: 36,
                      }),
                    ]
                  }),
                  // titolo Gruppo
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: window.config.documentExport.gruppo,
                        bold: true,
                        color: '000000',
                        size: 24,
                      }),
                    ]
                  }),
                  // Nucleo Operativo
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: window.config.documentExport.tipo_nucleo,
                        color: '000000',
                        size: 19,
                      }),
                    ]
                  }),
                  // informazioni varie
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: window.config.documentExport.informazioni_varie,
                        color: '000000',
                        size: 20,
                      }),
                    ]
                  }),
                  // Tabella Oggetto
                  new Table({
                    columnWidths: [
                      1700,
                      6700,
                    ],
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Oggetto",
                                    bold: true,
                                    color: '000000',
                                    size: 20,
                                  }),
                                ]
                              }),
                            ]
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: oggetto,
                                    color: '000000',
                                    size: 20,
                                  }),
                                ]
                              }),
                            ]
                          }),
                        ]
                      })
                    ]
                  }),
                  // balnk space
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "",
                        color: '000000',
                        size: 20,
                      }),
                    ]
                  }),
                  // Testo contenente informazioni inerenti al caso
                  new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                      new TextRun({
                        text: info_modulo,
                        color: '000000',
                        size: 20,
                      }),
                    ]
                  }),
                  // Immagine di divisione
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new ImageRun({
                        data: window.config.reportImageDivider,
                        transformation: {
                          width: 100,
                          height: 50,
                        },
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                  }),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "",
                        color: '000000',
                        size: 20,
                      }),
                    ]
                  }),
                  // Titolo SPORTAZIONE MESSAGGI SOCIAL
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "ESPORTAZIONE REGISTRO CHIAMATE",
                        bold: true,
                        color: '000000',
                        size: 24,
                      }),
                    ]
                  }),
                  // Blank space
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "",
                        color: '000000',
                        size: 20,
                      }),
                    ]
                  }),
                  // Blank space
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "",
                        color: '000000',
                        size: 20,
                      }),
                    ]
                  }),
                  // balnk space
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "",
                        color: '000000',
                        size: 50,
                      }),
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "",
                        color: '000000',
                        size: 50,
                      }),
                    ]
                  }),
                  // Tabelle chiamate
                  new Table({
                    columnWidths: [4200, 4200],
                    alignment: AlignmentType.CENTER,
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            shading: {
                              fill: "D9D9D9",
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Inizio Chiamata",
                                    bold: true,
                                    color: '000000',
                                    size: 20,
                                  })
                                ],
                              }),
                            ]
                          }),
                          new TableCell({
                            shading: {
                              fill: "D9D9D9",
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text:"Fine Chiamata",
                                    bold: true,
                                    color: '000000',
                                    size: 20,
                                  })
                                ],
                              }),
                            ]
                          }),
                          new TableCell({
                            shading: {
                              fill: "D9D9D9",
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Durata",
                                    bold: true,
                                    color: '000000',
                                    size: 20,
                                  })
                                ],
                              }),
                            ]
                          }),
                          new TableCell({
                            shading: {
                              fill: "D9D9D9",
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Telefono",
                                    bold: true,
                                    color: '000000',
                                    size: 20,
                                  })
                                ],
                              }),
                            ]
                          }),
                          new TableCell({
                            shading: {
                              fill: "D9D9D9",
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Nome Contatto",
                                    bold: true,
                                    color: '000000',
                                    size: 20,
                                  })
                                ],
                              }),
                            ]
                          }),
                          new TableCell({
                            shading: {
                              fill: "D9D9D9",
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Servizio",
                                    bold: true,
                                    color: '000000',
                                    size: 20,
                                  })
                                ],
                              }),
                            ]
                          }),
                        ]
                      }),
                      ...callsTable
                    ]
                  }),
                  // Firma
                  firmaText,
                  // balnk space
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: "",
                        color: '000000',
                        size: 20,
                      }),
                    ]
                  }),
                  linea,
                  firmatario
                ]
              }]
          });
          Packer.toBlob(doc).then(blob => {
            saveAs(blob,"call-register.docx");
            this.exportWordLoad = false;
          });
        }
        this.exportWordLoad = false;
      }
      catch(e){
        console.log("Error: ", e);
      }
      finally{
        this.exportWordLoad = false;
      }
    },
    escapeRegExp(string) {
			return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
		},
  },
  async created(){
    let callRegisterData = await this.getDataCallRegister();
    this.callRegister = callRegisterData.response;
    this.totalLength = callRegisterData.count;
    this.totalLengthPagionation = callRegisterData.count;
    this.services = await this.getDataServiceUsage();
  }
}
</script>

<style scoped>
	.lost {
		color: red;
	}

	.btn-export {
		min-width: 50px !important;
		max-width: 30px;
		margin-top: 10px;
		margin-right: 3px;
		font-size: 0.3vw;
	}
</style>