import {Model} from "@vuex-orm/core";
import Device from "@/store/models/device.model";

export default class Keylog extends Model {
  static entity = 'keylogs';

  static fields(){
    return {
      id: this.attr(null),
      app: this.attr(null),
      text: this.attr(null),
      deviceId: this.attr(null),
      created_at: this.attr(null),
      device: this.belongsTo(Device, 'deviceId'),
    }
  }
}