<script>
	import Multiselect from 'vue-multiselect'
	import axios from '../../axios';
	import Swal from 'sweetalert2';
	import ChatMessageUtils from "../../utils/ChatMessageUtils";
	import ChatMessage from "../../store/models/chat-message.model";

	export default {
		name: "CahngeDirectionOfMessage",
		props: [
			'apiUrl',
			'token',
			'imageName',
			'actualContact',
			'spliceElement',
			'actualIdMessage'
		],
		components: {
			Multiselect
		},
		data() {
			return {
				selectedDirection: null,
				selectedContact: null,
				contacts: [],
				direction: ["IN ENTRATA", "IN USCITA"],
			}
		},
		methods: {
			async updateContacts() {
				console.log("contactText: ", this.contactText); /// TODO: Capire se implementare o rimuovere contactText nella chiamata per filtrare
				await axios.get(`${this.apiUrl}/device/contact-limit`).then((data) => {
					console.log("data: ", data.data);
					this.contacts = data.data.map(contatto => {
						return {
							value: contatto.id,
                            label: `${contatto.name} - ${contatto.phone}`
						}
					});
				});
			},
			save() {
				if (this.selectedDirection == null || this.selectedDirection == undefined) {
					Swal.fire({
						title: 'Errore',
						text: 'Non è stata specificata alcuna direzione',
						icon: 'error',
						confirmButtonText: 'Ok'
					});
					return;
				}
				let numberDirection = this.selectedDirection == "IN ENTRATA" ? 0 : 1;
				if (this.selectedContact == null || this.selectedContact == undefined) {
					Swal.fire({
						title: 'Errore',
						text: 'Non è stato specificato alcun contatto',
						icon: 'error',
						confirmButtonText: 'Ok'
					});
					return;
				}
				Swal.fire({
					icon: 'warning',
					title: 'Sei sicuro di voler effettuare questo cambiamento?',
					text: 'Potrai cambiare nuovamente il messaggio in seguito!',
					showDenyButton: true,
					showCancelButton: true,
					confirmButtonText: 'Salva',
					denyButtonText: `Non Salvare`,
				}).then(async (result) => {
					if (result.isConfirmed) {
						const user = JSON.parse(localStorage.getItem('user'));
						let contactId = this.selectedContact.value;
						let res = await axios.post(this.apiUrl + `/device/change-media-information`, {
							imageName: this.imageName,
							direction: numberDirection,
							contactId: contactId,
							userUpdated: user.username
						});
						console.log("Response: ", res);
						if (res.data.status) {
							Swal.fire({
								title: 'Successo',
								text: 'Salvataggio avvenuto con successo',
								icon: 'success',
								confirmButtonText: 'Ok'
							});
							this.spliceElement(this.actualIdMessage);
						}
						else {
							Swal.fire({
								title: 'Errore',
								text: 'Errore durante il salvataggio',
								icon: 'error',
								confirmButtonText: 'Ok'
							});
						}
						this.selectedContact = null;
						this.selectedDirection = null;
					}
					else if (result.isDenied) {
						Swal.fire('I cambiamenti non sono stati salvati!', '', 'info')
					}
				})
			}
		},
		mounted() {
			console.log("actualContact: ", this.actualContact)
			this.updateContacts();
		}
	}
</script>
<template>
	<div class="form-container">
		<v-select v-model="selectedDirection"
							:items="direction"
							item-text="direction"
							item-value="id"
							label="Seleziona la direzione">
		</v-select>
		<multiselect v-model="selectedContact"
								 :options="contacts"
								 :placeholder="'Seleziona il contatto'"
								 track-by="value"
								 label="label"
								 @search-change="updateContacts" />
		<v-btn color="primary" block @click="save()" class="save-btn">
			<v-icon>mdi-content-save</v-icon>
			Salva
		</v-btn>
	</div>
</template>
<style scoped>
	@import '~vue-multiselect/dist/vue-multiselect.min.css';

	.multiselect__placeholder {
		color: #adadad;
		display: inline-block;
		margin-bottom: 0px;
		font-size: 17px;
		padding-top: 2px;
		margin-top: 8px;
	}

	.save-btn {
		min-width: 50px !important;
		margin-top: 10px;
		float: right;
	}

	.multiselect__tags {
		min-height: 40px;
		display: block;
		padding: 0;
		border-radius: 0px;
		color: #bfbfbf;
		border-bottom: 1px solid #bfbfbf;
		background: #1b1b1b;
		font-size: 14px;
	}
</style>