import { Model } from "@vuex-orm/core";
import Device from "@/store/models/device.model";
import Contact from "@/store/models/contact.model";
import Relevance from "@/store/models/relevance.model";
import Notification from "@/store/models/notification.model";
import { CALL_REGISTER_EVENT } from "@/store/actions.type";

export default class CallRegister extends Model {
	static entity = 'call_register';

	static fields() {
		return {
			id: this.attr(null),
			phone: this.attr(null),
			type: this.number(null),
			duration: this.string(''),
			lost: this.boolean(null),
			created_at: this.attr(null),
			ended_at: this.attr(null),
			service: this.attr(null),
			deviceId: this.attr(null),
			device: this.belongsTo(Device, 'deviceId'),
			contactId: this.attr(null),
			contact: this.belongsTo(Contact, 'contactId'),
			relevanceId: this.attr(null),
			relevance: this.belongsTo(Relevance, 'relevanceId')
		}
	}
}

export const callRegister = {
	actions: {
		[CALL_REGISTER_EVENT](context, params) {
			CallRegister.insertOrUpdate({
				data: params.data,
			})
			if (params.event.includes('start')) {
				Notification.insert({
					data: {
						icon: 'mdi-phone',
						color: 'indigo darken-3',
						title: `Chiamata iniziata`,
						subtitle: `è in corso una chiamata con ${params.data.contact.known ? params.data.contact.name : 'un contatto sconosciuto'}`,
						actionLabel: 'Visualizza',
						actionIcon: 'mdi-check',
						actionLink: `/device/call-register`,
						deviceId: params.data.deviceId,
					}
				})
			}
			else if (params.event.includes('end') || params.event.includes('lost')) {
				const lost = params.event.includes('lost');
				Notification.insert({
					data: {
						icon: 'mdi-phone',
						color: 'indigo darken-3',
						title: `Chiamata ${lost ? 'persa' : 'terminata'}`,
						subtitle: `La chiamata ${lost ? 'non ha ricevuto risposta' : 'è terminata'}`,
						actionLabel: 'Visualizza',
						actionIcon: 'mdi-check',
						actionLink: `/device/call-register`,
						deviceId: params.data.deviceId,
					}
				})
			}
		}
	}
}