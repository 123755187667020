<template v-slot:extension>

	<v-tabs v-model="tabs">
		<div class="container-element">
			<div class="container-slider">
				<v-tab :key="'sintesi'"
					   href="#sintesi">
					Sintesi
				</v-tab>
				<v-tab :key="'transcription'"
					   href="#transcription">
					Trascrizione
				</v-tab>
				<v-tab :key="'events'"
					   href="#events">
					Eventi
				</v-tab>
			</div>
			<div style="display:flex">
				<div style="display:flex;flex-direction: column;">
					<span>Rilevanza</span>
					<v-select v-on:change="changeRelevance"
							  item-value="value"
							  item-text="label"
							  style="width: 147px;margin-top:-15px;"
							  v-model="relevance_grade"
							  :items="relevance_grades"
							  :disabled="socketArray.includes(relevance.id) && checkIfUserIsSame(relevance.id)">
					</v-select>
				</div>
				<div style="display:flex;flex-direction: column;">
					<span>Note di Rilevanza</span>
					<v-select v-on:change="changeRelevanceNote"
							  item-value="value"
							  item-text="label"
							  style="width: 147px;margin-top:-15px;"
							  v-model="relevance_note"
							  :items="relevance_notes"
							  :disabled="socketArray.includes(relevance.id) && checkIfUserIsSame(relevance.id)">
					</v-select>
				</div>
			</div>
			<div class="btn-container">

				<v-btn color="primary"
					   block
					   @click="saveRelevance()"
					   :loading="saving"
					   :disabled="socketArray.includes(relevance.id) && checkIfUserIsSame(relevance.id)"
					   class="btn-export">
					<v-icon class="font-size__10px">mdi-content-save</v-icon>
				</v-btn>
				<!-- <v-btn
				  color="green"
				  block
				  @click="exportRelevance()"
				  :loading="exportPDF"
				  class="btn-export"
				>
				  <v-icon class="font-size__10px">mdi-file-pdf-box</v-icon>
				</v-btn> -->
				<div class="tooltip-container">
					<v-btn color="green"
						   block
						   @click="exportWord()"
						   :loading="exportWordLoad"
						   class="btn-export">
						<v-icon class="font-size__10px">mdi-file-word</v-icon>
					</v-btn>
					<div class="tooltip btn-cont" @click="exportWord()">
						<span class="tooltip-text">Trascrizione</span>
					</div>
				</div>
				<div class="tooltip-container">
					<v-btn color="green"
						   block
						   @click="exportWord(true)"
						   :loading="exportWordLoad"
						   class="btn-export">
						<v-icon class="font-size__10px">mdi-file-word</v-icon>
					</v-btn>
					<div class="tooltip btn-cont" @click="exportWord(true)">
						<span class="tooltip-text">Sintesi</span>
					</div>
				</div>
			</div>
		</div>
		<v-tabs-items v-model="tabs">
			<v-tab-item :key="'sintesi'"
						:value="'sintesi'">
				<v-card>
					<v-card-text>
						<v-row class="ma-4">
							<v-col cols="8">
								<v-textarea solo
											outlined
											rows="7"
											v-model="sintesiText"
											@keyup="keyPressed($event,'sintesi')"
											:disabled="socketArray.includes(relevanceId) && checkIfUserIsSame(relevanceId)">
								</v-textarea>
							</v-col>

							<v-col cols="4">
								<v-card>
									<v-card-title>
										Interlocutori
									</v-card-title>
									<v-card-text>
										<v-list dense>
											<v-list-item>
												<v-checkbox v-model="changeInterlocutorSynthesis"></v-checkbox>
												Inserisci interlocutore premendo il tasto invio
											</v-list-item>
											<v-list-item v-for="i in interlocutorsSynthesis" :key="i.name">
												<v-list-item-avatar>
													<avatar :username="i.name"></avatar>
												</v-list-item-avatar>
												<v-list-item-content>
													{{i.name}}
												</v-list-item-content>
												<v-list-item-action>
													<v-btn icon @click="removeInterlocutor(i,2)"><v-icon>mdi-delete</v-icon></v-btn>
												</v-list-item-action>
												<v-list-item-action>
													<v-btn icon @click="insertInterlocutorText(i,2)"><v-icon>mdi-upload</v-icon></v-btn>
												</v-list-item-action>
											</v-list-item>
										</v-list>
									</v-card-text>
									<v-card-actions>
										<v-text-field v-model="newInterlocutorSynthesis"
													  @keyup.enter="addInterlocutor(2)">
										</v-text-field>
										<v-btn dark color="primary" @click="addInterlocutor(2)">Aggiungi</v-btn>
									</v-card-actions>
								</v-card>
								<v-card>
									<v-card-title>
										Dizionario
									</v-card-title>
									<v-card-text>
										<v-list dense>
											<v-list-item v-for="i in dictionarySynthesis" :key="i.word">
												<v-list-item-avatar>
													<avatar :username="i.label"></avatar>
												</v-list-item-avatar>
												<v-list-item-content>
													{{i.word}}
												</v-list-item-content>
												<v-list-item-action>
													<v-btn icon @click="removeWord(i,2)"><v-icon>mdi-delete</v-icon></v-btn>
												</v-list-item-action>
												<v-list-item-action>
													<v-btn icon @click="insertWordText(i,2)"><v-icon>mdi-upload</v-icon></v-btn>
												</v-list-item-action>
											</v-list-item>
										</v-list>
									</v-card-text>
									<v-card-actions>
										<v-text-field v-model="newWordSynthesis"
													  @keyup.enter="addWord(2)">
										</v-text-field>
										<v-btn dark color="primary" @click="addWord(2)">Aggiungi</v-btn>
									</v-card-actions>
								</v-card>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-tab-item>
			<v-tab-item :key="'transcription'"
						:value="'transcription'">
				<v-card>
					<v-card-text>
						<v-row class="ma-4">
							<v-col cols="8">
								<v-textarea solo
											outlined
											rows="7"
											v-model="transcriptionText"
											@keyup="keyPressed($event, 'trascrizione')"
											:disabled="socketArray.includes(relevanceId) && checkIfUserIsSame(relevanceId)">
								</v-textarea>
							</v-col>
							<v-col cols="4">
								<v-card>
									<v-card-title>
										Interlocutori
									</v-card-title>
									<v-card-text>
										<v-list dense>
											<v-list-item>
												<v-checkbox v-model="changeInterlocutorTransciption"></v-checkbox>
												Inserisci interlocutore premendo il tasto invio
											</v-list-item>
											<v-list-item v-for="i in interlocutorsTranscription" :key="i.name">
												<v-list-item-avatar>
													<avatar :username="i.name"></avatar>
												</v-list-item-avatar>
												<v-list-item-content>
													{{i.name}}
												</v-list-item-content>
												<v-list-item-action>
													<v-btn icon @click="removeInterlocutor(i)"><v-icon>mdi-delete</v-icon></v-btn>
												</v-list-item-action>
												<v-list-item-action>
													<v-btn icon @click="insertInterlocutorText(i,1)"><v-icon>mdi-upload</v-icon></v-btn>
												</v-list-item-action>
											</v-list-item>
										</v-list>
									</v-card-text>
									<v-card-actions>
										<v-text-field v-model="newInterlocutorTranscription"
													  @keyup.enter="addInterlocutor()">
										</v-text-field>
										<v-btn dark color="primary" @click="addInterlocutor()">Aggiungi</v-btn>
									</v-card-actions>
								</v-card>
								<v-card>
									<v-card-title>
										Dizionario
									</v-card-title>
									<v-card-text>
										<v-list dense>
											<v-list-item v-for="i in dictionaryTranscirption" :key="i.word">
												<v-list-item-avatar>
													<avatar :username="i.label"></avatar>
												</v-list-item-avatar>
												<v-list-item-content>
													{{i.word}}
												</v-list-item-content>
												<v-list-item-action>
													<v-btn icon
														   @click="removeWord(i)">
														<v-icon>mdi-delete</v-icon>
													</v-btn>
												</v-list-item-action>
												<v-list-item-action>
													<v-btn icon @click="insertWordText(i,1)"><v-icon>mdi-upload</v-icon></v-btn>
												</v-list-item-action>
											</v-list-item>
										</v-list>
									</v-card-text>
									<v-card-actions>
										<v-text-field v-model="newWordTranscription"
													  @keyup.enter="addWord()">
										</v-text-field>
										<v-btn dark color="primary" @click="addWord()">Aggiungi</v-btn>
									</v-card-actions>
								</v-card>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-tab-item>
			<v-tab-item :key="'events'"
						:value="'events'">
				<event-details ref="eventDetails"
							   :editEvent="editEventTT"
							   :relevanceId="relevanceId"
							   :goToSecond="goToSecond" />
			</v-tab-item>
		</v-tabs-items>
	</v-tabs>
</template>

<script>
	import Avatar from 'vue-avatar';
	import SocketioService from '../../../services/socketio.service';
	import EventDetails from "@/components/relevance/EventDetails";

	export default {
		name: "TTextArea",
		components: {
			Avatar,
			EventDetails
		},
		props: [
			'transcription',
			'synthesis',
			'editEventTT',
			'goToSecond',
			'relevance',
			'relevanceId',
			'changeSavedElementFunc',
			'stopAudio',
			'saveRelevance',
			'saving',
			'exportRelevance',
			'exportPDF',
			'exportWord',
			'exportWordLoad',
			'changeRelevance',
			"relevance_grade",
			"relevance_grades",
			"relevance_note",
			"relevance_notes",
			"changeRelevanceNote"
		],
		created() {
			SocketioService.checkDataTranscriptionAndSynthesis();
			SocketioService.socket.on('broadcast-id-value', (data) => {
				console.log("DATI ARRIVATI");
				SocketioService.elaborateData(data);
				this.socketArray = SocketioService.occupiedID;
			});
		},
		data() {
			return {
				transcriptionText: '',
				socketArray: SocketioService.occupiedID,
				sintesiText: '',
				interlocutorsTranscription: [],
				interlocutorsSynthesis: [],
				dictionaryTranscirption: [],
				actualInterlocutorTranscription: 0,
				actualInterlocutorSynthesis: 0,
				dictionarySynthesis: [],
				username: JSON.parse(localStorage.getItem('user')).username,
				interlocutorsInc: -1,
				afterNameContent: false,
				changeInterlocutorTransciption: false,
				tabs: null,
				newInterlocutorTranscription: '',
				newInterlocutorSynthesis: '',
				newWordTranscription: '',
				newWordSynthesis: '',
				changeInterlocutorSynthesis: false
			}
		},
		beforeDestroy() {
			// Always destroy your editor instance when it's no longer needed

		},
		mounted() {
			this.transcriptionText = this.transcription ? this.transcription.text : "";
			this.sintesiText = this.synthesis ? this.synthesis.text : "";
		},
		methods: {
			removeInterlocutor(interlocutor, type = 1) {
				if (type == 1) {
					this.interlocutorsTranscription.splice(this.interlocutorsTranscription.findIndex((i) => i.name === interlocutor.name), 1);
				} else {
					this.interlocutorsSynthesis.splice(this.interlocutorsSynthesis.findIndex((i) => i.name === interlocutor.name), 1);
				}
			},
			updateTranscriptionText(event) {
				this.transcriptionText = event.target.innerHTML;
			},
			getEventDetails() {
				console.log("EVENT SAVA GET EVENT");
				this.$refs.eventDetails.getEvent()
			},
			removeWord({ word }, type = 1) {
				if (type == 1) {
					this.dictionaryTranscirption.splice(this.dictionaryTranscirption.findIndex((d) => d.word === word), 1);
				} else {
					this.dictionarySynthesis.splice(this.dictionarySynthesis.findIndex((d) => d.word === word), 1);
				}
			},
			getText() {
				return this.transcriptionText;
			},
			insertInterlocutorText(interlocutor, type = 1) {
				if (type == 1) {
					let index = this.interlocutorsTranscription.findIndex((i) => i.name === interlocutor.name);
					this.transcriptionText += " " + this.interlocutorsTranscription[index].name;
				}
				else {
					let index = this.interlocutorsSynthesis.findIndex((i) => i.name === interlocutor.name);
					this.sintesiText += " " + this.interlocutorsSynthesis[index].name;
				}

			},
			getUserOccupied(idRelevance) {
				let arrID = [];
				// let users = "";
				let users = [];
				console.log("SocketArr", this.socketArray);
				for (let i = 0; i < this.socketArray.length; i++) {
					console.log("SocketArr[i]: ", this.socketArray[i]);
					if (this.socketArray[i] == idRelevance) {
						arrID.push(i);
					}
				}
				console.log("SocketioService.occupiedUsers: ", SocketioService.occupiedUsers);
				for (let i = 0; i < arrID.length; i++) {
					// i + 1 == arrID.length ? users += SocketioService.occupiedUsers[arrID[i]] : users += SocketioService.occupiedUsers[arrID[i]]+",";
					users.push(SocketioService.occupiedUsers[arrID[i]]);
				}
				console.log("users: ", users)
				return users;
			},
			checkIfUserIsSame(idRelevance) {
				let users = this.getUserOccupied(idRelevance);
				console.log("getUserOccupied: ", users);
				console.log("ID RELEVANCE: ", idRelevance);
				let localUser = JSON.parse(localStorage.getItem("user")).username;
				console.log(users[0], "!=", localUser)
				return users[0] != localUser;
			},
			sendDataToSocket(msg, type) {
				SocketioService.sendSynthesisOrTranscription(msg, this.relevanceId, type);
			},
			keyPressed($event, type) {
				this.changeSavedElementFunc();
				this.stopAudio();
				if (type == "trascrizione" && $event.code === 'Enter' && this.interlocutorsTranscription.length > 0 && this.changeInterlocutorTransciption) {
					const textArea = $event.target;
					const interlocutor = this.interlocutorsTranscription[this.actualInterlocutorTranscription].name + ": ";
					this.addTextAtCursor(textArea, "transcriptionText", interlocutor);

					if (this.actualInterlocutorTranscription < this.interlocutorsTranscription.length - 1) {
						this.actualInterlocutorTranscription++;
					}
					else {
						this.actualInterlocutorTranscription = 0;
					}
				}
				else if (type == "sintesi" && $event.code === 'Enter' && this.interlocutorsSynthesis.length > 0 && this.changeInterlocutorSynthesis) {
					const textArea = $event.target;
					const interlocutor = this.interlocutorsSynthesis[this.actualInterlocutorSynthesis].name + ": ";
					this.addTextAtCursor(textArea, "sintesiText", interlocutor);

					if (this.actualInterlocutorSynthesis < this.interlocutorsSynthesis.length - 1) {
						this.actualInterlocutorSynthesis++;
					}
					else {
						this.actualInterlocutorSynthesis = 0;
					}
				}
				else if ($event.code === 'Backspace') {
					if (!this.afterNameContent) {
						this.removeLastLine()
						this.afterNameContent = true;
					}
				}
				if ($event.code.includes('Numpad')) {
					switch (+$event.key) {
						case 5:
							this.$emit('control', 'pause');
							break;
						case 4:
							this.$emit('control', 'back');
							break;
						case 6:
							this.$emit('control', 'forward');
							break;
						case 8:
							this.$emit('control', 'volUp');
							break;
						case 2:
							this.$emit('control', 'volDown');
							break;
					}
				}
				if ($event.code.includes('Key')) {
					this.afterNameContent = true
				}
			},
			addTextAtCursor(textArea, textKey, textToAdd) {
				const cursorPosition = textArea.selectionStart;
				this[textKey] =
					this[textKey].slice(0, cursorPosition)
					+ textToAdd
					+ this[textKey].slice(cursorPosition);

				// Riposiziona il cursore dopo il testo inserito
				this.$nextTick(() => {
					textArea.selectionStart = cursorPosition + textToAdd.length;
					textArea.selectionEnd = cursorPosition + textToAdd.length;
					textArea.focus();
				});
			},
			insertWordText(word, type) {
				if (type == 1) {
					let index = this.dictionaryTranscirption.findIndex((d) => d.word === word.word);
					this.transcriptionText += " " + this.dictionaryTranscirption[index].word;
				}
				else {
					let index = this.dictionarySynthesis.findIndex((d) => d.word === word.word);
					this.sintesiText += " " + this.dictionarySynthesis[index].word;
				}
			},
			changeInterlocutor(interlocutor = null) {
				if (interlocutor === null) {
					if (this.interlocutorsInc >= this.interlocutors.length - 1)
						this.interlocutorsInc = 0;
					else
						this.interlocutorsInc++;
					this.transcriptionText += this.interlocutors[this.interlocutorsInc].name + ': '
					this.afterNameContent = false;
					return;
				}

				if (interlocutor >= this.interlocutors.length || interlocutor < 0)
					return;
				this.interlocutorsInc = interlocutor;
				this.transcriptionText += '\n' + this.interlocutors[interlocutor].name + ': '
				this.afterNameContent = false;
			},
			removeLastLine() {
				const pieces = this.transcriptionText.split('\n');
				pieces.pop()
				this.transcriptionText = pieces.join('\n')

				if (--this.interlocutorsInc < 0)
					this.interlocutorsInc = this.interlocutors.length - 1
			},
			appendWordToText(index) {
				if (index >= 0 && index < this.dictionary.length) {
					this.transcriptionText += this.dictionary[index].word + ' ';
					this.afterNameContent = true
				}
			},
			addInterlocutor(type = 1) {
				if (type == 1) {
					if (this.newInterlocutorTranscription == "") return;
					this.interlocutorsTranscription.push({ name: this.newInterlocutorTranscription });
					this.newInterlocutorTranscription = '';
				}
				else {
					if (this.newInterlocutorSynthesis == "") return;
					this.interlocutorsSynthesis.push({ name: this.newInterlocutorSynthesis });
					this.newInterlocutorSynthesis = '';
				}
			},
			addWord(type = 1) {
				if (type == 1) {
					if (this.newWordTranscription == "") return;
					this.dictionaryTranscirption.push({ label: (this.dictionaryTranscirption.length + 1) + '', word: this.newWordTranscription });
					this.newWordTranscription = '';
				}
				else {
					if (this.newWordSynthesis == "") return;
					this.dictionarySynthesis.push({ label: (this.dictionarySynthesis.length + 1) + '', word: this.newWordSynthesis });
					this.newWordSynthesis = '';
				}
			}
		}
	}
</script>

<style scoped>
	.v-card__subtitle, .v-card__text, .v-card__title {
		padding-left: 0px !important;
		padding-right: 0px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}

	.v-sheet.v-card:not(.v-sheet--outlined) {
		box-shadow: none !important;
	}

	.v-list-item {
		padding: 0 !important;
		margin-top: -20px !important;
	}

	.tooltip-text {
		font-size: 9px;
	}

	.v-card__actions {
		padding: 0px !important;
		margin-top: -35px !important;
	}

		.v-card__actions > .v-btn.v-btn {
			margin-left: 10px !important;
		}

	.font-size__10px {
		font-size: 18px !important;
	}

	.div-editable {
		border: 1px solid #ccc;
		padding: 5px;
		min-height: 100px;
	}

	.container-element {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	.container-slider {
		display: flex;
	}

	.btn-container {
		display: flex;
		margin-top: 10px;
	}

	.v-btn {
		padding: 0 !important;
		min-width: 25px !important;
		margin-right: 10px;
		height: 25px !important;
	}

	.tooltip-container {
		position: relative;
		display: inline-block;
		cursor: pointer;
	}

	.btn-cont {
		display: flex;
		width: auto;
	}

	.tooltip {
		visibility: hidden;
		width: auto;
		height: auto;
		z-index: 1;
		background-color: #000;
		color: #fff;
		text-align: center;
		border-radius: 4px;
		padding: 6px;
		position: absolute;
		top: 10px;
		margin-bottom: 24px;
		left: 50%;
		transform: translateX(-50%);
		opacity: 0;
		transition: opacity 0.3s ease;
	}

	.tooltip-container:hover .tooltip {
		visibility: visible;
		opacity: 1;
	}
</style>