import {Model} from "@vuex-orm/core";
import Relevance from "@/store/models/relevance.model";
import User from "@/store/models/user.model";

export default class Transcription extends Model{
  static entity = 'transcriptions';

  static fields() {
    return {
      id: this.attr(null),
      relevance_grade: this.attr(0),
      text: this.attr(null),
      created_at: this.attr(null),
      last_modified: this.attr(null),
      userId: this.attr(null),
      user: this.belongsTo(User, 'userId'),
      relevance: this.hasOne(Relevance, 'transcriptionId')
    }
  }
}