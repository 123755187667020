<template>
	<v-row>
		<v-col cols="6" class="mt-4 d-flex justify-center align-start">
			<div>
				<l-map v-if="showMap"
					   :zoom="zoom"
					   :center="center"
					   :options="mapOptions"
					   style="height: 700px; width: 600px;"
					   @update:center="centerUpdate"
					   @update:zoom="zoomUpdate">
					<l-tile-layer :url="url" />
					<l-marker :lat-lng="withTooltip" :icon="customIcon">
						<l-tooltip :options="{ permanent: true, interactive: true }">
							<div>
								Ultima posizione
							</div>
						</l-tooltip>
					</l-marker>
					<l-marker :lat-lng="center" :icon="customIcon"></l-marker>
					<l-polyline :lat-lngs="polyline.latlngs"
								:color="polyline.color" />
					<l-polyline :lat-lngs="searchResult"
								:color="'red'" />
				</l-map>
			</div>
		</v-col>

		<v-col cols="6" class="d-flex justify-center align-stretch">
			<v-card width="100%">
				<v-card-title>Ultime posizioni registrate</v-card-title>
				<v-card-text>
					<div class="filter-list">
						<div class="filter-item">
							<label class="filter-label"><v-icon>mdi-clock</v-icon> Filtro per data: </label>
							<date-picker class="filter-content" v-model="dataFiltro" range></date-picker>
							<v-btn v-if="dataFiltro" @click="dataFiltro = null"><v-icon>mdi-close</v-icon></v-btn>
						</div>
					</div>
					<v-divider></v-divider>
					<v-subheader>Elenco ultime 10 posizioni conosciute</v-subheader>
					<v-divider></v-divider>
					<v-list>
						<v-list-item v-if="pageItems.length === 0">
							<v-list-item-content>
								<v-list-item-title>Nessuna posizione presente in elenco</v-list-item-title>
								<v-list-item-subtitle>Il telefono non ha ancora acquisito nessuna posizione GPS</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
						<template>
							<div v-for="position in pageItems" :key="position.id" style="display:flex; align-items: end;">
								<v-list-item @click="centerMapOnPosition(position)">
									{{position.lat}} - {{position.lon}} alle {{ProxyAddOneHour(position.created_at)}}
								</v-list-item>
								<v-btn @click="createRelevanceFromPosition(position.id)" :disabled="relevanceCreated[position.id]">Crea Rilevanza</v-btn>
							</div>
							<v-divider></v-divider>
						</template>
					</v-list>
					<v-pagination v-model="page"
								  :length="pagesLength"
								  prev-icon="mdi-menu-left"
								  next-icon="mdi-menu-right"></v-pagination>
					<v-subheader>Elenco percorsi frequenti</v-subheader>
					<v-divider></v-divider>
					<v-list>
						<template>
							<v-list-item v-for="position in [1]" :key="position.id">
								Nessun percorso frequente riconosciuto
							</v-list-item>
							<v-divider></v-divider>
						</template>
						<v-container>
							<v-row class="text-center">
								<!-- create col 6 -->
								<v-col cols="4">

									<div class="button-gps__container">
										<v-btn color="green"
											   class="event-button"
											   aria-label="Tooltip Bottom"
											   tooltip-position="bottom"
											   @click="sendCommand('GET_VALUE_OF_GPS_CRITERION')">
											<v-icon>mdi-update</v-icon>
										</v-btn>
										<v-btn @click="showDescription(0)" :color="actualConfigurationPosition == 0 || configuationActive == '0' ? 'green' : ''">
											<span class="content-span__btn">Localizzazione a risparmio energetico</span>
										</v-btn>
										<v-btn @click="showDescription(1)" :color="actualConfigurationPosition == 1 || configuationActive == '1' ? 'green' : ''">
											<span class="content-span__btn">Localizzazione standard</span>
										</v-btn>
										<v-btn @click="showDescription(2)" :color="actualConfigurationPosition == 2 || configuationActive == '2' ? 'green' : ''">
											<span class="content-span__btn">Localizzazione in tempo reale</span>
										</v-btn>
										<v-btn @click="showDescription(3)" :color="actualConfigurationPosition == 3 || configuationActive == '3' ? 'green' : ''">
											<span class="content-span__btn">Localizzazione passiva</span>
										</v-btn>
									</div>
								</v-col>
								<!-- create col 6 -->
								<v-col cols="8">

									<div class="info-button__container">
										<div v-if="actualConfigurationPosition == 0">
											<p class="info-text">
												Questa opzione è adatta quando si desidera risparmiare energia e non è necessaria una precisione molto alta nella localizzazione.
												Il dispositivo utilizzerà meno energia, ma la precisione potrebbe non essere sufficiente per applicazioni che richiedono posizioni molto accurate,
												come la navigazione turn-by-turn.
											</p>
											<v-btn color="green"
												   block
												   class="btn-info"
												   @click="sendCommandGPS(0)">
												<v-icon>mdi-send</v-icon>Invia Comando
											</v-btn>
										</div>
										<div v-if="actualConfigurationPosition == 1">
											<p class="info-text">
												Questa opzione offre un equilibrio tra la potenza consumata e
												la precisione della localizzazione. Il dispositivo utilizzerà una quantità moderata
												di energia e fornirà posizioni più accurate rispetto alla modalità "bassa potenza e bassa precisione". Questo può essere utile per applicazioni come la mappatura o la localizzazione generale.
											</p>
											<v-btn color="green"
												   block
												   class="btn-info"
												   @click="sendCommandGPS(1)">
												<v-icon>mdi-send</v-icon>Invia Comando
											</v-btn>
										</div>
										<div v-if="actualConfigurationPosition == 2">
											<p class="info-text">
												Questa opzione richiederà una maggiore potenza e fornisce
												la massima precisione possibile nella localizzazione.
												È adatto per applicazioni che richiedono una precisione estremamente alta,
												come la navigazione GPS avanzata o il tracciamento dettagliato della posizione.
											</p>
											<v-btn color="green"
												   block
												   class="btn-info"
												   @click="sendCommandGPS(2)">
												<v-icon>mdi-send</v-icon>Invia Comando
											</v-btn>
										</div>
										<div v-if="actualConfigurationPosition == 3">
											<p class="info-text">
												Utilizza le informazioni di posizione già disponibili,
												come i dati GPS storici, per fornire informazioni sulla posizione senza
												attivare attivamente il GPS. È utile quando si desidera ottenere
												aggiornamenti sulla posizione solo quando sono disponibili e non
												è necessaria la localizzazione in tempo reale. Ad esempio,
												può essere utilizzato per ricevere aggiornamenti
												sulla posizione solo quando l'applicazione è in background o quando altri provider attivi forniscono dati.
												Tuttavia, la precisione della posizione può essere limitata in questo caso.
											</p>
											<v-btn color="green"
												   block
												   class="btn-info"
												   @click="sendCommandGPS(3)">
												<v-icon>mdi-send</v-icon>Invia Comando
											</v-btn>
										</div>
									</div>
								</v-col>
							</v-row>
						</v-container>

					</v-list>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
	import { latLng } from "leaflet";
	import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LPolyline } from "vue2-leaflet";
	import "leaflet/dist/leaflet.css";
	import { FETCH_DEVICE_POSITIONS } from "@/store/actions.type";
	import Device from "@/store/models/device.model";
	import { apiUrl } from "@/common/endpoint.const";
	import Console from "@/store/models/console.model";
	import moment from "moment";
	import { addOneHour } from '../../common/utils';
	import axios from '../../axios';
	import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';
	import 'vue2-datepicker/locale/it';
	import Swal from 'sweetalert2';
	import Vue from "vue";

	export default {
		name: "Example",
		components: {
			LMap,
			LTileLayer,
			LMarker,
			LPopup,
			LTooltip,
			LPolyline,
			DatePicker,
		},
		data() {
			return {
				zoom: 18,
				url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
				mapOptions: {
					zoomSnap: 0.5
				},
				customIcon: L.icon({
					iconUrl: window.location.origin + '/img/marker.png',
					iconSize: [24, 38], // Dimensione dell'icona in pixel
					iconAnchor: [11, 35], // Punto dell'icona che corrisponde alla posizione del marker
					popupAnchor: [-3, -76] // Punto da cui verrà aperto il popup rispetto all'iconAnchor
				}),
				commandConsole: null,
				page: 1,
				positionsPerPage: 10,
				start_date: null,
				end_date: null,
				searchResult: [],
				actualConfigurationPosition: null,
				configuationActive: 0,
				center: latLng(0, 0),
				dataFiltro: null,
				startDate: null,
				endDate: null,
				relevanceCreated: {},
				positions: null,
			};
		},
		watch: {
			dataFiltro(date) {
				if (date && date[0] && date[1]) {
					this.startDate = new Date(date[0]);
					this.endDate = new Date(date[1]);
				} else {
					this.startDate = null;
					this.endDate = null;
				}
				this.changePositions();
			}
		},
		methods: {
			changePositions() {
				this.positions = null;
				if (this.startDate || this.endDate) {
					this.positions = this.device.positions.filter((position) =>
						new Date(position.created_at) >= this.startDate && new Date(position.created_at) <= this.endDate.setHours(23, 59, 59, 999)
					);
				} else {
					this.positions = this.device.positions;
				}
			},
			createRelevanceFromPosition(positionId) {
				axios.post(`${apiUrl}/device/relevance/create-from-position/${positionId}`)
					.then((response) => {
						this.updateRelevanceCreated();
						const relevance = response.data;
						Swal.fire({
							icon: 'success',
							title: 'Rilevanza creata con successo!',
							text: `Rilevanza ${relevance.id} creata per la posizione ${positionId}!`,
							showConfirmButton: true,
							confirmButtonText: 'Visualizza',
							showCancelButton: true,
						}).then((result) => {
							if (result.isConfirmed) {
								this.$router.push(`/device/relevance/${relevance.id}`);
							}
						})
					})
					.catch((error) => {
						console.log(error);
					});
			},
			async updateRelevanceCreated() {
				this.relevanceCreated = {};
				for (var position of this.positions) {
					var response = await axios.get(`${apiUrl}/device/relevance-by-position/${position.id}`);
					Vue.set(this.relevanceCreated, position.id, response.data ? true : false);
				}
				console.log("this.relevanceCreated: ", this.relevanceCreated);
			},
			zoomUpdate(zoom) {
				this.currentZoom = zoom;
			},
			centerMapOnPosition(position) {
				console.log("position: ", latLng(position.lat, position.lon));
				this.center = latLng(position.lat, position.lon);
			},
			async sendCommandGPS(value) {
				await this.commandConsole.sendCommand(
					{
						cmd: "UPDATE_CONFIGURATION",
						params: ['GPS_CRITERION', value],
						user: JSON.parse(localStorage.getItem('user')) || {}
					},
					localStorage.getItem("deviceId")
				);
				await new Promise(resolve => setTimeout(resolve, 4000));
				await this.getGpsCriterio();
			},
			async sendCommand(command) {
				await this.commandConsole.sendCommand(
					{
						cmd: command,
						params: [],
						user: JSON.parse(localStorage.getItem('user')) || {}
					},
					localStorage.getItem("deviceId")
				);
				await new Promise(resolve => setTimeout(resolve, 4000));
				await this.getGpsCriterio();
			},
			async getGpsCriterio() {
				await axios.get(`${apiUrl}/device/gps/criterion`)
					.then((response) => {
						console.log("response: ", response);
						this.configuationActive = response.data.gpsCriterion;
						console.log("this.configuationActive: ", this.configuationActive);
					})
					.catch((error) => {
						console.log(error);
					});
			},
			FormatDate(date) {
				let dateElem = moment(date);

				let second = dateElem.seconds();
				let minute = dateElem.minutes();
				let hour = dateElem.hours();
				let day = dateElem.date();
				let month = dateElem.month();
				let years = dateElem.year();

				return `${day}/${month}/${years} ${hour}:${minute}:${second}`;
			},
			ProxyAddOneHour(date, type) {
				return addOneHour(date, type == 3);
			},
			centerUpdate(center) {
				this.currentCenter = center;
			},
			async showDescription(pos) {
				this.actualConfigurationPosition = pos;
			},
			async searchPosition(start_date, end_date) {
				this.searchResult = (await Device.api().post(
					`${apiUrl}/device/positions`,
					{
						// filter: {
						//   start_date,
						//   end_date
						// }
					},
					{
						persistBy: 'update',
						persistOptions: {
							insertOrUpdate: ['gpsPositions']
						}
					})).entities.gpsPositions;
				await this.updateRelevanceCreated();
			}
		},
		computed: {
			device() {
				return Device.query()
					.with('state')
					.with('positions', query => query.orderBy('created_at', 'desc'))
					.find(localStorage.getItem("deviceId"))
			},
			pagesLength() {
				// Lunghezza pagina
				return this.positions && this.positions.length > 0
					? Math.ceil(this.positions.length / this.positionsPerPage)
					: 0;
			},
			pageItems() {
				// Posizioni per pagina
				const start = (this.page - 1) * this.positionsPerPage;
				const end = start + this.positionsPerPage /* - 1 */;
				return this.positions ? this.positions.slice(start, end) : [];
			},
			withTooltip() {
				if (this.device && this.positions && this.positions.length > 0)
					return latLng(this.positions[0].lat, this.positions[0].lon)
				return latLng(0, 0);
			},
			polyline() {
				return {
					latlngs: [
						...this.pageItems
					],
					color: "green"
				}
			},
			showMap() {
				return this.pageItems.length > 0
			}
		},
		async created() {
			await Device.dispatch(FETCH_DEVICE_POSITIONS,
				{
					id: localStorage.getItem("deviceId"),
					// limit: 50,
				}
			)
			await this.getGpsCriterio();
			if (!this.commandConsole) {
				console.log('[CREATING CONSOLE...]')
				await Console.insertOrUpdate({
					data: {
						deviceId: localStorage.getItem("deviceId"),
						device: this.device,
					}
				}).then((data) => {
					this.commandConsole = data.console[0];
				});
			}
			if (this.device && this.device.positions && this.device.positions.length > 0) {
				this.center = latLng(this.device.positions[0].lat, this.device.positions[0].lon)
				this.positions = this.device.positions;
			}
			await this.updateRelevanceCreated();
		}
	};
</script>
<style scoped>
	.button-gps__container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin-top: 20px;
	}

	.info-button__container {
		margin-top: 20px;
	}

	.info-text {
		text-align: left;
	}

	.button-gps__container > button {
		margin: 10px;
	}

	.btn-info {
		width: 50%;
	}

	.content-span__btn {
		font-size: 10px;
		width: 60%;
		white-space: break-spaces;
	}

	.filter-list {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.filter-item {
		display: grid;
		width: 100%;
		grid-template-columns: 150px 1fr auto;
		align-items: center;
		gap: 1rem;
		padding: 0.5rem;
	}

		.filter-item label {
			font-weight: bold;
			margin-right: 0.5rem;
		}

	.filter-content {
		width: 100%;
		padding: 0.3rem;
	}
</style>
<style>
	.mx-input, .mx-datepicker-main {
		background-color: #1E1E1E !important;
		color: white !important;
	}

	.mx-btn {
		color: white !important;
	}

	.cell.not-current-month {
		color: #73879c !important;
	}

	.cell {
		color: #ccc !important;
	}

		.cell.hover-in-range, .cell.in-range {
			background-color: darkslategray !important;
		}

		.cell:hover {
			background-color: cornflowerblue !important;
			color: white !important;
		}
</style>