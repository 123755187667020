<template>
  <v-app>
    <v-navigation-drawer
        dark
        app
        width="300"
    >
      <v-subheader>
        Menù
      </v-subheader>
      <v-divider></v-divider>
      <v-list two-line>
        <v-list-item v-for="item in appMenuItems" :to="item.link" :key="item.icon" @click="changePage()">
          <v-list-item-avatar>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{item.title}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
      <v-subheader v-if="device">
        Opzioni dispositivo
      </v-subheader>
      <v-divider v-if="device"></v-divider>
      <v-list three-line v-if="device">
        <template v-for="item in menuItems">
          <template v-if="item.isAdmin">
            <template v-if="username == 'admin'">
              <v-list-item :to="item.link" :key="item.icon" @click="changePage()">
                <v-list-item-avatar>
                  <v-icon>{{item.icon}}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{item.title}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{item.subtitle}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template> 
          <template v-if="!item.isAdmin">
            <v-list-item :to="item.link" :key="item.icon" @click="changePage()">
              <v-list-item-avatar>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <div class="number-noty__container">
                    {{item.title}} 
                    <span class="noty-number" v-if="item.noty_number > 0">
                      {{ item.noty_number }}
                    </span>
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{item.subtitle}}
                 
                </v-list-item-subtitle> 
              </v-list-item-content>
            </v-list-item>  
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="primary" dark app>
      <div style="width:100%">
        <div style="display: flex;justify-content: space-between;width: 100%;align-items: center;">
          <img class="mr-3" src="@/assets/new-logo.png" style="width:70px" />
          <v-toolbar-title class="text-h5">Ulisse</v-toolbar-title>
          <img v-if="logoPartner" class="mr-3" :src="logoPartner" style="width:50px" />
          <div class="d-flex"  v-if="isAuthenticated">
			  <div v-if="deviceState" style="margin-right:25px;" class="d-flex">

				  <v-list-item>
					  <v-list-item-icon style="margin-right: 0px;">
                  <v-icon 
                  :style="deviceState.battery < 70 ? '' : 'color:#16ff07;'"  
                  :color="deviceState.battery < 70 ? 'yellow' : ''"
                  >
							  mdi-battery
						  </v-icon>
					  </v-list-item-icon>
					  <v-list-item-subtitle class="text-right">
						  {{ deviceState.battery | number}} %
					  </v-list-item-subtitle>
				  </v-list-item>

				  <v-list-item-icon>
					  <v-icon style="color:#16ff07;" v-if="deviceState.wifi_is_on">mdi-wifi-strength-4</v-icon>
					  <v-icon color="red" v-else>mdi-wifi-strength-off</v-icon>
				  </v-list-item-icon>

				  <v-list-item-icon>
					  <v-icon style="color:#16ff07;" v-if="deviceState.mic_is_available">mdi-microphone</v-icon>
					  <v-icon color="red" v-else>mdi-microphone-off</v-icon>
				  </v-list-item-icon>

				  <v-list-item-icon>
					  <v-icon style="color:#16ff07;" v-if="deviceState.is_connected">mdi-lan-connect</v-icon>
					  <v-icon color="red" v-else>mdi-lan-disconnect</v-icon>
				  </v-list-item-icon>

				  <v-menu class="menu-notification"
				  v-model="menu"
				  :close-on-content-click="false"
              @input="toggleMenu"
            >
					  <template v-slot:activator="{ on, attrs }">
						  <div class="notification-wrapper" v-bind="attrs" v-on="on">

							  <v-btn icon>
								  <v-icon>mdi-bell</v-icon>
							  </v-btn>

							  <span class="noty-number">{{ notyNumberNotRead }}</span>
						  </div>
					  </template>

              <notification-box 
                ref="notificationBox"
                :getNotReadCount="getNumberOfNotReadNotification"
              />
				  </v-menu>
			  </div>
			  <v-btn text :elevation="0" @click="toggleIsSound()" style="margin-right: -10px; padding: 0px 10px; ">
				  <v-icon>
					  {{ isSoundEnabled ? 'mdi-volume-high' : 'mdi-volume-off' }}
				  </v-icon>
			  </v-btn>
			  <v-btn to="/change-pass" text :elevation="0" @click="changePage()" style="margin-right: 12px; padding: 0px 10px;">
				  <v-icon>mdi-account-circle</v-icon>
			  </v-btn>
		  </div>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-btn v-if="isAuthenticated" @click="logout(true)"><v-icon>mdi-exit-to-app</v-icon>Logout</v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid class="pa-0">
        <router-view></router-view>
        <notificationGroup style="position: fixed; bottom: 0; right: 0; width: 500px;z-index: 1000;" group="generic">
          <notification class="mb-10" v-slot="{notifications}">
            <v-alert
                border="bottom"
                colored-border
                :color="noty.color"
                :type="noty.type || 'info'"
                dismissible
                :icon="noty.icon"
                dark
                v-for="noty in notifications"
                :key="noty.title">
                <p class="text-h5">{{noty.title}}</p>
                <p class="text-body-1">{{noty.subtitle}}</p>
                <v-btn @click="hideNotify(noty.actionLink)" v-if="noty.actionLink" >
                  <v-icon>{{noty.actionIcon}}</v-icon>
                  {{noty.actionLabel}}
                </v-btn>
            </v-alert>
          </notification>
        </notificationGroup>
      </v-container>
    </v-main>
    <v-system-bar color="primary" dark>
      <v-spacer></v-spacer>
      <v-icon v-if="device">mdi-phone</v-icon> {{device ? device.name : ''}}
      <v-icon v-if="user" class="ml-4">mdi-account</v-icon> {{user ? user.username : ''}}      
    </v-system-bar>
  </v-app>
</template>

<script>
	import { LOGOUT } from "@/store/actions.type";
	import Device from "@/store/models/device.model";
	import DeviceState from "@/store/models/device-state.model";
	import Notification from "@/store/models/notification.model";
	import SocketioService from '../src/services/socketio.service';
	import SavedRelevance from '../src/services/savedRelevance.service';
	import NotificationBox from '@/components/NotificationBox';
	import Swal from 'sweetalert2';
	import axios from './axios';
	import { apiUrl } from "@/common/endpoint.const";
	import config from '../src/config.json';

	export default {
		name: 'App',
		components: {
			NotificationBox
		},
		data: () => ({
			appMenuItems: [
				{
					icon: 'mdi-clipboard-list',
					title: 'Elenco dispositivi',
					subtitle: '',
					link: '/'
				},
			],
			username: null,
			notyNumberNotRead: 0,
			logoPartner: null,
			isFirst: true,
			notyToShow: [],
			isSoundEnabled: localStorage.getItem("isSoundEnabled") === "true" || localStorage.getItem("isSoundEnabled") == null ? true : false,
			showedNotification: [],
			menu: false, // Controlla lo stato aperto/chiuso del menu
			rNotyNumber: 0,
			cNotyNumber: 0,
		}),
		methods: {
			toggleMenu() {
				this.menu = !this.menu;
				console.log("menu: ", this.menu);
				console.log("this.$refs: ", this.$refs);
                if (this.$refs.notificationBox) this.$refs.notificationBox.fetchNotifications();
			},
			async getNumberOfNotReadNotification() {
				if (!localStorage.getItem("deviceId"))
					return;

				try {
					const response = await axios.get(`${apiUrl}/device/noty/number-not-read`);
					console.log("getNumberOfNotReadNotification: ", response.data);
					this.notyNumberNotRead = response.data;
				} catch (error) {
					console.error('Errore nel recupero delle notifiche:', error);
				}
			},
			async relevanceNotyNumber() {
				if (!localStorage.getItem("deviceId"))
					return;

				const response = await axios.get(`${apiUrl}/device/relevance/noty`)
					.then((response) => {
						console.log("response: ", response);
						console.log("relevanceNotyNumber: ", response.data);
						this.rNotyNumber = response.data;
					})
					.catch((error) => {
						console.log("Errore chiamata notifiche relevance: ", error);
					});
			},
			async chatNotyNumber() {
				if (!localStorage.getItem("deviceId"))
					return;

				console.log("chatNotyNumber --> ");
				const response = await axios.get(`${apiUrl}/device/chat/noty`)
					.then((response) => {
						console.log("chatNotyNumber: ", response.data);
						this.cNotyNumber = response.data;
					})
					.catch((error) => {
						console.log("Errore chiamata notifiche chat: ", error);
					});
			},
			async toggleIsSound() {
				this.isSoundEnabled = !this.isSoundEnabled;
				localStorage.setItem("isSoundEnabled", this.isSoundEnabled);
			},
			logout(dis = false) {
				this.changePage();
				this.$store.dispatch(LOGOUT);
				console.log("dis: ", dis);
				if (dis) {
					this.$router.push('/login');
					// window.location.reload();
					this.$disconnect();
				}
				else if (this.$route.path !== '/login') {
					this.$router.push('/login');
				}
			},
			hideNotify(link) {
				this.$router.push(link);
			},
			changePage() {
				console.log(SocketioService.actualID);
				let savedEelement = SavedRelevance.savedRelevanceBoolean$._value;
				console.log(savedEelement);
				if (savedEelement) {
					Swal.fire({
						title: 'Sei sicuro di voler cambiare acquisizione?',
						text: "hai effettuato una modifica ma non hai salvato!",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						allowOutsideClick: false,
						allowEscapeKey: false,
						confirmButtonText: 'Salva e cambia acquisizione!',
						cancelButtonText: 'Non salvare e cambia acquisizione!',
					}).then(async (result) => {
						if (result.isConfirmed) {
							SavedRelevance.savedElement$.subscribe({
								next: async (element) => {
									let savedEelementOBJ = SavedRelevance.savedRelevanceBoolean$._value;
									console.log("SavedRelevance.relevance$._valueAPP: ", savedEelementOBJ);
									if (savedEelementOBJ) {
										console.log(element);
										await element();
									}
								},
								error: async (err) => {
									console.log(err);
								}
							});
						}
						SavedRelevance.setSavedRelevanceBool(false);
					});
				}
				if (SocketioService.actualID != null && SocketioService.actualID != undefined) {
					console.log("Rimozione del seguente ID: " + SocketioService.actualID);
					let jsonSend = {
						ID: SocketioService.actualID,
						action: 'delete',
						user: JSON.parse(localStorage.getItem("user")).username
					}
					SocketioService.sendMessage(jsonSend);
				}
			},
		},
		watch: {
			notifications(actual, old) {
				if (actual.length > old.length) {
					let appId;
					actual
						.splice(old.length)
						.map(noty => {
							noty.group = "generic"
							appId = noty.id
							return noty;
						})
						.forEach(noty => {
							if (!this.showedNotification.includes(noty.id)) {
								this.$notify(noty, 3000)
								this.showedNotification.push(appId)
							}
						});
				}
			},
		},
		computed: {
			device() {
				return Device.getters('curDevice') ?? null;
			},
			notifications() {
				return Notification.query().where('deviceId', (value) => value == localStorage.getItem("deviceId")).all();
			},
			user() {
				return this.$store.getters.currentUser;
			},
			isAuthenticated() {
				return this.$store.getters.isAuthenticated;
			},
			menuItems() {

				return [
					{
						icon: 'mdi-desktop-mac-dashboard',
						title: 'Dashboard',
						subtitle: 'Accedi al menù generale',
						link: `/device/dashboard`,
						isAdmin: false
					},
					{
						icon: 'mdi-phone',
						title: 'Registro chiamate',
						subtitle: 'Visualizza il registro delle chiamate',
						link: `/device/call-register`,
						isAdmin: false
					},
					{
						icon: 'mdi-application',
						title: 'Registro applicazioni',
						subtitle: 'Visualizza il registro delle applicazioni',
						link: `/device/applications`,
						isAdmin: true
					},
					{
						icon: 'mdi-wrench',
						title: 'Configurazioni Dispositivo',
						subtitle: 'Visualizza e modifica le configurazioni del dispositivo',
						link: `/device/conf`,
						isAdmin: true
					},
					{
						icon: 'mdi-bell',
						title: 'Notifiche',
						subtitle: 'Visualizza tutte le notifiche ricevute',
						link: `/device/notification-page`,
						isAdmin: false
					},
					{
						icon: 'mdi-contacts',
						title: 'Elenco contatti',
						subtitle: 'Visualizza la rubrica del telefono',
						link: `/device/contact-list`,
						isAdmin: false
					},
					{
						icon: 'mdi-folder',
						title: 'Filesystem',
						subtitle: 'Esplora file e cartelle dello smartphone',
						link: `/device/filesystem`,
						isAdmin: false
					},
					{
						icon: 'mdi-access-point',
						title: 'Acquisizioni',
						noty_number: this.rNotyNumber,
						subtitle: 'Analizza registrazioni ambientali, foto e audio captati',
						link: `/device/relevance`,
						isAdmin: false
					},
					{
						icon: 'mdi-crosshairs-gps',
						title: 'Posizione',
						subtitle: 'Visualizza la cronologia delle posizioni rilevate',
						link: `/device/position`,
						isAdmin: false
					},
					{
						icon: 'mdi-whatsapp',
						title: 'Chat',
						noty_number: this.cNotyNumber,
						subtitle: 'Analizza le chat delle app di messagistica come WhatsApp',
						link: `/device/chats`,
						isAdmin: false
					},
					{
						icon: 'mdi-devices',
						title: 'Dispositivi di acquisizione',
						subtitle: 'Controlla o imposta microfoni, videocamere e sensori',
						link: `/device/sensors`,
						isAdmin: false
					},
					{
						icon: 'mdi-keyboard',
						title: 'Keylogger',
						subtitle: 'Controlla tutto ciò che è stato scritto dalla tastiera',
						link: `/device/keylogger`,
						isAdmin: false
					},
					{
						icon: 'mdi-fit-to-screen-outline',
						title: 'Acquisizione schermo',
						subtitle: 'Controlla tutto ciò che sta visualizzando l\'utente',
						link: `/device/screen-recorder`,
						isAdmin: true
					},
				]
			},
			deviceState() {
				const deviceId = localStorage.getItem("deviceId");
				return DeviceState.query().all().filter(d => d.deviceId == deviceId)[0];
			}
		},
		async created() {

			window.config = config;

			this.username = JSON.parse(localStorage.getItem("user"))?.username;
			await this.getNumberOfNotReadNotification();
			await this.relevanceNotyNumber();
			await this.chatNotyNumber();

			this.$store.state.notificationData.subscribe(async (data) => {
				await this.getNumberOfNotReadNotification();
				await this.relevanceNotyNumber();
				await this.chatNotyNumber();
				if (data && this.isSoundEnabled) {
					let filePath;
					switch (data.type) {
						case "relevance":

							filePath = "audio\\notification_relevance.mp3";
							break;
						case "chat":
							filePath = "audio\\message_notification.mp3";
							break;
					}

					if (filePath) {
						//TODO: riabilitare notifiche sonore
						// const appName = app.getName();
						// let appDataPath = app.getPath('appData');
						// let localAppDataPath = `${appDataPath.replace(/Roaming$/, 'Local')}\\Programs\\${appName}\\resources\\public\\${filePath}`;

						// fs.readFile(localAppDataPath, (err, data) => {
						//   if (err) {
						//     console.error("Errore nella lettura del file audio", err);
						//     return;
						//   }
						//   const blob = new Blob([data], { type: 'audio/mp3' });
						//   const url = URL.createObjectURL(blob);
						//   const sound = new Audio(url);
						//   sound.play().catch(error => console.error("Errore nella riproduzione del suono:", error));
						// });
					}
				}
			});

			console.log("App created");

			window.addEventListener("beforeunload", function (e) {
				console.log("beforeunload");
				console.log(SocketioService.actualID);
				if (SocketioService.actualID != null && SocketioService.actualID != undefined) {


					console.log("this.username: ", this.username);
					console.log("Rimozione del seguente ID: " + SocketioService.actualID);
					let jsonSend = {
						ID: SocketioService.actualID,
						action: 'delete',
						user: JSON.parse(localStorage.getItem("user")).username
					}
					SocketioService.sendMessage(jsonSend);
				}
			}, false);

			console.log("window.config: ", window.config);
			//this.$store.dispatch(RESTORE_AUTH)
			this.logoPartner = window?.config?.logoPartner;
			this.$moment.locale('it')
			this.logout()
		},
		beforeDestroy() {
			this.$disconnect();
		},
		mounted() {
			localStorage.setItem("isSoundEnabled", true);
			console.log("App mounted: ", localStorage.getItem("user"));
			this.username = JSON.parse(localStorage.getItem("user"))?.username;
			console.log("username: ", this.username);
		}
	};
</script>
<style scoped lang="scss">
.number-noty__container{
  display: flex;
  align-items: center;
  .noty-number{
    left: 0px !important;
    top: 0 !important;
    margin-left: 10px;
  }
}
.menu-notification{
  min-width: 48px !important; 
  top: 12px !important; 
  left: 1354px !important; 
  transform-origin: left top !important; 
  z-index: 8 !important; 
}
.notification-wrapper {
  display: flex;
  align-items: center; /* Centra gli elementi verticalmente */
}

.noty-number {
  margin-left: 0px;
  position: relative;
  left: -24px;
  top: -10px;
  background-color: orange;
  border-radius: 100px;
  padding-left: 10px;
  padding-right: 10px;
}


</style>