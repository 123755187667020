import {Model} from "@vuex-orm/core";
import Device from "@/store/models/device.model";

export default class Schedule extends Model{
  static entity = 'schedule';

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      created_at: this.attr(null),
      start_date: this.attr(null),
      end_date: this.attr(null),
      duration: this.attr(null),
      ssid: this.attr(null),
      lat: this.attr(null),
      lon: this.attr(null),
      radius: this.attr(null),
      hasWifiFence: this.attr(null),
      hasGeoFence: this.attr(null),
      active: this.attr(null),
      deviceId: this.attr(null),
      device: this.belongsTo(Device, 'deviceId'),
      loading: this.boolean(false),
    }
  }
}