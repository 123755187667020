import {Model} from "@vuex-orm/core";
import Device from "@/store/models/device.model";

export default class WiFi extends Model{
  static entity = 'wifis';

  static fields(){
    return {
      id: this.attr(null),
      ssid: this.attr(null),
      deviceId: this.attr(null),
      device: this.belongsTo(Device, 'deviceId'),
    }
  }
}