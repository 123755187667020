import { Model } from "@vuex-orm/core";
import Device from "@/store/models/device.model";
import Chat from "@/store/models/chat.model";
import File from "@/store/models/file.model";
import {NEW_CHAT_MESSAGE} from "@/store/actions.type";
import ChatMessageUtils from "../../utils/ChatMessageUtils";
export default class ChatMessage extends Model {
  static entity = 'chat_messages';

  static fields() {
    return {
      id: this.attr(null),
      source: this.attr(null),
      created_at: this.attr(null),
      direction: this.attr(null),
      interlocutor: this.attr(null),
      fileType: this.attr(null),
      relevance_grade: this.attr(null),
      content: this.attr(null),
      read: this.boolean(false),
      file: this.belongsTo(File, 'fileId'),
      chatId: this.attr(null),
      chat: this.belongsTo(Chat, 'chatId'),
      traduzione: this.attr(null),
      fileId: this.attr(null),
      device: this.belongsTo(Device, 'deviceId'),
      last_user_update: this.attr(null)
    }
  }
}

export const chatMessage = {
  actions: {
    [NEW_CHAT_MESSAGE](context, params){
      console.log("params.data: ",params.data);
      // Notification.insert({
        // data: {
          // icon: 'mdi-progress-upload',
          // color: 'yellow darken-3',
          // title: `Nuovo messaggio arrivato`,
          // subtitle: 'E\' arrivato un nuovo messaggio',
          // actionLabel: 'OK',
          // actionIcon: 'mdi-check',
          // deviceId: 1,
        // }
      // })
      ChatMessageUtils.newMessageArrive(params.data);
      ChatMessage.insert({
        data: {
          ...params.data
        }
      })
    }
  }
}

