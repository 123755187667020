import { Model } from "@vuex-orm/core";
import Device from "@/store/models/device.model";

export default class TrafficRegister extends Model {
  static entity = 'traffic_registers';

  static fields() {
    return {
      id: this.attr(null),
      value: this.attr(null),
      created_at: this.attr(null),
      deviceId: this.attr(null),
      device: this.belongsTo(Device, 'deviceId'),
    }
  }
}