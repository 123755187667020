import { render, staticRenderFns } from "./RelevanceEventDetails.vue?vue&type=template&id=619a6b46&scoped=true"
import script from "./RelevanceEventDetails.vue?vue&type=script&lang=js"
export * from "./RelevanceEventDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "619a6b46",
  null
  
)

export default component.exports