import {Model} from "@vuex-orm/core";
import Device from "@/store/models/device.model";

export default class GpsPosition extends Model {
  static entity = 'gpsPositions';

  static fields() {
    return {
      id: this.attr(null),
      lat: this.attr(null),
      lon: this.attr(null),
      created_at: this.attr(null),
      deviceId: this.attr(null),
      device: this.belongsTo(Device, 'deviceId')
    }
  }
}