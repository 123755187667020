import { Model } from "@vuex-orm/core";
import Device from "@/store/models/device.model";

export default class BatteryRegister extends Model {
  static entity = 'battery_registers';

  static fields() {
    return {
      id: this.attr(null),
      level: this.attr(null),
      created_at: this.attr(null),
      deviceId: this.attr(null),
      device: this.belongsTo(Device, 'deviceId'),
    }
  }
}