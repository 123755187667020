import { Model } from "@vuex-orm/core";
import Device from "@/store/models/device.model";
import CallRegister from "@/store/models/call-register.model";
import Chat from "@/store/models/chat.model";
import Notification from "@/store/models/notification.model";
import {CONTACT_ADDED} from "@/store/actions.type";

export default class Contact extends Model {
  static entity = 'contacts';

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      phone: this.attr(null),
      known: this.attr(null),
      total_received_calls: this.attr(null),
      total_sent_calls: this.attr(null),
      total_lost_calls: this.attr(null),
      total_received_whatsapp: this.attr(null),
      total_sent_whatsapp: this.attr(null),
      total_received_telegram: this.attr(null),
      total_sent_telegram: this.attr(null),
      total_received_sms: this.attr(null),
      total_sent_sms: this.attr(null),
      total_received_messages: this.attr(null),
      total_sent_messages: this.attr(null),
      deviceId: this.attr(null),
      device: this.belongsTo(Device, 'deviceId'),
      calls: this.hasMany(CallRegister, 'contactId'),
      chats: this.hasMany(Chat, 'contactId'),
    }
  }
}

export const contact = {
  actions: {
    [CONTACT_ADDED](context, params){
      Contact.insert({
        data: params.data,
      })
      Notification.insert({
        data: {
          icon: 'mdi-call',
          color: 'red darken-3',
          title: `Contatto aggiunto alla rubrica`,
          subtitle: `${params.data.name} è stato aggiunto alla rubrica`,
          actionLabel: 'Visualizza',
          actionIcon: 'mdi-check',
          actionLink: `/device/contact-list`,
          deviceId: params.data.deviceId,
        }
      })
    }
  }
}