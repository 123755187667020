<template>
  <div>
    <v-list-item v-if="!editing">
      <v-list-item-content>
        <v-list-item-title>Fascia {{ schedule.id }} - dalle {{schedule.start}} alle {{schedule.end}}</v-list-item-title>
        <v-list-item-subtitle v-if="!schedule.wifiFence">
          WifiFencing: no
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="schedule.wifiFence">
          WifiFencing: nome linea {{schedule.wifiFence}}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="schedule.gpsFence">
          GeoFencing: si ({{schedule.gpsFence.lat}} - {{schedule.gpsFence.lon}}, r: {{schedule.gpsFence.radius / 1000}} Km)
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="schedule.gpsFence === null">
          GeoFencing: no
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-else>
      <v-list-item-content>
        <v-list-item-title>Fascia {{schedule.id}}</v-list-item-title>
        <v-list-item-subtitle>Ora inizio</v-list-item-subtitle>
        <v-list-item-subtitle>WifiFencing: no</v-list-item-subtitle>
        <v-list-item-subtitle>GeoFencing: si - Area 1</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>mdi-delete</v-icon>
        <v-icon class="mt-2" @click="editDialog = true">mdi-pencil</v-icon>
        <v-icon class="mt-2">mdi-content-copy</v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>
    <v-dialog
        persistent
        transition="dialog-top-transition"
        max-width="500px"
        v-model="editDialog">
      <v-card>
        <v-toolbar
            color="primary"
            dark
        >Imposta fascia oraria di registrazione</v-toolbar>
        <v-card-text>
          <v-container>
            <v-row align="center">
              <v-col cols="6" align-self="end">
                <p class="text-body-1">Ora inizio</p>
              </v-col>
              <v-col cols="6" align-self="end">
                <v-row>
                  <v-col cols="6"><v-select v-model="startHour" :items="hours"></v-select></v-col>
                  <v-col cols="6"><v-select v-model="startMinute" :items="minutes"></v-select></v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="6" align-self="end">
                <p class="text-body-1">Ora fine</p>
              </v-col>
              <v-col cols="6" align-self="end">
                <v-row>
                  <v-col cols="6"><v-select v-model="endHour" :items="hours"></v-select></v-col>
                  <v-col cols="6"><v-select v-model="endMinute" :items="minutes"></v-select></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              @click="editDialog = false"
          ><v-icon>mdi-cancel</v-icon>Annulla</v-btn>
          <v-btn
              color="primary"
              @click="editDialog = false"
          ><v-icon>mdi-content-save</v-icon>Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {hours} from "@/common/hours";
import {minutes} from "@/common/minutes";

export default {
  name: "ScheduleItem",
  props: ['editing', 'schedule'],
  data(){
    return {
      editDialog: false,
      hours,
      minutes,
    }
  },
  watch: {
    schedule: {
      handler(hour){
        console.log(hour)
      },
      deep: true,
    }
  },
  computed: {
    startHour: {
      get(){
        return this.schedule.start.split(':')[0]
      },
      set(hour){
        this.schedule.start = [hour, this.startMinute].join(':')
      }
    },
    startMinute: {
      get(){
        return this.schedule.start.split(':')[1]
      },
      set(minute){
        this.schedule.start = [this.startHour, minute].join(':')
      }
    },
    endHour: {
      get(){
        return this.schedule.end.split(':')[0]
      },
      set(hour){
        this.schedule.end = [hour, this.endMinute].join(':')
      }
    },
    endMinute: {
      get(){
        return this.schedule.end.split(':')[1]
      },
      set(minute){
        this.schedule.end = [this.endHour, minute].join(':')
      }
    }
  }
}
</script>

<style scoped>
</style>