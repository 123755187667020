import { BehaviorSubject } from 'rxjs';
import File from "@/store/models/file.model";
import { apiUrl } from "@/common/endpoint.const";
class SavedRelevance {
	static savedElement$ = new BehaviorSubject(false);
	static relevance$ = new BehaviorSubject();
	static savedRelevanceBoolean$ = new BehaviorSubject(false);

	static setSavedElement(value) {
		console.log("CREAZIONE SET SAVED ELEMENT");
		SavedRelevance.savedElement$.next(value);
	}

	static setRelevance(value) {
		console.log("setRelevance-value: ", value);
		SavedRelevance.relevance$.next(value);
	}

	static setSavedRelevanceBool(value, debug = "") {
		console.log("DEBUG: ", debug);
		console.log("SET RELEVANCE: ", value);
		SavedRelevance.savedRelevanceBoolean$.next(value);
	}

	static async getDataRelevance(id) {
		const response = (await File.api().get(`${apiUrl}/device/filesystem/relevace/file/id/${id}`)).response.data;
		return response;
	}

	static async createRelevanceFromFile(id) {
		await File.api().get(`${apiUrl}/device/relevance/create-from-file/${id}`);
	}
	static async createRelevanceFromFile(id, messageDate) {
		await File.api().post(`${apiUrl}/device/relevance/create-from-file/${id}`, { messageDate });
	}
}

export default SavedRelevance;