import {
	EMPTY_SOCKET_BUFFER,
	SOCKET_AUTH,
	SOCKET_CONNECT,
	SOCKET_ONCLOSE,
	SOCKET_ONERROR,
	SOCKET_ONMESSAGE,
	SOCKET_ONOPEN,
	SOCKET_SEND,
	WS_AUTH,
	SOCKET_ONRECONNECT,
	SOCKET_AUTH_RESPONSE,
	SERVER_COMMAND_ACK,
	SERVER_FILE_UPLOADED,
	REFRESH_DIRECTORY,
	DEVICE_STATE_UPDATE,
	PAGE_LOG_UPDATE,
	NOTIFICATION_FROM_SERVER,
	NOTIFICATION_UPDATE,
	CALL_REGISTER_EVENT,
	CONTACT_ADDED,
	ADDED_RELEVANCE,
	NEW_CHAT_MESSAGE,
	SUBSCRIBE_ON_DEVICE_ROOM
} from "@/store/actions.type";
import {
	CLOSED_SOCKET,
	ERROR_SOCKET,
	OPENED_SOCKET,
	PUSH_TO_BUFFER,
	RECONNECT_SOCKET,
	SAVE_SOCKET_BUFFER, SOCKET_AUTHENTICATED, SUBSCRIBE_SOCKET_EVENT
} from "@/store/mutations.type";
import Vue from "vue";
import { consoleModule } from "@/store/models/console.model";
import { sleep } from "@/common/utils";
import Device from "@/store/models/device.model";
import Notification from "@/store/models/notification.model";
import { wsUrl } from "../common/endpoint.const";

const sendMessageWS = (data, event) => {
	if (!Vue.prototype.$socket || false === !!event)
		return false
	const msg = { data, event }
	console.log('sending', msg)
	Vue.prototype.$socket.sendObj(msg)
	return true;
};

export const state = {
	isConnected: false,
	isAuthenticated: false,
	reconnecting: false,
	buffer: [],
	listeners: [
		{
			subscribe: 'AUTH',
			action: 'dispatch',
			actionName: SOCKET_AUTH_RESPONSE
		},
		{
			subscribe: 'server:.*',
			action: 'dispatch',
			actionName: NOTIFICATION_FROM_SERVER
		},
		{
			subscribe: 'server:cmd_ack',
			action: 'dispatch',
			actionName: SERVER_COMMAND_ACK
		},
		{
			subscribe: 'server:file_uploaded',
			action: 'dispatch',
			actionName: SERVER_FILE_UPLOADED
		},
		{
			subscribe: 'server:filesystem_update',
			action: 'dispatch',
			actionName: REFRESH_DIRECTORY
		},
		{
			subscribe: 'device:state_update',
			action: 'dispatch',
			actionName: DEVICE_STATE_UPDATE
		},
		{
			subscribe: 'device:page_log_update',
			action: 'dispatch',
			actionName: PAGE_LOG_UPDATE
		},
		{
			subscribe: 'device:notification_update',
			action: 'dispatch',
			actionName: NOTIFICATION_UPDATE
		},
		{
			subscribe: 'device:call_*',
			action: 'dispatch',
			actionName: CALL_REGISTER_EVENT
		},
		{
			subscribe: 'device:contact_created',
			action: 'dispatch',
			actionName: CONTACT_ADDED
		},
		{
			subscribe: 'device:relevance_created',
			action: 'dispatch',
			actionName: ADDED_RELEVANCE
		},
		{
			subscribe: 'device:chat_update',
			action: 'dispatch',
			actionName: NEW_CHAT_MESSAGE,
		}
	]
}

export const actions = {
	async [SOCKET_CONNECT]({ state }) {
		if (state.isConnected)
			return true;
		Vue.prototype.$connect(wsUrl + `?access_token=${localStorage.getItem("token")}`);
		await sleep(200)
		return true;
	},
	[SOCKET_AUTH]({ state, commit }, token) {
		if (!state.isConnected)
			return false;

		return sendMessageWS({
			who: 2,
			token
		}, 'AUTH')
	},
	[SOCKET_SEND]({ commit, getters }, message) {
		console.log(message)
		if (!message.event)
			return 0;

		if (!getters.isReady) {
			commit(PUSH_TO_BUFFER, message)
			return 0;
		}

		const result = sendMessageWS(message.data, message.event)
		return result ? 1 : -1
	},
	[SOCKET_ONOPEN]({ rootState, state, commit, dispatch }, { target }) {
		commit(OPENED_SOCKET, target);

		if (state.reconnecting && rootState.auth.user && rootState.auth.user.secret) {
			dispatch(SOCKET_AUTH, rootState.auth.user.secret)
			state.reconnecting = false;
			if (rootState.entities.devices.curDevice) {
				Device.dispatch(SUBSCRIBE_ON_DEVICE_ROOM, rootState.entities.devices.curDevice)
			}
		}

		if (state.isConnected && !state.isAuthenticated && rootState.auth.user && rootState.auth.user.secret)
			dispatch(SOCKET_AUTH, rootState.auth.user.secret)

	},
	[SOCKET_ONCLOSE]({ commit }) {
		commit(CLOSED_SOCKET);
	},
	[SOCKET_ONERROR]({ commit }, event) {
		commit(ERROR_SOCKET, event);
	},
	[SOCKET_ONRECONNECT]({ commit }) {
		commit(RECONNECT_SOCKET)
	},
	[SOCKET_ONMESSAGE](context, { message }) {
		if (message.event == undefined && message.data == undefined) {
			setTimeout(() => context["dispatch"]("serverCommandAck", { recieved: false }), 3000);
		}
		for (const listener of context.state.listeners) {
			if (new RegExp(listener.subscribe).test(message.event)) {
				context[listener.action](listener.actionName, message)
			}
		}
	},
	[SOCKET_AUTH_RESPONSE](context, { data }) {
		if (data.ok) {
			context.commit(SOCKET_AUTHENTICATED)
			if (state.buffer.length > 0)
				context.dispatch(EMPTY_SOCKET_BUFFER);
		}
	},
	[EMPTY_SOCKET_BUFFER]({ state, dispatch, commit }) {
		const originalLength = state.buffer.length
		console.log(`emptying buffer (${state.buffer.length} packet(s))...`)
		const packets = [...state.buffer].reverse();
		console.log([...packets]);
		for (let i = packets.length - 1; i >= 0; i--) {
			if (dispatch(SOCKET_SEND, packets[i]) > 0) {
				console.log(`packets[${i}] sent!`)
				packets.splice(i, 1)
			}
			else console.log(`packets[${i}] not sent!`)
		}
		console.log(`sent ${state.buffer.length}/${originalLength} packet(s)...`)
		commit(SAVE_SOCKET_BUFFER, packets.reverse())
	}
}

export const mutations = {
	[OPENED_SOCKET](state, target) {
		Vue.prototype.$socket = target;
		state.isConnected = true;
	},
	[CLOSED_SOCKET](state) {
		state.isConnected = false;
		Vue.prototype.$socket = null
	},
	[ERROR_SOCKET](state, error) {
		console.error(error)
	},
	[SAVE_SOCKET_BUFFER](state, buffer) {
		state.buffer = buffer;
	},
	[PUSH_TO_BUFFER](state, message) {
		state.buffer.push(message)
	},
	[SOCKET_AUTHENTICATED](state) {
		state.isAuthenticated = true
	},
	[RECONNECT_SOCKET](state) {
		state.reconnecting = true
	},
	[SUBSCRIBE_SOCKET_EVENT](state, listener) {
		state.listeners.push(listener);
	}
}

export const getters = {
	isReady: (state) => state.isConnected && state.isAuthenticated,
}

export default {
	state,
	actions,
	mutations,
	getters
}