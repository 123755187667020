class ChatMessageUtils {
    static updateMessage = null;

    static newMessageArrive(message) {
        if(this.updateMessage) {
            this.updateMessage(message);
        }
    }

    static setUpdateMessage(updateMessage) {
        this.updateMessage = updateMessage;
    }
}

export default ChatMessageUtils;