<template>
  <v-list two-line>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          Chiamate totali
        </v-list-item-title>
        <v-list-item-subtitle>
          ( ricevute / effettuate )
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        {{contact.total_received_calls + contact.total_sent_calls}}<br/>
        <span class="text-caption">({{contact.total_received_calls }}/{{ contact.total_sent_calls}})</span>
      </v-list-item-action>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          Messaggi totali
        </v-list-item-title>
        <v-list-item-subtitle>
          ( ricevuti / inviati )
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        {{contact.total_received_messages + contact.total_sent_messages}}<br/>
        <span class="text-caption">({{contact.total_received_messages }}/{{ contact.total_sent_messages}})</span>
      </v-list-item-action>
    </v-list-item>

    <v-list-group>
      <template v-slot:activator>
        <v-list-item-avatar>
          <v-icon>mdi-message-question</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            Dettagli messaggi
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            Whatsapp
          </v-list-item-title>
          <v-list-item-subtitle>
            ( ricevuti / inviati )
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          {{contact.total_received_whatsapp + contact.total_sent_whatsapp}}<br/>
          <span class="text-caption">({{contact.total_received_whatsapp }}/{{ contact.total_sent_whatsapp}})</span>
        </v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            Telegram
          </v-list-item-title>
          <v-list-item-subtitle>
            ( ricevuti / inviati )
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          {{contact.total_received_telegram + contact.total_sent_telegram}}<br/>
          <span class="text-caption">({{contact.total_received_telegram }}/{{ contact.total_sent_telegram}})</span>
        </v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            SMS
          </v-list-item-title>
          <v-list-item-subtitle>
            ( ricevuti / inviati )
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          {{contact.total_received_sms + contact.total_sent_sms}}<br/>
          <span class="text-caption">({{contact.total_received_sms }}/{{ contact.total_sent_sms}})</span>
        </v-list-item-action>
      </v-list-item>

    </v-list-group>
  </v-list>
</template>

<script>
export default {
  name: "ContactActivityInfo",
  props: ['contact']
}
</script>