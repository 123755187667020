import {Model} from "@vuex-orm/core";
import Device from "@/store/models/device.model";
import {NOTIFICATION_FROM_SERVER} from "@/store/actions.type";

export default class Notification extends Model {
  static entity = 'notifications';

  static fields(){
    return {
      id: this.attr(null),
      icon: this.attr(null),
      color: this.attr(null),
      title: this.attr(null),
      subtitle: this.attr(null),
      text: this.attr(null),
      actionLabel: this.attr(null),
      actionLink: this.attr(null),
      actionIcon: this.attr(null),
      deviceId: this.attr(null),
      device: this.belongsTo(Device, 'deviceId'),
      show: this.attr(null),
      group: this.attr(null),
    }
  }
}

export const notification = {
  actions: {
    [NOTIFICATION_FROM_SERVER](context, params){
      console.log(params);
    }
  }
}