<template>
  <div></div>
  <!-- <v-list-group v-if="events && events.length > 0">
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-subtitle>
          Eventi salienti <b>({{events.length}})</b>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>

    <div>
      <template v-for="rEvent in events">
        <v-list-item @dblclick="$emit('focusOnEvent', rEvent)" three-line :key="rEvent.happens_at">
          <v-list-item-content>
            <v-list-item-title>Avvenuto alle {{relevance.created_at | moment('add', `${rEvent.happens_at} seconds`) | moment(timestamp)}}</v-list-item-title>
            <v-list-item-subtitle>
              <v-text-field
                  dense
                  label="Etichetta evento"
                  v-model="rEvent.label"
              ></v-text-field>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-select
                  item-value="value"
                  item-text="label"
                  v-model="rEvent.relevance_grade"
                  :items="[
                          {
                            label: 'Molto importante',
                            value: '5'
                          },
                          {
                            label: 'Utile ai fini dell\'indagine',
                            value: '4'
                          },
                          {
                            label: 'Da valutare',
                            value: '3'
                          },
                          {
                            label: 'Non inerente',
                            value: '2'
                          },
                          {
                            label: 'Non utilizzabile',
                            value: '1'
                          },
                          {
                            label: 'Non impostata',
                            value: '0'
                          },
                      ]"
                  :placeholder="rEvent.relevance_grade | relevanceGradeLabel"
              ></v-select>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="rEvent.happens_at+'d'"></v-divider>
      </template>
    </div>

  </v-list-group> -->
</template>

<script>
import {timestamp} from '@/common/dateFilterFormat'

export default {
  name: "RelevanceEventDetails",
  props: ['events', 'relevance'],
  data(){
    return{
      timestamp,
    }
  },
  methods: {
    editEvent(event){
      event.editing = true;
    }
  }
}
</script>

<style scoped>

</style>