<template>

<div>
    <v-row class="align-content-center">
      <v-col cols="4"></v-col>
      <v-col cols="4" class="pa-4">
        <v-card>
          <v-card-title>Registrazione a 2 fattori</v-card-title>
          <v-card-text class="text-caption">
            <img v-bind:src="qr" class="text-center" v-if="qr" />
            <br/>
            <span v-if="qr" class="text-center">Scansiona il qr code con l'app "Google authenticator" ed inserisci il token che viene generato.</span>
            <v-text-field
                placeholder="token"
                v-model="token"
                color="primary"
            ></v-text-field>
            <p>{{ validationMessage }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn block dark color="primary" @click="check">Valida</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import axios from '../axios';
import {apiUrl} from "@/common/endpoint.const";
import {AUTHENTICATE} from "@/store/actions.type";

export default {
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  data() {
    return {
      email: '',
      error: '',
      twoFactorEnabled: false,
      qr: '',
      token: '',
      secret: '',
      validationMessage: '',
    };
  },
  created: function () {
    window.addEventListener('keydown', this.handleKeyDown);
    console.log("user: " , this.$store.getters.currentUser);
    if (this.$store.getters.currentUser.secret !== '')
    {
      console.log("user: ",  this.$store.getters.currentUser.secret);
      if (this.$store.getters.currentUser.secret === 'no2fa')
      {
        this.$store.dispatch(AUTHENTICATE);
        this.$router.push('/')
      }
      else {
        //nascondo qr e testo.
      }
    }
    else {
      this.step1();
    }
  },
  methods: {
    handleKeyDown(e) {
      if (e.key == "Enter") {   
        console.log(e.key);
        this.check();
      }
    },
    async step1(){
      await axios({
        method: 'POST',
        url: `${apiUrl}/auth/enabletwofactorstep1`,
        // data: credentials,
      }).then(({ data }) => {
        this.qr = data.qr;
        console.log("qr", data.qr);
        console.log("secret", data.secret);
        this.secret = data.secret;
      });
    },
    async check() {
      if (this.$store.getters.currentUser.secret !== ''){
        await this.validateToken();
      }
      else{
         await this.step2();
      }
    },
    async step2(){
      await axios({
        method: 'POST',
        url: `${apiUrl}/auth/enabletwofactorstep2`,
        data: {
          base32secret: this.secret,
          userToken: this.token,
          username: this.$store.getters.currentUser.username
        }
      }).then(({ data }) => {
        if (data){
          this.$store.dispatch(AUTHENTICATE);
          this.$router.push('/')
        }
        else{
          this.validationMessage = 'Il token non è valido, riprova.';
        }
      });
    },
     async validateToken(){
      await axios({
        method: 'POST',
        url: `${apiUrl}/auth/validatetoken`,
        data: {
          token: this.token,
          username: this.$store.getters.currentUser.username
        }
      }).then(({ data }) => {
        if (data){
          this.$store.dispatch(AUTHENTICATE);
          this.$router.push('/')
        }
        else{
          this.validationMessage = 'Il token non è valido, riprova.';
        }
      });
    },
  },
};
</script>