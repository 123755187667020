<script>
	import axios from '../../axios';
	import { apiUrl } from "@/common/endpoint.const";
	import Swal from 'sweetalert2';
	import Console from "@/store/models/console.model";

	export default {
		name: "DeviceConf",
		data() {
			return {
				whVocalPath: "",
				whImagePath: "",
				whBsVocalPath: "",
				whBsImagePath: "",
			};
		},
		computed: {
			console() {
				return Console.query()
					.with("device")
					.with("cmdHistory")
					.where("deviceId", localStorage.getItem("deviceId"))
					.first();
			},
		},
		methods: {
			async saveConfig() {
				const response = await axios.post(`${apiUrl}/device/update-config`, {
					wh_vocal_path: this.whVocalPath,
					wh_images_path: this.whImagePath,
					whbs_vocal_path: this.whBsVocalPath,
					whbs_images_path: this.whBsImagePath
				});
				if (response.status == 200) {
					const data = response.data;
					this.whVocalPath = data.wh_vocal_path;
					this.whImagePath = data.wh_images_path;
					this.whBsVocalPath = data.whbs_vocal_path;
					this.whBsImagePath = data.whbs_images_path;
					await this.console.sendCommand(
						{
							cmd: "UPDATE_CONFIGURATION",
							params: [
								"WHATSAPPBS_PATH_VOCAL",
								this.whBsVocalPath
							],
							user: JSON.parse(localStorage.getItem('user') || '') || {}
						}
					);
					await this.console.sendCommand(
						{
							cmd: "UPDATE_CONFIGURATION",
							params: [
								"WHATSAPPBS_PATH_IMG",
								this.whBsImagePath
							],
							user: JSON.parse(localStorage.getItem('user') || '') || {}
						}
					);
					await this.console.sendCommand(
						{
							cmd: "UPDATE_CONFIGURATION",
							params: [
								"WHATSAPP_PATH_VOCAL",
								this.whVocalPath
							],
							user: JSON.parse(localStorage.getItem('user') || '') || {}
						}
					);
					await this.console.sendCommand(
						{
							cmd: "UPDATE_CONFIGURATION",
							params: [
								"WHATSAPP_PATH_IMG",
								this.whImagePath
							],
							user: JSON.parse(localStorage.getItem('user') || '') || {}
						}
					);
					Swal.fire({
						title: 'Configurazione Dispositivo',
						text: 'Configurazione salvata con successo',
						icon: 'success',
						confirmButtonText: 'OK'
					});


				}
			}
		},
		async mounted() {
			const response = await axios.get(`${apiUrl}/device/get-config`);
			console.log("RESPONSE", response);
			if (response.status == 200) {
				const data = response.data;
				this.whVocalPath = data.wh_vocal_path;
				this.whImagePath = data.wh_images_path;
				this.whBsVocalPath = data.whbs_vocal_path;
				this.whBsImagePath = data.whbs_images_path;
			}
		},
		async created() {
			if (!this.console) {
				console.log("creating console...");
				await Console.insertOrUpdate({
					data: {
						deviceId: localStorage.getItem("deviceId"),
						device: this.device,
					},
				})
			}
		}
	}
</script>

<template>
	<v-card class="mx-auto">
		<v-card-title>Configurazione Dispositivo</v-card-title>
		<v-divider></v-divider>
		<v-card-text>
			<v-row>
				<v-col cols="12" sm="6">
					<v-text-field v-model="whVocalPath"
												label="Percorso Messaggi Vocali Whatsapp"
												required />
				</v-col>
				<v-col cols="12" sm="6">
					<v-text-field v-model="whImagePath"
												label="Percorso Immagini Whatsapp"
												required />
				</v-col>
				<v-col cols="12" sm="6">
					<v-text-field v-model="whBsVocalPath"
												label="Percorso Messaggi Vocali Whatsapp Business"
												required />
				</v-col>
				<v-col cols="12" sm="6">
					<v-text-field v-model="whBsImagePath"
												label="Percorso Immagini Whatsapp Business"
												required />
				</v-col>
				<v-col cols="12" sm="6">
					<v-btn @click="saveConfig" color="primary">Salva</v-btn>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<style scoped>
</style>