<template>
  <div>
    <v-card>
      <v-subheader>
        <p>Impostazioni registrazioni ambientali</p>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="!scheduleEditing" @click="scheduleEditing = true">
          <v-icon>mdi-pencil</v-icon>Modifica
        </v-btn>
        <v-btn v-if="scheduleEditing" @click="saveScheduleConf()">
          <v-icon>mdi-content-save</v-icon>Salva
        </v-btn>
      </v-subheader>
      <v-divider></v-divider>
      <v-list three-line>
        <schedule-item
            :editing="scheduleEditing"
            v-for="s in this.schedule"
            :schedule="s"
            :key="s.id"
        ></schedule-item>
      </v-list>
    </v-card>

    <v-dialog v-model="scheduleDialog"></v-dialog>
  </div>
</template>

<script>
import ScheduleItem from "@/components/schedule/ScheduleItem";

export default {
  name: "ScheduleMenu",
  components: {
    ScheduleItem,
  },
  data(){
    return {
      scheduleDialog: false,
      scheduleEditing: false,
    }
  },
  methods: {
    saveScheduleConf(){
      this.scheduleEditing = false;
    }
  },
  computed: {
    schedule(){
      return [
        {
          id: 1,
          gpsFence: {
            lat: "37.145678",
            lon: "15.324324",
            radius: '1000',
          },
          wifiFence: null,
          start: "08:00",
          end: "15:00",
          duration: "60",
        },
        {
          id: 2,
          gpsFence: {
            lat: "29.365214",
            lon: "18.698412",
            radius: '1000',
          },
          wifiFence: null,
          start: "20:00",
          end: "22:00",
          duration: "60",
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>