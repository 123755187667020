import { Model } from "@vuex-orm/core";
import Device from "@/store/models/device.model";
import Transcription from "@/store/models/transcription.model";
import File from "@/store/models/file.model";
import { apiUrl } from "@/common/endpoint.const";
import RelevanceEvent from "@/store/models/relevance-event.model";
import {
	ADDED_RELEVANCE,
	NOTIFY_OBSERVERS,
	ADD_OBSERVER,
	REMOVE_OBSERVER
} from "@/store/actions.type";
import Notification from "@/store/models/notification.model";
import Vue from "vue";
import Synthesis from "@/store/models/synthesis.model";

export default class Relevance extends Model {

	static entity = 'relevance';

	static fields() {
		return {
			id: this.number(null),
			created_at: this.attr(null),
			relevance_grade: this.attr(null).nullable(),
			type: this.number(null).nullable(),
			deviceId: this.attr(null),
			device: this.belongsTo(Device, 'deviceId'),
			transcriptionId: this.attr(null),
			transcription: this.belongsTo(Transcription, 'transcriptionId'),
			synthesisId: this.attr(null),
			synthesis: this.belongsTo(Synthesis, 'synthesisId'),
			fileId: this.attr(null),
			file: this.belongsTo(File, 'fileId'),
			events: this.hasMany(RelevanceEvent, 'relevanceId')
		}
	}

	static fromApiResponse(apiResponse) {
		const relevanceData = apiResponse;

		this.id = relevanceData.id;
		this.type = relevanceData.type;
		this.createdAt = relevanceData.createdAt;
		this.updatedAt = relevanceData.updatedAt;
		this.file = relevanceData.file;
		this.transcription = relevanceData.transcription;
		this.synthesis = relevanceData.synthesis;
		this.relevanceEvents = relevanceData.relevanceEvents;
		this.deviceId = relevanceData.deviceId;
		this.device = relevanceData.device;
	}

	async persist() {
		console.log(this.$toJson());
		const data = this.$toJson();
		delete data.device;

		if (isNaN(parseInt(data.transcription.id))) {
			delete data.transcriptionId;
			delete data.transcription.id
			delete data.transcription.relevance
		}
		else {
			delete data.transcription.created_at;
			data.transcription.last_modified = new Date().toISOString();
			delete data.transcription.relevance;
		}
		delete data.transcription.user;
		if (isNaN(parseInt(data.synthesis.id))) {
			delete data.synthesisId;
			delete data.synthesis.id
			delete data.synthesis.relevance
		}
		else {
			delete data.synthesis.created_at;
			data.synthesis.last_modified = new Date().toISOString();
			delete data.synthesis.relevance;
		}
		delete data.synthesis.user;

		if (isNaN(parseInt(data.file.id))) {
			delete data.file.id
			delete data.file.relevance
		}
		else
			delete data.file

		data.events = data.events.map(e => {
			console.log(e)
			console.log(isNaN(e.id))
			delete e.relevance
			if (isNaN(e.id)) {
				const { id, ...event } = e;
				console.log(event)
				return event
			}
			return e;
		})

		console.log("SEND DATA: ", data);

		return await Relevance.api().put(`${apiUrl}/relevance/${this.id}`, { relevance: data }, {
			persistBy: 'insertOrUpdate',
			persistOptions: {
				insertOrUpdate: ['synthesis', 'transcriptions', 'users', 'relevanceEvents']
			}
		})
	}
}
let getDataRelevance = undefined;
export const relevance = {
	actions: {
		[ADDED_RELEVANCE](context, params) {
			Relevance.insert({
				data: params.data,
				insertOrUpdate: ['files',]
			})
			Notification.insert({
				data: {
					icon: 'mdi-call',
					color: 'red darken-3',
					title: `Nuova rilevanza acquisita`,
					subtitle: `acquisita il ${new Date(params.data.created_at).toLocaleString()}`,
					actionLabel: 'Visualizza',
					actionIcon: 'mdi-check',
					actionLink: `/device/relevance/${params.data.id}`,
					deviceId: params.data.deviceId,
				}
			})

			context.dispatch(NOTIFY_OBSERVERS);
		},
		[NOTIFY_OBSERVERS]() {
			if (getDataRelevance) {
				getDataRelevance();
			}
		},
		[ADD_OBSERVER](context, params) {
			console.log("method: ", params);
			getDataRelevance = params
		},
	},
};