import { Model } from "@vuex-orm/core";
import Console from "@/store/models/console.model";

export default class ConsoleCommand extends Model {
  static entity = 'consoleCommands';

  static fields() {
    return {
      id: this.attr(null),
      uuid: this.attr(null),
      cmd: this.attr(null),
      params: this.attr(null),
      from: this.attr(null),
      to: this.attr(null),
      timestamp: this.attr(null),
      status: this.attr(null),
      consoleId: this.attr(null),
      console: this.belongsTo(Console, 'consoleId'),
    }
  }
}