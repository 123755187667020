import { io } from 'socket.io-client';
import { wsUrl } from "@/common/endpoint.const";

class SocketioService {
	socket;
	actualID = null;
	occupiedID = [];
	occupiedUsers = [];
	arrIdDelete = [];

	constructor() { }

	setupSocketConnection(port) {
		if (this.socket == undefined) {
			this.socket = io(wsUrl + `?access_token=${localStorage.getItem("token")}`);
			console.log('Socket connected: ', wsUrl);
		}
	}

	elaborateData(data) {
		let allData = JSON.parse(data);
		console.log("Dati ricevuti dal server: ", allData);
		if (allData != undefined && allData != null) {
			this.occupiedID = allData.arrID;
			this.occupiedUsers = allData.arrUser;
		}
	}

	disconnect() {
		if (this.socket) {
			let jsonSend = {
				ID: this.actualID,
				action: 'delete',
				user: JSON.parse(localStorage.getItem("user")).username
			}
			this.sendMessage(jsonSend);
			this.socket.disconnect();
		}
	}

	clearData() {
		console.log("Pulizia dati in corso...")
		this.socket.emit('clear-data', "clean");
	}

	checkDataTranscriptionAndSynthesis() {
		console.log("Inserimento dati sintesi e trascrizione...")
		this.socket.emit('get-data-synthesis-transcrption', "get-data-synthesis-transcrption");
	}

	checkDataRelevance() {
		console.log("Inserimento dati sintesi e trascrizione...")
		this.socket.emit('get-data-relevance', "get-data-relevance");
	}

	savedDataRelevance() {
		this.socket.emit('save-data-relevance', "saved-data-relevance");
	}

	async sendMessage(value) {
		console.log('Invio messaggio ', value);
		if (value.action == 'insert') {
			this.actualID = value.ID;
		}
		else {
			let indexOccupied = this.occupiedID.indexOf(this.actualID);
			this.occupiedUsers.splice(indexOccupied, 1);

			this.occupiedID.splice(indexOccupied, 1);
			this.actualID = null;
		}
		console.log("actualID: ", this.actualID);
		await this.socket.emit('id-value', JSON.stringify(value));
	}

	sendSynthesisOrTranscription(value, idRelevance, type) {
		let message = JSON.stringify({
			message: value,
			idRelevance: idRelevance,
			idUser: JSON.parse(localStorage.getItem("user")).username,
			type: type + " /savement/"
		});
		console.log("Invio del seguente messaggio: ", message);
		if (type === 'trascrizione') {
			this.socket.emit('transcription', message);
		}
		else if (type === 'sintesi') {
			this.socket.emit('synthesis', message);
		}
	}

	sendRelevanceNoteOrRelevanceGrade(value, idRelevance, type) {
		let message = JSON.stringify({
			message: value,
			idRelevance: idRelevance,
			idUser: JSON.parse(localStorage.getItem("user")).username,
			type: type
		});
		console.log("Invio del seguente messaggio: ", message);
		if (type === 'note') {
			this.socket.emit('set-note-relvance-grade', message);
		}
		else if (type === 'grade') {
			this.socket.emit('set-relevance-grade', message);
		}

	}
}

export default new SocketioService();