<script>
import axios from '../../axios';
import Device from "@/store/models/device.model";
import DeviceInfo from "@/components/device/DeviceInfo";
import Avatar from "vue-avatar";
import ChatContactItem from "@/components/chat/ChatContactItem";
import UnknownChat from "@/components/chat/UnknownChat";
import ChangeDirectionOfMessage from "@/components/chat/ChangeDirectionOfMessage";
import Multiselect from 'vue-multiselect'
import { apiUrl } from "@/common/endpoint.const";
import Swal from 'sweetalert2';
import SavedRelevance from "../../services/savedRelevance.service";
import ChatMessage from "@/store/models/chat-message.model";
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  ImageRun,
  AlignmentType,
  TableCell,
  TableRow,
  Table,
} from "docx";
import { LogEventTypes } from "../../utils/LogEventTypes";
import { LogStatus } from "../../utils/LogStatus";

export default {
	name: "DeviceChats",
	components: {
		DeviceInfo,
		Avatar,
		Multiselect,
		ChangeDirectionOfMessage,
		ChatContactItem,
		UnknownChat
	},
	watch: {
        pageContact: 'getChatContact',
		async filterContact(){
			this.selectedChat = [];
			this.isFirstChatGet = true;
			this.pageContact = 1;
			this.getChatContact();
		},
		async relevanceGrade(){
			this.selectedChat = [];
			this.isFirstChatGet = true;
			this.pageContact = 1;
			this.getChatContact();
		},
		async idMessageSearch(){
			this.selectedChat = [];
			this.isFirstChatGet = true;
			this.pageContact = 1;
			this.getChatContact();
		},
		async filterData(){
			this.selectedChat = [];
			this.isFirstChatGet = true;
			this.pageContact = 1;
			this.getChatContact();
		}
    },
	data() {
		return {
			movingChat: false,
			apiUrl,
			filterData: null,
			dialog: false,
			filterContact: "",
			relevanceGrade: { label: 'Tutte', value: null },
			relevanceGrades: [
				{
					label: 'Molto importante',
					value: 5
				},
				{
					label: 'Utile ai fini dell\'indagine',
					value: 4
				},
				{
					label: 'Da valutare',
					value: 3
				},
				{
					label: 'Non inerente',
					value: 2
				},
				{
					label: 'Non utilizzabile',
					value: 1
				},
				{
					label: 'Non impostata',
					value: 0
				},
				{
					label: 'Tutte',
					value: null
				},
			],
			actServiceChat: null,
			exportWordLoading: false,
			pageContact: 1,
			loaderMessages: false,
			limitContact: 10,
			token: localStorage.getItem('token'),
			deviceId: localStorage.getItem('deviceId'),
			deviceBrand: "",
			deviceModel: "",
			pageMessage: 1,
			limitMessage: 10,
			chatContactList: [],
			actualContact: "",
			actualSocial: "",
			selectedChat: [],
			transalteId:[],
			firstSelect: true,
			idMessageSearch: null,
			actualIdChat: null,
			testoDaTradurre: "",
			actualTranslation: '',
			translation: false,
			selectedImageUrl: "",
			imageActualName: "",
			actUser: "",
			typeMessageDialogue: "",
			actualFileID: null,
			isFirstChatGet: true,
			relevanceIsCreated: false,
			actualIdRelevance: null,
			actualIdMessage: null,
			maxItemContact: null,
			messageData: null
		}
	},
	methods: {
		async moveChat() {
			this.movingChat = true;
			var currentChatId = null;
			var newChat = null;
			var response = null;

			try{
				if(this.selectedChat != null && this.selectedChat.length > 0){
					currentChatId = this.selectedChat[0].chatId;
				} else {
					return;
				}

				var listChats = await axios.get(`${apiUrl}/device/chats`);
				if(listChats.data == null) return;

				const userResponse = await Swal.fire({
					title: 'Selezione chat',
					text: 'Seleziona la chat di destinazione',
					icon: 'question',
					input: 'select',
					inputOptions: listChats.data.reduce((acc, obj) => {
						if(obj.contact){
							acc[obj.id] = `chat-${obj.id} - ${obj.contact.name} (${obj.contact.phone}) ctn-${obj.contact.id}`;
						} else {
							acc[obj.id] = `chat-${obj.id}`;
						}
						return acc;
					}, {}),
					showCancelButton: true,
					confirmButtonText: 'Conferma',
					cancelButtonText: 'Annulla',
				});

				if(userResponse.isConfirmed) {
					var confirm = await Swal.fire({
						title: 'Attenzione',
						text: "L'operazione è irreversibile e unirà eventuali chat. Si vuole davvero procedere?",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: 'Conferma',
						cancelButtonText: 'Annulla',
					})
					if(!confirm.isConfirmed) {
						return;
					}
					newChat = userResponse.value;
				} else {
					return;
				}

				if(currentChatId == newChat) {
					Swal.fire({
						icon: 'error',
						title: 'Errore',
						text: 'Le chat devono essere diverse',
						confirmButtonText: 'Ok',
					})
					return;
				}

				response = await axios.post(`${apiUrl}/device/move-chat`, {
					chatId: currentChatId,
					newChatId: newChat
				});
			} catch (e) {
				console.log("Errore imprevisto nello spostamento chat: ", e);
				response = {
					status: false,
					events: e,
				};
			} finally {
				this.movingChat = false;

				var status = response.data.status;
				var error = response.data.events;

				if (status && this.selectedChat != null && newChat != null) {
					await Swal.fire({
						icon: 'success',
						title: 'Successo',
						text: 'Chat spostata correttamente',
						confirmButtonText: 'Ok',
					});

					this.getChatContact();
				} else {
					await Swal.fire({
						icon: 'error',
						title: 'Errore',
						text: 'Si è verificato un errore durante la modifica della chat: ' + error,
						confirmButtonText: 'Ok',
					});
				}
			}
		},
		sendLog({event, message, details, status}) {
			axios.post(`${apiUrl}/device/log-register/new?token=${localStorage.getItem('token')}`, {
				event: event,
				module: "CHAT",
				message: message,
				details: details,
				status: status,
				session: localStorage.getItem('token'),
				user: localStorage.getItem('user'),
			});
		},
		setContact(chat){
			this.actualContact = chat?.contact;
			this.actualSocial = chat?.source;
			this.selectedChat = [];
			this.isFirstChatGet = true;
		},
		async getChatContact() {
			try {
                const response = await axios.post(`${apiUrl}/device/chat-list`,
                    {
                        page: this.pageContact,
                        limit: this.limitContact,
                        filter:
                        {
                            filterData: this.filterData,
                            filterContact: this.filterContact,
                            relevanceGrade: this.relevanceGrade.value,
							idMessageSearch: this.idMessageSearch
						}
					}
				);
				console.log("response: ", response)
				if (response.status === 200) {
					console.log("response.data: ", response.data);
					this.chatContactList = response.data.response;
					this.maxItemContact = response.data.count;
					console.log("chatContactList: ", this.chatContactList)
				}
			}
			catch (e) {
				console.log(e)
			}
		},
		viewRelevance(){
			this.$router.push(`/device/relevance/${this.actualIdRelevance}`).catch(err => {
				if (err.name !== 'NavigationDuplicated') {
				throw err;
				};
			});
		},
		async spliceElement(id){
			for(let i = 0; i < this.selectedChat.length; i++){
				if(this.selectedChat[i].id == id){
					this.selectedChat.splice(i,1);
					break;
				}
			}
		},
		close() {
			this.dialog = false;
		},
		createRelevanceFromFile(){
			SavedRelevance.createRelevanceFromFile(this.actualFileID, this.messageData);
			this.relevanceIsCreated = false;
		},
		async openDialogue(imageUrl,actualImage,user,typeMessage,actualIdMessage,fileId, messageDate) {
			this.actualIdMessage = actualIdMessage;
			this.selectedImageUrl = imageUrl;
			this.imageActualName = actualImage;
			this.dialog = true;
			this.actUser = user;
			this.typeMessageDialogue = typeMessage;
			let dataRelevance = await SavedRelevance.getDataRelevance(fileId);
			console.log("dataRelevance: ",dataRelevance);
			this.actualFileID = fileId;
			this.relevanceIsCreated = typeof dataRelevance === "object" ? false : true;
			this.messageData = messageDate;
			if(!this.relevanceIsCreated){
				this.actualIdRelevance = dataRelevance.id;
			}
		},
		OpenTranslation() {
			this.translation = true;
		},
		async closeTranslation() {
			this.translation = false;
		},
		async saveTranslation() {
			console.log("Save Translation");
			await axios.post(`${apiUrl}/device/insert-translation`, {
                id: this.actualIdMessage,
				traduzione: this.actualTranslation
			}).then((data) => {
				console.log("data: ", data);
				if (data?.data) {
					Swal.fire({
						title: "Successo!",
						text: "Traduzione salvata correttamente.",
						icon: "success",
						confirmButtonText: "Ok"
					});
					this.translation = false;
					if(!this.transalteId.includes(this.actualIdMessage)){
						this.transalteId.push(this.actualIdMessage);
					}
				}
				else {
					Swal.fire({
						title: "Errore!",
						text: "Si è verificato un errore durante il salvataggio della traduzione!",
						icon: "error",
						confirmButtonText: "Ok"
					});
				}
			});
		},
		async getTranslation(idMsg) {
			console.log("getTranslation")
			await axios.get(`${apiUrl}/device/${idMsg}/translation`).then((data) => {
				console.log("data-gettrans: ", data);
				if (data?.data?.length > 0) {
					this.actualTranslation = data?.data;
				}
			});
		},
		async openDialogueTranslation(testo, idMsg) {
			console.log("testo: ", testo);
			console.log("idMsg: ", idMsg);
			this.testoDaTradurre = testo;
			this.actualTranslation = "";
			this.actualIdMessage = idMsg;
			this.translation = true;
			await this.getTranslation(idMsg);
		},
		escapeRegExp(string) {
			return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
		},
		getSocial(source) {
			console.log("source: ", source);
			switch (source) {
				case 0:
					return 'sms';
				case 1:
					return 'whatsapp';
				case 2:
					return 'telegram';
				case 3:
					return 'facebook-messenger';
				case 4:
					return 'instagram';
				case 5:
					return 'wickr-me';
				case 6:
					return 'whatsapp-business';
				default:
					return 'unknown';
			}
		},
		async exportChatWord() {
			try {
				let messages;
				await axios.post(`${apiUrl}/device/chat-messages`,
				{
                    limit: 0,
					filter:{
						ChatId: this.actualIdChat,
						actServiceChat : this.actServiceChat,
						filterData: this.filterData,
						filterContact: this.filterContact,
						relevanceGrade: this.relevanceGrade.value,
                        idMessageSearch: this.idMessageSearch,
						noPagination: "true"
					}
				}
				).then((data) => {
					console.log("data: ", data.data.response);
					messages = data.data.response;
				});
				console.log("messages: ", messages);
				if (this.selectedChat[0] != undefined && this.selectedChat[0] != null) {
					const fileId = this.selectedChat[0].fileId;
					var relevanceId = null;
					if (fileId != null) {
						const response = await axios.post(`${apiUrl}/device/relevance/getIdByFile`, { fileId })
							.catch((error) => {
								console.log(error);
							});
						relevanceId = response?.data ? response.data : null;
					}

					this.exportWordLoading = true;
					const interlocutor = this.actualContact.name;
					const deviceBrand = this.device.manufacturer;
					const deviceModel = this.device.model;
					const social = this.getSocial(this.selectedChat[0].source);
					let messageTable = [];
					console.log("device: ", messages);
					if (messages != undefined) {
						messages.map(message => {
							if (message.direction == 1) {
								messageTable.push(
									new TableRow({
										children: [
											new TableCell({
												children: [
													new Paragraph({
														children: [
															new TextRun({
																text: "",
																bold: true,
																size: 20,
																color: '000000',
															}),
														],
													}),
												],
											}),
											new TableCell({
												children: [
													new Paragraph({
														children: [
															new TextRun({
																text: new Date(message.created_at).toLocaleString(),
																bold: true,
																size: 20,
																color: '000000',
															}),
														],
													}),
													new Paragraph({
														children: [
															new TextRun({
																text: "ID Messaggio: msg-" + message.id,
																bold: true,
																size: 20,
																color: '000000',
															}),
														],
													}),
													message.traduzione ? new Paragraph({
														children: [
															new TextRun({
																text: "Traduzione: " + message.traduzione,
																bold: true,
																size: 20,
																color: '000000',
															}),
														],
													}) : {},
													relevanceId ? new Paragraph({
														children: [
															new TextRun({
																text: 'ID Acquisizione: ' + relevanceId,
																bold: true,
																size: 20,
																color: '000000',
															}),
														],
													}) : {},
													new Paragraph({
														children: [
															new TextRun({
																text: message.content.includes("[IMG") ? "Immagine WhatsApp" : message.content.includes("[PTT") ? "Audio WhatsApp" : message.content,
																bold: true,
																size: 20,
																color: '000000',
															}),
														],
													}),
												],
											}),
										]
									})
								);
							}
							else {
								messageTable.push(
									new TableRow({
										children: [
											new TableCell({
												children: [
													new Paragraph({
														children: [
															new TextRun({
																text: new Date(message.created_at).toLocaleString(),
																bold: true,
																size: 20,
																color: '000000',
															}),
														],
													}),
													new Paragraph({
														children: [
															new TextRun({
																text: "ID Messaggio: msg-" + message.id,
																bold: true,
																size: 20,
																color: '000000',
															}),
														],
													}),
                                                    message.traduzione ? new Paragraph({
														children: [
															new TextRun({
																text: "Traduzione: " + message.traduzione,
																bold: true,
																size: 20,
																color: '000000',
															}),
														],
                                                    }) : {},
													new Paragraph({
														children: [
															new TextRun({
																text: message.content.includes("[IMG") ? "Immagine WhatsApp" : message.content.includes("[PTT") ? "Audio WhatsApp" : message.content,
																bold: true,
																size: 20,
																color: '000000',
															}),
														],
													}),
												],
											}),
											new TableCell({
												children: [
													new Paragraph({
														children: [
															new TextRun({
																text: "",
																bold: true,
																size: 20,
																color: '000000',
															}),
														],
													}),
												],
											}),
										]
									}
									));
							}
						});
					}

					console.log("messages: ", messages);

					let today = new Date();
					let dd = today.getDate();
					let mm = today.getMonth() + 1;
					let yyyy = today.getFullYear();
					let hh = today.getHours();
					let min = today.getMinutes() + "";
					console.log(dd + "/" + mm + "/" + yyyy + " " + hh + ":" + min);
					let oggetto = window.config.documentExport.oggetto
						.replace(new RegExp(this.escapeRegExp('{{progressivo}}'), 'g'), "ID Chat: chat-" + this.selectedChat[0].chatId);
					let idContatto = this.actualContact.id;
					console.log("actualContact: ", this.actualContact);
					console.log("idContatto: ", idContatto);
					let info_modulo = window.config.documentExport.info_modulo
						.replace(new RegExp(this.escapeRegExp('{{anno}}'), 'g'), yyyy)
						.replace(new RegExp(this.escapeRegExp('{{mese}}'), 'g'), mm)
						.replace(new RegExp(this.escapeRegExp('{{giorno}}'), 'g'), dd)
						.replace(new RegExp(this.escapeRegExp('{{orario}}'), 'g'), hh + ":" + min)
						.replace(new RegExp(this.escapeRegExp('{{firmatario}}'), 'g'), window.config.documentExport.firmatario)
						.replace(new RegExp(this.escapeRegExp('{{RIT}}'), 'g'), window.config.documentExport.rit_acquisizioni);
					// Creazione del paragrafo per il testo "Ufficiale di P.G."
					const firmaText = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: window.config.documentExport.titolo_firma,
								size: 25,
								color: '000000',
							}),
						],
					});

					// Creazione del paragrafo per la linea
					const linea = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: '____________________',
								bold: true,
								size: 20,
								color: '000000',
							}),
						],
					});

					// Creazione del paragrafo per il firmatario
					const firmatario = new Paragraph({
						alignment: AlignmentType.CENTER,
						children: [
							new TextRun({
								text: window.config.documentExport.firmatario,
								size: 18,
								color: '000000',
							}),
						],
					});

					const doc = new Document({
						sections: [{
							properties: {},
							children: [
								// logo iniziale centrale
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new ImageRun({
											data: window.config.reportImageLogo,
											transformation: {
												width: 100,
												height: 100,
											},
											alignment: AlignmentType.CENTER,
										}),
									],
								}),
								// GUARDIA DI FINANZA
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: window.config.documentExport.titolo,
											bold: true,
											color: '000000',
											size: 36,
										}),
									]
								}),
								// titolo Gruppo
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: window.config.documentExport.gruppo,
											bold: true,
											color: '000000',
											size: 24,
										}),
									]
								}),
								// Nucleo Operativo
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: window.config.documentExport.tipo_nucleo,
											color: '000000',
											size: 19,
										}),
									]
								}),
								// informazioni varie
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: window.config.documentExport.informazioni_varie,
											color: '000000',
											size: 20,
										}),
									]
								}),
								// Tabella Oggetto
								new Table({
									columnWidths: [
										1700,
										6700,
									],
									rows: [
										new TableRow({
											children: [
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: "Oggetto",
																	bold: true,
																	color: '000000',
																	size: 20,
																}),
															]
														}),
													]
												}),
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: oggetto,
																	color: '000000',
																	size: 20,
																}),
															]
														}),
													]
												}),
											]
										})
									]
								}),
								// balnk space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 20,
										}),
									]
								}),
								// Testo contenente informazioni inerenti al caso
								new Paragraph({
									alignment: AlignmentType.JUSTIFIED,
									children: [
										new TextRun({
											text: info_modulo,
											color: '000000',
											size: 20,
										}),
									]
								}),
								// Immagine di divisione
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new ImageRun({
											data: window.config.reportImageDivider,
											transformation: {
												width: 100,
												height: 50,
											},
											alignment: AlignmentType.CENTER,
										}),
									],
								}),
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 20,
										}),
									]
								}),
								// Titolo SPORTAZIONE MESSAGGI SOCIAL
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "ESPORTAZIONE MESSAGGI SOCIAL",
											bold: true,
											color: '000000',
											size: 24,
										}),
									]
								}),
								// Blank space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 20,
										}),
									]
								}),
								// Prima tabella informazioni
								new Table({
									alignment: AlignmentType.CENTER,
									columnWidths: [1600, 1600, 1600, 1600, 1600],
									rows: [
										new TableRow({
											children: [
												new TableCell({
													shading: {
														fill: "D9D9D9",
													},
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: "Social",
																	bold: true,
																	color: '000000',
																	size: 20,
																}),
															],
														}),
													],
												}),
												new TableCell({
													shading: {
														fill: "D9D9D9",
													},
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: "Nome persona contattata",
																	bold: true,
																	color: '000000',
																	size: 20,
																}),
															],
														}),
													],
												}),
												new TableCell({
													shading: {
														fill: "D9D9D9",
													},
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: "Data e ora",
																	bold: true,
																	color: '000000',
																	size: 20,
																}),
															],
														}),
													],
												}),
												new TableCell({
													shading: {
														fill: "D9D9D9",
													},
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: "Bersaglio",
																	bold: true,
																	color: '000000',
																	size: 20,
																}),
															],
														}),
													],
												}),
												new TableCell({
													shading: {
														fill: "D9D9D9",
													},
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: "P.P - RIT",
																	bold: true,
																	color: '000000',
																	size: 20,
																}),
															],
														}),
													],
												}),
												new TableCell({
													shading: {
														fill: "D9D9D9",
													},
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: "ID Contatto",
																	bold: true,
																	color: '000000',
																	size: 20,
																}),
															],
														}),
													],
												}),
											],
										}),
										new TableRow({
											children: [
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: social,
																	color: '000000',
																	size: 20,
																}),
															],
														}),
													],
												}),
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: interlocutor,
																	color: '000000',
																	size: 20,
																}),
															],
														}),
													],
												}),
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: dd + "/" + mm + "/" + yyyy + " " + hh + ":" + min,
																	color: '000000',
																	size: 20,
																}),
															],
														}),
													],
												}),
												 new TableCell({
												 	children: [
												 		new Paragraph({
												 			children: [
												 				new TextRun({
												 					text: deviceBrand + " " + deviceModel,
												 					color: '000000',
												 					size: 20,
												 				}),
												 			],
												 		}),
												 	],
												 }),
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: window.config.documentExport.rit_acquisizioni,
																	color: '000000',
																	size: 20,
																}),
															],
														}),
													],
												}),
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: "ctn-" + idContatto.toString(),
																	color: '000000',
																	size: 20,
																}),
															],
														}),
													],
												}),
											]
										})
									],
								}),
								// Blank space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 20,
										}),
									]
								}),
								// // Seconda tabella informazioni
								// new Table({
								//   alignment: AlignmentType.CENTER,
								//   columnWidths: [3200,3200],
								//   rows:[
								//     new TableRow({
								//       children:[
								//         new TableCell({
								//           shading:{
								//             fill: "D9D9D9",
								//           },
								//           children:[
								//             new Paragraph({
								//               children: [
								//                 new TextRun({
								//                   text: "Esportato il: " + moment(Date.now()).format('DD/MM/YYYY HH:mm').toString(),
								//                   bold: true,
								//                   color: '000000',
								//                   size: 20,
								//                 })
								//               ],
								//             }),
								//           ]
								//         }),
								//         new TableCell({
								//           children:[
								//             new Paragraph({
								//               children: [
								//                 new TextRun({
								//                   text: "Operatore di PG: " + JSON.parse(localStorage.getItem('user')).username.toString(),
								//                   color: '000000',
								//                   size: 20,
								//                 })
								//               ],
								//             }),
								//           ]
								//         })
								//       ]
								//     }),
								//   ]
								// }),
								// Blank space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 20,
										}),
									]
								}),
								// Titolo tabella messaggi
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "Messaggi rilevanti esportati:",
											color: '000000',
											bold: false,
											size: 30,
										}),
									]
								}),
								// Blank space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 20,
										}),
									]
								}),
								// Blank space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 20,
										}),
									]
								}),
								// Blank space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 20,
										}),
									]
								}),
								// Tabelle messaggi
								new Table({
									columnWidths: [4200, 4200],
									alignment: AlignmentType.CENTER,
									rows: [
										new TableRow({
											children: [
												new TableCell({
													shading: {
														fill: "D9D9D9",
													},
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: interlocutor,
																	bold: true,
																	color: '000000',
																	size: 20,
																})
															],
														}),
													]
												}),
												new TableCell({
													shading: {
														fill: "D9D9D9",
													},
													children: [
														new Paragraph({
															children: [
																new TextRun({
                                                                    text: deviceBrand + " " + deviceModel,
																	bold: true,
																	color: '000000',
																	size: 20,
																})
															],
														}),
													]
												})
											]
										}),
										...messageTable
									]
								}),
								// balnk space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 50,
										}),
									]
								}),
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 50,
										}),
									]
								}),
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 50,
										}),
									]
								}),
								// Firma
								firmaText,
								// balnk space
								new Paragraph({
									alignment: AlignmentType.CENTER,
									children: [
										new TextRun({
											text: "",
											color: '000000',
											size: 20,
										}),
									]
								}),
								linea,
								firmatario
							]
						}]
					});

					Packer.toBlob(doc).then(blob => {
						saveAs(blob, social + "-" + interlocutor + ".docx");
						this.exportWordLoad = false;
					});
				}
				else {
					Swal.fire({
						title: "Errore!",
						text: "Selezionare una chat prima di procedere con l'esportazione dei dati!",
						icon: "error",
						confirmButtonText: "Ok"
					});
					return;
				}
			}
			catch (e) {
				console.log(e);
				Swal.fire({
					title: "Errore!",
					text: "Si è verificato un errore durante l'esportazione dei dati!",
					icon: "error",
					confirmButtonText: "Ok"
				});
			}
			finally {
				this.exportWordLoading = false;
			}
		},
		handleScroll(event) {
			const container = event.target;
			if (container.scrollTop === 0 && this.selectedChat.length > 0) {
				this.loadOldMessages();
			}
		},
		markAsUnread(message) {
			message.read = false;
			this.updateStatusMessages([message.id], 0)
		},
		markAsRead(message){
			message.read = true;
			this.updateStatusMessages([message.id], 1);
		},
		markAllBeforeAsRead(index){
			const toUpdate = [];
			for(let i = index; i >= 0; i--) {
				if (this.selectedChat[i].read === true) continue;
				this.selectedChat[i].read = true;
				toUpdate.push(this.selectedChat[i].id);
			}
			this.updateStatusMessages(toUpdate, 1)
		},
		updateStatusMessages(messagesId, value = null){
			if(!messagesId || messagesId.length === 0) return;
			console.log(messagesId, value);
			const chatId = this.selectedChat[0].chatId;
			ChatMessage.api().put(`${apiUrl}/device/chat/messages/read`, {
				value: value != null && value != undefined ? value : false,
				chatId,
				messagesId
			},
				{ persistBy: 'update' }
			)
		},
		setRelevance(message, relevance){
			message.relevance_grade = relevance;
			var messagesId = [message.id];

			if (!messagesId || messagesId.length === 0)
				return;

			const chatId = this.selectedChat[0].chatId;
			ChatMessage.api().put(`${apiUrl}/device/chat/messages/relevance_grade`, {
				value: relevance != null && relevance != undefined ? relevance : false,
				chatId,
				messagesId
			},
				{ persistBy: 'update' }
			)
		},
        scrollToBottom() {
            this.$nextTick(() => {
                const container = this.$refs.scrollContainer;
                if (container) {
                    console.log("container: ", container.scrollHeight);
                    container.scrollTop = container.scrollHeight;

                }
            });
        },
        loadOldMessages() {
            console.log("Caricamento vecchi messaggi...");
            let limitPage = Math.floor(this.actualMaxNumber / this.limitMessage);
            console.log("limitPage: ", limitPage);
            console.log("pageMessage: ", this.pageMessage);
            if (this.pageMessage > 0) {
                this.loaderMessages = true
                this.pageMessage--;
                this.selectChat(this.actualIdChat, true);
            }
        },
		async selectChat(chatId, loadNewMessage = false, serviceChat = null) {
			if(serviceChat != null){
				console.log("serviceChat: ",serviceChat);
				this.actServiceChat = serviceChat;
			}
			this.firstSelect = false;
			this.actualIdChat = chatId;
			let getMaxPage;

			if(this.isFirstChatGet){
				getMaxPage = await axios.post(`${apiUrl}/device/max-numberof-selected-chat`,
					{
						limitMessage: this.limitMessage,
						filter: {
							chatId,
							actServiceChat: this.actServiceChat,
							filterData: this.filterData,
							filterContact: this.filterContact,
							idMessageSearch: this.idMessageSearch,
							relevanceGrade: this.relevanceGrade.value
						}
					}
				);
				this.pageMessage = getMaxPage.data.page;
				this.isFirstChatGet = false;
			}
			if(this.pageMessage > 0){
				const result = await axios.post(`${apiUrl}/device/chat-messages`,
					{
						limit: this.limitMessage,
						page: this.pageMessage,
						filter: {
							chatId,
							actServiceChat: this.actServiceChat,
							filterData: this.filterData,
							filterContact: this.filterContact,
							idMessageSearch: this.idMessageSearch,
							relevanceGrade: this.relevanceGrade.value,
							noPagination: "false"							
						}
					}
				);

				console.log("result: ", result)
				if (result.status === 200) {
					if (loadNewMessage) {
                        const container = this.$refs.scrollContainer;
						const oldScrollHeight = container.scrollHeight;

						this.selectedChat = [...result.data.response, ...this.selectedChat];

						this.$nextTick(() => {
							const newScrollHeight = container.scrollHeight;
							container.scrollTop = newScrollHeight - oldScrollHeight;
							}
						);
					}
					else {
						this.selectedChat = result.data.response;
						this.actualMaxNumber = result.data.count;
						if(this.selectedChat.length < 10 && this.pageMessage > 1){
							this.pageMessage--;
							this.selectChat(this.actualIdChat, true);
							return;
						}
                        this.scrollToBottom();
					}
					console.log("selectedChat: ", this.selectedChat);
				}
			}
			this.loaderMessages = false;
		}
	},
	computed: {
        device() {
            return Device.getters('curDevice') ?? null;
        }
	},
	async created() {
		try{
			await axios.post(`${apiUrl}/device/noty/clean/chat`);
		} catch (e) {
			console.log(e);
		}
		let idMsg = window.location.href.split("?idMsg=")[1];
		if(idMsg != undefined && idMsg != null && idMsg != "") {
			if(idMsg.includes("#")){
				idMsg = idMsg.split("#")[0];
			}
			console.log("idMsg: ",idMsg)
			this.idMessageSearch = idMsg;
		}
		await this.getChatContact();
        const container = this.$refs.scrollContainer;
		container.addEventListener('scroll', this.handleScroll);
	}
}
</script>

<template>
	<div>
		<v-dialog v-model="dialog" max-width="500px">
			<v-card>
				<v-card-title class="headline" style="display: flex;justify-content: space-between;">
					{{ imageActualName }}
					<v-btn v-if="relevanceIsCreated" @click="createRelevanceFromFile()">
						<v-icon>mdi-plus</v-icon>
						Crea rilevanza
					</v-btn>
					<v-btn v-else @click="viewRelevance()">
						<v-icon>mdi-eye</v-icon>
						Visualizza rilevanza
					</v-btn>
				</v-card-title>
				<v-card-text>
					<div class="modal-container-image">
						<audio v-if="typeMessageDialogue == 'audio'" controls class="my-audio-player" controlsList="nodownload">
							<source :src="selectedImageUrl" />
							Your browser does not support the audio element.
						</audio>
						<v-img v-if="typeMessageDialogue == 'image'" :src="selectedImageUrl" :style="{ maxWidth: '80%', maxHeight: '80%' }"></v-img>
						<video v-if="typeMessageDialogue == 'video'" controls autoplay :style="{ maxWidth: '80%', maxHeight: '80%' }" controlsList="nodownload" disablePictureInPicture>
							<source :src="selectedImageUrl">
						</video>
					</div>
					<v-expansion-panels>
						<v-expansion-panel>
							<v-expansion-panel-header>
								<h4>Cambia Chat</h4>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<span v-if="actUser != null && actUser?.length > 0">Ultimo utente che ha cambiato l'immagine: {{actUser}}</span>
								<change-direction-of-message :apiUrl="apiUrl"
																						 :token="token"
																						 :spliceElement="() => {
									isFirstChatGet = true;
									this.selectChat(this.actualIdChat, true);
								}"
																						 :imageName="imageActualName"
																						 :actualContact="actualContact"
																						 :actualIdMessage="actualIdMessage" />
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close">Chiudi</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="translation" max-width="500px">
			<v-card>
				<v-card-title class="headline" style="display: flex;justify-content: space-between;">
					Traduzione
				</v-card-title>
				<v-card-text>
					<p>
						Testo da tradurre: {{ testoDaTradurre }}
					</p>
					<!-- create text area -->
					<v-textarea v-model="actualTranslation" solo outlined rows="7"></v-textarea>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeTranslation">Chiudi</v-btn>
					<v-btn color="blue darken-1" text @click="saveTranslation">Traduci</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row class="pa-4">
			<v-col cols="4">
				<v-card>
					<v-subheader>Elenco chat</v-subheader>
					<v-card-title>
						<v-row dense>
							<v-col cols="6">
								<v-text-field v-model="filterContact" label="Inserisci il contatto da filtrare">
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field v-model="idMessageSearch" label="Inserisci l'id del messaggio">
								</v-text-field>
							</v-col>
							<v-col :cols="filterData?5:6">
								<v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
												offset-y min-width="290px">
									<template v-slot:activator="{ on }">
										<v-text-field v-model="filterData" label="Seleziona Date"
																	prepend-icon="mdi-calendar" readonly v-on="on">
										</v-text-field>
									</template>
									<v-date-picker v-model="filterData" @input="menu = false"></v-date-picker>
								</v-menu>
							</v-col>
							<v-col v-if="filterData" cols="1">
								<v-icon style="margin-top: 20px;" @click="filterData = null">mdi-delete</v-icon>
							</v-col>
							<v-col cols="6">
								<v-select v-model="relevanceGrade" :items="relevanceGrades" label="Filtra per rilevanza"
													return-object item-text="label" item-value="value"></v-select>
							</v-col>
						</v-row>
					</v-card-title>
					<v-list two-line style="height: 600px; overflow-y: auto;" v-if="chatContactList?.length > 0">

						<template v-for="chat in chatContactList">
							<v-divider :key="'d' + chat.id" />
							<div @click="setContact(chat)">
								<chat-contact-item :chat="chat"
																	 @switchChat="(serviceChat) => {
										isFirstChatGet = true;
										selectChat(chat.id, false,serviceChat);
									}"
																	 :key="'c' + chat.id" />
							</div>
						</template>
						<v-pagination v-model="pageContact"
													:length="Math.ceil(maxItemContact / limitContact)"
													@input="(page) => pageContact = page"
													:total-visible="5">
						</v-pagination>
					</v-list>
				</v-card>
			</v-col>

			<v-col id="chat-timeline" ref="scrollContainer" style="height: 600px; overflow-y: auto;" cols="5" v-if="selectedChat" align-self="start">
				<v-card style="height:auto;">
					<v-subheader>
						Messaggi
						<v-spacer></v-spacer>
						<v-btn v-if="!firstSelect && selectedChat.length > 0" color="orange" @click="moveChat()" class="v-btn--text" :loading="movingChat" style="
							min-width: none;
							margin-top: 10px;
							margin-bottom: 10px;
						">
							<v-icon>mdi-export</v-icon>Sposta chat
						</v-btn>
					</v-subheader>
					<v-divider></v-divider>
					<v-card-text v-if="firstSelect">
						Seleziona una chat per visualizzarne i messaggi
					</v-card-text>
					<v-timeline align-top v-if="!firstSelect">
						<!-- insert loader -->
						<v-progress-circular indeterminate v-if="loaderMessages"></v-progress-circular>
						<v-timeline-item v-for="(message, i) in selectedChat" :key="i" :right="message.direction === 1" :left="message.direction === 0" color="white">
							<template v-slot:opposite>
								<div style="display:flex">
									{{ new Date(message.completeData).toLocaleString() }}
									<v-menu>
										<template v-slot:activator="{ on, attrs }">
											<v-btn v-bind="attrs" v-on="on" icon small
														 :color="message.relevance_grade | relevanceGradeColor">
												<v-icon>mdi-flag</v-icon>
											</v-btn>
										</template>
										<v-list>
											<v-list-item @click="setRelevance(message, 5)">
												<v-icon style="color: #E53935">mdi-flag</v-icon>
												Molto importante
											</v-list-item>
											<v-list-item @click="setRelevance(message, 4)">
												<v-icon style="color: #e65100 !important">mdi-flag</v-icon>
												Utile ai fini dell'indagine
											</v-list-item>
											<v-list-item @click="setRelevance(message, 3)">
												<v-icon style="color: #fbc02d !important">mdi-flag</v-icon>
												Da valutare
											</v-list-item>
											<v-list-item @click="setRelevance(message, 2)">
												<v-icon style="color: #01579b !important">mdi-flag</v-icon>
												Non inerente
											</v-list-item>
											<v-list-item @click="setRelevance(message, 1)">
												<v-icon style="color: #4a148c !important">mdi-flag</v-icon>
												Non utilizzabile
											</v-list-item>
											<v-list-item @click="setRelevance(message, 0)">
												<v-icon style="color: #bdbdbd !important">mdi-flag</v-icon>
												Non impostata
											</v-list-item>
										</v-list>
									</v-menu>
									<div class="tooltip" v-if="message.fileType == 'vocal'">
										<v-btn color="green" block @click="invertDirection(message.id, i)"
													 style="height: 30px;padding: 0 5px;">
											<v-icon>mdi-arrow-left-right</v-icon>
										</v-btn>
										<span class="tooltiptext">Inverti direzione</span>
									</div>
								</div>
								<p style="float:left">id Messaggio: msg-{{ message.id }}</p>
								<div class="tooltip-container">
									<v-icon v-if="message.relevance">
										mdi-monitor-arrow-down-variant
									</v-icon>
									<div class="tooltip btn-cont">
										<span class="tooltip-text">Rilevanza già creata</span>
									</div>
								</div>
							</template>

							<template v-slot:icon>
								<v-menu>
									<template v-slot:activator="{ on, attrs }">
										<v-btn color="primary" v-bind="attrs" v-on="on" icon small
													 v-if="message.read === false">
											<v-icon>mdi-email</v-icon>
										</v-btn>
										<v-btn color="primary" v-bind="attrs" v-on="on" icon small v-else>
											<v-icon>mdi-email-mark-as-unread</v-icon>
										</v-btn>
									</template>
									<v-list>

										<v-list-item @click="markAsUnread(message)">
											<v-icon color="primary">mdi-email</v-icon>
											Segna come non letto
										</v-list-item>
										<v-list-item @click="markAsRead(message)">
											<v-icon color="primary">mdi-email-mark-as-unread</v-icon>
											Segna come letto
										</v-list-item>
										<v-list-item @click="markAllBeforeAsRead(i)">
											<v-icon color="primary">mdi-email-mark-as-unread</v-icon>
											Segna come letto insieme ai precedenti
										</v-list-item>
									</v-list>
								</v-menu>
							</template>
							<v-alert dense
									 :color="message.id == idMessageSearch ? 'red lighten-1' : message.direction === 1 ? 'green lighten-1' : 'grey darken-3'">

								<div class="tooltip-container" style="width:100%;">
									<div v-if="message.fileType == 'text'" :id="message.id"
										 @click="openDialogueTranslation(message.content, message.id)">
										<p style="display: flex;justify-content: space-between;">
											{{ message.content }}
											<span>
												<v-icon color="white" v-if="message.traduzione || transalteId.includes(message.id)">mdi-ab-testing</v-icon>
											</span>
										</p>

									</div>
									<div class="tooltip btn-cont"
										 @click="openDialogueTranslation(message.content, message.id)">
										<span class="tooltip-text">Clicca per inserire una traduzione</span>
									</div>
								</div>
								<div v-if="message.fileType == 'vocal'"
									 v-for="(messagElem, index) in message.content.replace('[', '').replace(']', '').split(',')"
									 :key="index"
									 @click="openDialogue(
										apiUrl + '/downloadfile/' + messagElem + '?access_token=' + token + '&deviceid=' + deviceId,
										messagElem,
										message.last_user_update,
										'audio',
										message.id,
										message.fileId.replace('[', '').replace(']', ''),
										message.completeData,
									)">
									<audio controls
										   class="my-audio-player"
										   controlsList="nodownload"
										   @click="openDialogue(
											apiUrl + '/downloadfile/' + messagElem + '?access_token=' + token + '&deviceid=' + deviceId,
											messagElem,
											message.last_user_update,
											'audio',
											message.id,
											message.fileId.replace('[', '').replace(']', ''),
											message.completeData,
										)">
										<source :src="apiUrl + '/downloadfile/' + messagElem + '?access_token=' + token + '&deviceid=' + deviceId" />
										Your browser does not support the audio element.
									</audio>
								</div>
								<div v-if="message.fileType == 'image'">
									<!-- convert message.content in array and iterate it-->
									<div class="column-container">
										<template v-if="message?.content?.replace('[', '').replace(']', '').split(',')?.length > 0">
											<div v-for="(messagElem, index) in message.content.replace('[', '').replace(']', '').split(',')"
												 :key="index" class="column-item">

												<img :src="apiUrl + '/downloadfile/' + messagElem + '?access_token=' + token + '&deviceid=' + deviceId" 
													:style="{ maxWidth: '100%', maxHeight: '100%' }" @click="openDialogue(
														apiUrl + '/downloadfile/' + messagElem + '?access_token=' + token + '&deviceid=' + deviceId,
														messagElem,
														message.last_user_update,
														'image',
														message.id,
														message.fileId.replace('[', '').replace(']', ''),
														message.completeData,
													)" />
											</div>
										</template>
									</div>
								</div>
								<div v-if="message.fileType == 'video'">
									<div class="column-container">
										<template v-if="message?.content?.replace('[', '').replace(']', '').split(',')?.length > 0">
											<div v-for="(messagElem, index) in message.content.replace('[', '').replace(']', '').split(',')"
												 :key="index" class="column-item">

												<div class="video-wrapper">
													<video :src="apiUrl + '/downloadfile/' + messagElem + '?access_token=' + token + '&deviceid=' + deviceId"
														controlsList="nodownload" :style="{ maxWidth: '100%', maxHeight: '100%' }" disablePictureInPicture
														@click="openDialogue(
																apiUrl + '/downloadfile/' + messagElem + '?access_token=' + token + '&deviceid=' + deviceId,
																messagElem,
																message.last_user_update,
																'video',
																message.id,
																message.fileId.replace('[', '').replace(']', ''),
																message.completeData,
														)">
													</video>
													<div class="play-overlay">▶</div>
												</div>
											</div>
										</template>
									</div>
								</div>
							</v-alert>
						</v-timeline-item>
					</v-timeline>
				</v-card>
			</v-col>
			<v-col cols="3" align-self="start">
				<v-card>
					<v-subheader>Dettagli</v-subheader>
					<v-divider></v-divider>
					<div style="
						width:100%;
						display:flex;
						justify-content:center;
					">
						<v-btn color="green" block @click="exportChatWord()" :loading="exportWordLoading" style="
							min-width: 50% !important;
							max-width: 50%;
							margin-top: 10px;
							margin-bottom: 10px;
						">
							<v-icon>mdi-file-document-arrow-right</v-icon>Esporta Word
						</v-btn>
					</div>
				</v-card>
			</v-col>
			<v-col cols="12">
				<unknown-chat ref="unknownChat" :apiUrl="apiUrl" :token="token">
				</unknown-chat>
			</v-col>
		</v-row>
	</div>
</template>
<style scoped>
	.v-expansion-panel-content__wrap {
		padding: 0px !important;
	}

	.my-audio-player {
		width: 100%;
	}

	.tooltip-container {
		position: relative;
		display: inline-block;
		cursor: pointer;
	}

	.btn-cont {
		display: flex;
		width: auto;
	}

	.tooltip {
		visibility: hidden;
		width: 124px;
		height: auto;
		font-size: 9px;
		text-align: center !important;
		z-index: 1;
		background-color: #000;
		color: #fff;
		text-align: center;
		border-radius: 4px;
		padding: 6px;
		text-align: center;
		position: absolute;
		top: 10px;
		margin-bottom: 24px;
		left: 50%;
		transform: translateX(-50%);
		opacity: 0;
		transition: opacity 0.3s ease;
	}

	.tooltip-container:hover .tooltip {
		visibility: visible;
		opacity: 1;
	}

    .video-wrapper {
        position: relative;
        display: inline-block;
        cursor: pointer;
    }

    .play-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 2rem;
        color: white;
        pointer-events: none;
    }
</style>