<script>
	import axios from '../../axios';
	import { apiUrl } from "@/common/endpoint.const";
	import Console from "@/store/models/console.model";

	export default {
		name: 'DeviceConf',
		data() {
			return {
				config: null,
				loading: false
			}
		},
		computed: {
			console() {
				return Console.query()
					.with("device")
					.with("cmdHistory")
					.where("deviceId", localStorage.getItem("deviceId"))
					.first();
			},
		},
		methods: {
			async commandUpdateConf() {
				await this.console.sendCommand({
					cmd: "PRINT_CONFIGURATION",
					params: [],
					user: JSON.parse(localStorage.getItem('user') || '') || {}
				});
				this.loading = true;
				await new Promise(resolve => setTimeout(resolve, 5000));
				this.loading = false;
				await this.getDeviceConfig();
			},
			async sendCommand(key, value) {
				console.log("key: ", key, " value: ", value);
				await this.console.sendCommand(
					{
						cmd: "UPDATE_CONFIGURATION",
						params: [
							key,
							value
						],
						user: JSON.parse(localStorage.getItem('user') || '') || {}
					});
				await new Promise(resolve => setTimeout(resolve, 5000));
				this.commandUpdateConf();
			},
			async getDeviceConfig() {
				await axios.get(`${apiUrl}/device/get-phone-configuration`).then(response => {
					if (response.status === 200) {
						let jsonString = response.data.text;

						try {
							this.config = JSON.parse(jsonString);
						} catch (error) {
							console.error("Errore nel parsing JSON: ", error);
						}
					}
				}).catch(error => {
					console.log(error);
				});
			}
		},

		async mounted() {
			await this.getDeviceConfig();
		},
		async created() {
			if (!this.console) {
				console.log("creating console...");
				await Console.insertOrUpdate({
					data: {
						deviceId: localStorage.getItem("deviceId"),
						device: this.device,
					},
				})
			}
		}
	}
</script>

<template>
	<div>
		<v-card>
			<v-card-title class="headline">
				<div class="single-cont">
					<span>Configurazione Dispositivo</span>
					<v-btn @click="commandUpdateConf" color="red">
						<v-progress-circular v-if="loading" indeterminate></v-progress-circular>
						<v-icon v-else>mdi-update</v-icon>
					</v-btn>
				</div>
			</v-card-title>
			<v-card-text>
				<div v-for="(value, key) in config" :key="key" class="config-field">
					<div class="single-cont">
						<v-text-field :id="`config-${key}`"
													v-model="config[key]"
													:label="key"
													required />
						<v-btn @click="sendCommand(key,config[key])" color="primary">Cambia Valore</v-btn>
					</div>
				</div>
			</v-card-text>
		</v-card>
	</div>
</template>

<style>
	.single-cont {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
</style>
