import {Model} from "@vuex-orm/core";
import Device from "@/store/models/device.model";

export default class Sensor extends Model{
  static entity = 'sensors';

  static fields() {
    return {
      id: this.attr(null),
      name: this.string('GENERIC SENSOR'),
      type: this.number(0),
      identifier: this.number(null),
      deviceId: this.attr(null),
      device: this.belongsTo(Device, 'deviceId'),
    }
  }
}