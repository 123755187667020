import {Model} from "@vuex-orm/core";
import Relevance from "@/store/models/relevance.model";

export default class RelevanceEvent extends Model{
  static entity = 'relevanceEvents';

  static fields() {
    return {
      id: this.number(0).nullable(),
      label: this.attr(null),
      editing: this.boolean(false),
      relevance_grade: this.attr(null),
      happens_at: this.attr(null),
      created_at: this.attr(null),
      last_modified: this.attr(null),
      relevanceId: this.attr(null),
      relevance: this.hasOne(Relevance, 'relevanceId')
    }
  }
}