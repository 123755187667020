import {Model} from "@vuex-orm/core";
import Device from "@/store/models/device.model";

export default class User extends Model{
  static entity = 'users';

  static fields(){
    return {
      id: this.attr(null),
      username: this.attr(null),
      email: this.attr(null),
    }
  }
}