<template>
  <v-list-group>
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-subtitle>
          Dettagli file
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>

    <v-list-item>
      <v-row>
        <v-col cols="6">Nome file</v-col>
        <v-col cols="6" class="text-caption">
          <span>
            {{ file.name }}
          </span>
        </v-col>
      </v-row>
    </v-list-item>

    <v-list-item>
      <v-row>
        <v-col cols="6">Grandezza</v-col>
        <v-col cols="6" class="text-caption">
          <span>
            {{ file.size | fileSize }}
          </span>
        </v-col>
      </v-row>
    </v-list-item>

    <v-list-item>
      <v-row>
        <v-col cols="6">Trasferito il</v-col>
        <v-col cols="6" class="text-caption">
          <span>
            {{ new Date(file.transfer_at).toLocaleString() }}
          </span>
        </v-col>
      </v-row>
    </v-list-item>

    <v-list-item>
      <v-row>
        <v-col cols="6">Ultima modifica</v-col>
        <v-col cols="6" class="text-caption">
          <span>
            {{ new Date(file.last_modified).toLocaleString() }}
          </span>
        </v-col>
      </v-row>
    </v-list-item>
  </v-list-group>
</template>

<script>
export default {
  name: "FileDetails",
  props: ['file', 'duration'],
  created() {
    console.log("this.duration: ", this.duration);
  },
  methods: {
    sumDurationAndLastModified() {
      const fileName = this.file.name;
      const dateTimeString = fileName.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)[0];
      const date = new Date(dateTimeString);
      const [hours, minutes, seconds] = this.duration.split(':').map(Number);
      const durationInMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
      const newDate = new Date(date.getTime() + durationInMilliseconds);
      console.log("newDate: ", newDate);
      return newDate.toLocaleString();
    }
  }
}
</script>

<style scoped>
</style>