import {Model} from "@vuex-orm/core";
import Device from "@/store/models/device.model";
import Transcription from "@/store/models/transcription.model";
import Notification from "@/store/models/notification.model";
import {DOWNLOAD_FILE, REFRESH_DIRECTORY, REFRESH_DIRECTORY_COMMAND, SERVER_FILE_UPLOADED} from "@/store/actions.type";
import Console from "@/store/models/console.model";
import { AsyncSubject } from 'rxjs';

export default class File extends Model{
  static entity = 'files';
  static fileDownloaded = new AsyncSubject(null);
  static functionToReloadData;
  static fields() {
    return {
      id: this.attr(null),
      name: this.string(null),
      created_at: this.attr(null),
      type: this.attr(null),
      local_path: this.string(null),
      remote_path: this.string(null),
      extension: this.string(null),
      is_dir: this.boolean(null),
      size: this.number(null),
      last_modified: this.attr(null),
      is_downloading: this.attr(null),
      was_downloaded: this.boolean(null),
      remote_deleted: this.boolean(null),
      deviceId: this.attr(null),
      device: this.belongsTo(Device, 'deviceId'),
      relevance: this.hasOne(Transcription, 'fileId'),
      parentId: this.number(null).nullable(),
      children: this.hasMany(File, 'parentId')
    }
  }
}

export const file = {
  actions: {
    async [DOWNLOAD_FILE](context, params){
      console.log(params);
      console.log('downloading file... '+params.file)
      File.update({
        data: {
          id: params.fileId,
          is_downloading: true,
        }
      })
      File.fileDownloaded.next(params.fileId);
      const shell = Console.query().with('device').where('deviceId', params.device.id).first()
      await shell.sendCommand({
        cmd: "DOWNLOAD",
        params: [params.file],
        user: params.user,
      })
      if(params.functionToReloadData){
        this.functionToReloadData = params.functionToReloadData;
      }
    },
    async [SERVER_FILE_UPLOADED](context, params){
      const file = params.data.file;
      console.log(SERVER_FILE_UPLOADED);
      await File.update({
        data: {
          id: file.id,
          local_path: file.local_path,
          was_downloaded: true,
          is_downloading: false,
        },
      })
      Notification.insert({
        data: {
          icon: 'mdi-progress-upload',
          color: 'yellow darken-3',
          title: `Nuovo file scaricato ${file.name}`,
          subtitle: 'un nuovo file è stato scaricato dal telefono',
          actionLabel: 'OK',
          actionIcon: 'mdi-check',
          deviceId: file.deviceId,
        }
      })
      if(this.functionToReloadData){
        this.functionToReloadData();
      }
    },
    async [REFRESH_DIRECTORY_COMMAND](context, params){
      const shell = await Console.query().with('device').where('deviceId', params.device.id).first();
      let epoch = window.config.epochUpdate;
      let paramsArr;
      if(epoch != null){
        paramsArr = [params.file,epoch];
      }
      else{
        paramsArr = [params.file];
      }
      let res = await shell.sendCommand({
        cmd: "LS",
        params: paramsArr,
        user: params.user,
      });
      if(params.functionToReloadData){
        this.functionToReloadData = params.functionToReloadData;
      }
      return res;
    },
    async [REFRESH_DIRECTORY](context, params){
      console.log("REFRESH_DIRECTORY");
      console.log(await File.insertOrUpdate({
        data: {
          ...params.data
        }
      }))
      Notification.insert({
        data: {
          icon: 'mdi-progress-upload',
          color: 'yellow darken-3',
          title: `Aggiornamento cartella ${params.data.name}`,
          subtitle: `la cartella ${params.data.name} è stata aggiornata correttamente`,
          actionLabel: 'OK',
          actionIcon: 'mdi-check',
          deviceId: params.data.deviceId,
        }
      })
      console.log("this.functionRefreshFolder: ",this.functionToReloadData);
      if(this.functionToReloadData){
        this.functionToReloadData();
      }
    }
  }
}
