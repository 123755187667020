import { Model } from "@vuex-orm/core";
import ChatMessage from "@/store/models/chat-message.model";
import Device from "@/store/models/device.model";
import Contact from "@/store/models/contact.model";

export default class Chat extends Model {
  static entity = 'chats';

  static fields() {
    return {
      id: this.attr(null),
      interlocutor: this.attr(null),
      last_activity: this.attr(null),
      messages: this.hasMany(ChatMessage, 'chatId'),
      contactId: this.attr(null),
      contact: this.belongsTo(Contact, 'contactId'),
      deviceId: this.attr(null),
      device: this.belongsTo(Device, 'deviceId'),
    }
  }
}