<template>
	<v-card>
		<v-subheader>Dettagli rilevanza</v-subheader>
		<v-divider></v-divider>
		<v-card-text>
			<v-list-group class="border-bottom__1px">
				<template v-slot:activator>
					<v-list-item-content>
						<v-list-item-subtitle>
							Informazioni varie
						</v-list-item-subtitle>
					</v-list-item-content>
				</template>

				<v-list dense>
					<v-list-item>
						<v-row>
							<v-col cols="6">Progressivo</v-col>
							<v-col cols="6" class="text-caption">
								<span>
									{{this.relevance.id}}
								</span>
							</v-col>
						</v-row>
					</v-list-item>

					<v-list-item>
						<v-row>
							<v-col cols="6">Data acquisizione</v-col>
							<v-col cols="6" class="text-caption">
								<span>
									{{new Date(this.relevance.created_at).toLocaleString()}}
								</span>
							</v-col>
						</v-row>
					</v-list-item>

					<v-list-item v-if="this.relevance.device">
						<v-row>
							<v-col cols="6">Dispositivo d'origine</v-col>
							<v-col cols="6" class="text-caption">
								<span>
									{{this.relevance.device.manufacturer}} {{this.relevance.device.name}}
								</span>
							</v-col>
						</v-row>
					</v-list-item>

					<v-list-item v-if="this.relevance.Type">
						<v-row>
							<v-col cols="6">Tipo di acquisizione</v-col>
							<v-col cols="6" class="text-caption">
								<span>
									{{this.relevance.type}}
								</span>
							</v-col>
						</v-row>
					</v-list-item>
					<slot name="actions"></slot>
				</v-list>
			</v-list-group>
			<transcription-details :transcription="this.relevance.transcription"></transcription-details>
			<relevance-event-details @focusOnEvent="eventFocused" :events="this.relevance.events" :relevance="this.relevance"></relevance-event-details>
			<div v-if="this.relevance.file">
				<file-details :file="this.relevance.file" :duration="duration"></file-details>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
	import FileDetails from "@/components/file/FileDetails";
	import TranscriptionDetails from "@/components/relevance/transcription/TranscriptionDetails";
	import RelevanceEventDetails from "@/components/relevance/RelevanceEventDetails";
	import { timestamp } from "@/common/dateFilterFormat";

	export default {
		name: "RelevanceDetails",
		components: {
			FileDetails,
			TranscriptionDetails,
			RelevanceEventDetails
		},
		props: ['relevance', 'duration'],
		data() {
			console.log("this.duration: ", this.duration)
			return {
				relevance_grade: this.relevance.relevance_grade,
				timestamp
			}
		},
		watch: {
			relevance_grade(actual, old) {
				console.log(old, actual)
				if (actual !== old)
					this.$emit('updateRelevanceGrade', actual)
			}
		},
		methods: {
			eventFocused(event) {
				this.$emit('focusOnEvent', event);
			}
		}
	}
</script>

<style scoped>
	.border-bottom__1px {
		border-bottom: 1px solid rgba(255, 255, 255, 0.12);
	}

	.v-card__text {
		padding: 0px !important;
	}

	.theme--dark.v-subheader {
		color: white;
		font-weight: bold;
	}
</style>