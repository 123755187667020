<template>
	<div>
		<v-row class="pa-4">
			<v-col cols="4" v-if="service.length">
				<v-list>
					<div class="container-service">
						<div class="filter-container">
							<v-subheader style="padding:0px;">Filtra i dati del keylogger</v-subheader>
							<v-divider />
							<v-text-field v-model="filterText"
														label="Inserisci il testo da filtrare">
							</v-text-field>
							<div style="display:flex;align-items: center;">
								<v-menu :close-on-content-click="false"
												:nudge-right="40"
												transition="scale-transition"
												offset-y
												min-width="290px">
									<template v-slot:activator="{ on }">
										<v-text-field v-model="filterDateFrom"
																	label="Dalla data"
																	prepend-icon="mdi-calendar"
																	readonly
																	v-on="on" />
									</template>
									<v-date-picker v-model="filterDateFrom" @input="menu = false" />
								</v-menu>
								<v-menu :close-on-content-click="false"
												:nudge-right="40"
												transition="scale-transition"
												offset-y
												min-width="290px">
									<template v-slot:activator="{ on }">
										<v-text-field v-model="filterDateTo"
																	label="Alla data"
																	prepend-icon="mdi-calendar"
																	readonly
																	v-on="on" />
									</template>
									<v-date-picker v-model="filterDateTo" @input="menu = false" />
								</v-menu>
								<div class="tooltip">
									<v-btn color="green" block @click="resetFilterData()">
										<v-icon style="font-size: 20px;">mdi-delete</v-icon>
									</v-btn>
									<span class="tooltiptext">Resetta i filtri date</span>
								</div>
							</div>
						</div>
						<v-divider />
						<v-subheader style="padding:0px;">Clicca un servizio per visualizzare i log</v-subheader>
						<v-divider />
						<template v-for="(app) in service">
							<v-list-item @click="getLogByServiceAndFilter(app.app,true)">
								<div :class="activeServiceName == app.app ? 'active' : ''">
									{{app.app}}
								</div>
							</v-list-item>
							<v-divider />
						</template>
						<v-pagination :total-visible="7"
													v-model="pageApp"
													:length="Math.ceil(totalAppElement / limitApp)"
													@input="changePageService" />
					</div>
				</v-list>
			</v-col>

			<v-col id="chat-timeline" cols="8">
				<v-list>
					<v-subheader>Log</v-subheader>
					<v-divider />
					<v-card-text v-if="activeLogs.length == 0">
						Seleziona una servizio per visualizzarne i log
					</v-card-text>
					<template v-for="log in activeLogs">
						<v-list-item>
							<div style="display:flex; justify-content:space-between;width:100%;align-items: center;">
								<span>
									{{ log.text }}
								</span>
								<div style="display:flex; align-items:end;">
									<span style="margin-right: 10px;">
										{{ log.created_at ? new Date(log.created_at).toLocaleString() : "" }}
										<br>
										id: keylog-{{ log.id }}
									</span>
									<v-btn @click="createRelevanceFromKeylog(log.id, log.created_at)" :disabled="relevanceCreated[log.id]">Crea rilevanza</v-btn>
								</div>
							</div>
						</v-list-item>
						<v-divider></v-divider>
					</template>
					<v-pagination v-if="activeLogs.length > 0"
												:total-visible="7"
												v-model="pageLog"
												:length="Math.ceil(totalLogElement / limitLog)"
												@input="changePageLog" />
				</v-list>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import DeviceInfo from "@/components/device/DeviceInfo";
	import Avatar from "vue-avatar";
	import axios from '../../axios';
	import { apiUrl } from '@/common/endpoint.const';
	import Vue from "vue";
	import Swal from 'sweetalert2';

	export default {
		name: "DeviceKeylogger",
		components: {
			DeviceInfo,
			Avatar,
		},
		data() {
			return {
				keylog: [],
				activeLogs: [],
				activeServiceName: "",
				service: [],
				filterText: "",
				filterDateFrom: null,
				filterDateTo: null,
				token: localStorage.getItem('token'),
				totalAppElement: 0,
				totalLogElement: 0,
				pageApp: 1,
				limitApp: 10,
				limitLog: 10,
				pageLog: 1,
				relevanceCreated: {},
			}
		},
		watch: {
			filterText() {
				this.pageLog = 1;
				this.getLogByServiceAndFilter(this.activeServiceName);
			},
			filterDateFrom() {
				this.pageLog = 1;
				this.getLogByServiceAndFilter(this.activeServiceName);
			},
			filterDateTo() {
				this.pageLog = 1;
				this.getLogByServiceAndFilter(this.activeServiceName);
			},
		},
		methods: {
			createRelevanceFromKeylog(keylogId, createdAt) {
				axios.put(`${apiUrl}/relevance/create-from-keylog`, {
					id: keylogId,
					created_at: createdAt
				}).then((response) => {
					this.updateRelevanceCreated();
					const relevance = response.data;
					Swal.fire({
						icon: 'success',
						title: 'Rilevanza creata con successo!',
						text: `Rilevanza ${relevance.id} creata per il keylog ${keylogId}`,
						showConfirmButton: true,
						confirmButtonText: 'Visualizza',
						showCancelButton: true,
					}).then((result) => {
						if (result.isConfirmed) {
							this.$router.push(`/device/relevance/${relevance.id}`);
						}
					})
				}).catch((error) => {
					console.log(error);
				});
			},
			async updateRelevanceCreated() {
				this.relevanceCreated = {};
				for (var log in this.activeLogs) {
					var response = await axios.get(`${apiUrl}/relevance/relevance-by-keylog/${this.activeLogs[log].id}`);
					Vue.set(this.relevanceCreated, this.activeLogs[log].id, response.data ? true : false);
				}
			},
			changePageService(page) {
				if (page < 1 || page > Math.ceil(this.totalAppElement / this.limitApp)) {
					return;
				}
				this.pageApp = page;
				this.getService();
			},
			changePageLog(page) {
				if (page < 1 || page > Math.ceil(this.totalLogElement / this.limitLog)) {
					return;
				}
				this.pageLog = page;
				this.getLogByServiceAndFilter(this.activeServiceName);
			},
			getLogByServiceAndFilter(serviceName, resetPage = false) {
				if (resetPage) {
					this.pageLog = 1;
				}
				let objFilter = {
					limit: this.limitLog,
					page: this.pageLog,
					filter: {
						searchTerms: this.filterText,
						startDate: this.filterDateFrom,
						endDate: this.filterDateTo,
						serviceName: serviceName
					}
				}
				console.log(objFilter);
				this.activeServiceName = serviceName;
				axios.post(`${apiUrl}/device/keylog/app`, objFilter).then((response) => {
					console.log("response: ", response);
					this.activeLogs = response.data.response;
					this.totalLogElement = response.data.count;
					this.updateRelevanceCreated();
				}).catch((error) => {
					console.log(error);
				});
			},
			resetFilterData() {
				this.filterDateFrom = null;
				this.filterDateTo = null;
			},
			getService() {
				axios.post(`${apiUrl}/device/keylog/app/pagination`, {
					page: this.pageApp,
					limit: this.limitApp
				}).then((response) => {
					this.service = response.data.response;
					this.totalAppElement = response.data.count;
				}).catch((error) => {
					console.log(error);
				});
			}
		},
		computed: {

		},
		async created() {
			// this.keylog = await Device.dispatch(FETCH_DEVICE_KEYLOG, {id:localStorage.getItem("deviceId")});
			this.getService();
		}
	}
</script>

<style scoped>
	.container-service {
		padding: 10px;
	}

	.active {
		padding: 10px;
		background: #2380f5d6;
		width: auto;
		border-radius: 10px;
	}

	.tooltip {
		position: relative;
		display: inline-block;
		border-bottom: 1px dotted black;
	}

		.tooltip .tooltiptext {
			visibility: hidden;
			width: 120px;
			background-color: #555;
			color: #fff;
			text-align: center;
			font-size: 13px;
			border-radius: 6px;
			padding: 5px 0;
			position: absolute;
			z-index: 1;
			bottom: 125%;
			left: 50%;
			margin-left: -60px;
			opacity: 0;
			transition: opacity 0.3s;
		}

			.tooltip .tooltiptext::after {
				content: "";
				position: absolute;
				top: 100%;
				left: 50%;
				margin-left: -5px;
				border-width: 5px;
				border-style: solid;
				border-color: #555 transparent transparent transparent;
			}

		.tooltip:hover .tooltiptext {
			visibility: visible;
			opacity: 1;
		}
</style>