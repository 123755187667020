<template>
	<v-container>
		<v-card>
			<v-card-title class="headline">Cambia Password</v-card-title>
			<form @submit.prevent="handleSubmit">
				<v-card-text>
					<label for="currentPassword">Password attuale</label>
					<input type="password" id="currentPassword" class="password-field"
								 :class="{ 'password-field__error': wrongOldPassword }" v-model="oldPassword" />
					<label for="newPassword">Nuova password</label>
					<input type="password" id="newPassword" class="password-field"
								 :class="{ 'password-field__error': wrongNewPassword }" v-model="newPassword" />
					<label for="confirmPassword">Conferma password</label>
					<input type="password" id="confirmPassword" class="password-field"
								 :class="{ 'password-field__error': wrongConfirmPassword }" v-model="confirmPassword" />
					<v-btn type="submit">Salva</v-btn>
				</v-card-text>
			</form>
		</v-card>
	</v-container>
</template>

<script>
	import Swal from 'sweetalert2';
	import { apiUrl } from "@/common/endpoint.const";
	import axios from '../../axios';

	export default {
		name: 'DevicePassword',
		data() {
			return {
				oldPassword: '',
				newPassword: '',
				confirmPassword: '',
				wrongOldPassword: false,
				wrongNewPassword: false,
				wrongConfirmPassword: false,
			};
		},
		methods: {
			resetErrors() {
				this.wrongOldPassword = false;
				this.wrongNewPassword = false;
				this.wrongConfirmPassword = false;
			},
			validate() {
				this.resetErrors();
				if (this.newPassword === "" || this.oldPassword === "" || this.confirmPassword === "") {
					Swal.fire({
						title: 'Errore',
						text: 'Devi compilare tutti i campi',
						icon: 'error',
						confirmButtonColor: '#3085d6',
					});
					this.wrongOldPassword = this.oldPassword === "";
					this.wrongNewPassword = this.newPassword === "";
					this.wrongConfirmPassword = this.confirmPassword === "";
					return false;
				}
				else if (this.newPassword !== this.confirmPassword) {
					Swal.fire({
						title: 'Errore',
						text: 'Le password non corrispondono',
						icon: 'error',
						confirmButtonColor: '#3085d6',
					});
					this.wrongNewPassword = true;
					this.wrongConfirmPassword = true;
					return false;
				}
				return true;
			},
			async handleSubmit() {
				if (this.validate()) {
					await this.changePassword();
				}
			},
			async changePassword() {
				const user = JSON.parse(localStorage.getItem('user'));
				console.log('user: ', user);
				await axios.post(`${apiUrl}/auth/changepassword`, {
					id: user.id,
					oldPassword: this.oldPassword,
					newPassword: this.newPassword
				}).then((response) => {
					if (response != null) {
						if (response.data.status == false) {
							const error = response.data.message;
							if (error) {
                                Swal.fire({
                                    title: 'Errore',
									text: 
										error == "USER_NOT_FOUND"
											? "Utente non trovato"
											: error == "SAME_PASSWORD"
												? "La nuova password non puo essere la stessa di quella attuale"
												: error == "WRONG_PASSWORD"
													? "La password attuale non corrisponde"
													: error,
                                    icon: 'error',
                                    confirmButtonColor: '#3085d6',
                                });
							}
							switch (error) {
                                case "SAME_PASSWORD": {
                                    this.wrongNewPassword = true;
                                    this.wrongConfirmPassword = true;
                                    break;
                                }
								case "WRONG_PASSWORD": {
									this.wrongOldPassword = true;
									break;
								}
                                case "USER_NOT_FOUND":
								default: { break; }
							}
						} else {
							const r = response.data.events; ///TODO: Valutare se gestire le info utente ritornate magari in una pagina dedicata
							console.log(r);
							Swal.fire({
								title: 'Successo',
								text: 'Password modificata con successo',
								icon: 'success',
								confirmButtonColor: '#3085d6',
							});
							const deviceId = localStorage.getItem("deviceId");
							if (deviceId) this.$router.push(`/device/dashboard`);
							else this.$router.push(`/`);
						}
					} else {
						console.log('Risposta vuota');
						Swal.fire({
							title: 'Errore non gestito',
							text: "Non è stato possibile identificare l'errore",
							icon: 'error',
							confirmButtonColor: '#3085d6',
						});
					}
				}).catch((error) => {
					Swal.fire({
						title: error.response ? 'ERROR ' + error.response.status : null,
						text: error.message,
						icon: 'error',
						confirmButtonColor: '#3085d6',
					});
					console.log(error);
				});
			},
		},
	}
</script>

<style>
	.password-field {
		width: 100%;
		margin-bottom: 10px;
		margin-top: 10px;
		padding: 10px;
		border-radius: 10px;
		border: 1px solid rgba(255, 255, 255, 0.12);
		background-color: rgba(255, 255, 255, 0.12);
		color: white;
		font-size: 14px;
		outline: none;
		box-sizing: border-box;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
		transition: all 0.3s;
	}

	.password-field__error {
		border-color: red;
	}
</style>