<template>
  <div>
    <v-row class="align-content-center">
      <v-col cols="4"></v-col>
      <v-col cols="4" class="pa-4">
        <v-card>
          <v-card-title>Login</v-card-title>
          <v-card-text class="text-caption">
            <span>Effettua il login con il tuo account operatore</span>
            <v-text-field
                placeholder="username"
                v-model="email"
                color="primary"
            ></v-text-field>
            <v-text-field
                placeholder="password"
                v-model="password"
                type="password"
                color="primary"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn block dark color="primary" @click="login">Accedi</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {LOGIN} from "@/store/actions.type";
import {SOCKET_CONNECT} from "@/store/actions.type";
export default {
  name: "Login",
  created() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  data() {
    
    return {
      email: "",
      password: ""
    }
  },
  methods: {
    handleKeyDown(e) {
      if (e.key == "Enter") {   
        console.log(e.key);
        this.login();
      }
    },
    login(){
      this.$store.dispatch(LOGIN, {username: this.email, password: this.password})
          .then(async() => { 
              await this.$store.dispatch(SOCKET_CONNECT)
  
              if (this.$store.getters.currentUser.id){
                this.$router.push('/twofactorregistration');
              }
            })
          .catch(err => console.log(err));
    }
  }
}
</script>