<template>
  <div>
    <v-list-group>
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-subtitle>
            Dettagli trascrizione
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <div v-if="transcription">
        <v-list-item>
          <v-row>
            <v-col cols="6">Contenuto</v-col>
            <v-col cols="6" class="text-caption">
            <span>
              {{spoiler}}
            </span>
            </v-col>
          </v-row>
        </v-list-item>

        <v-list-item v-if="transcription.user">
          <v-row>
            <v-col cols="6">Trascrizione creata da</v-col>
            <v-col cols="6" class="text-caption">
            <span>
              {{transcription.user.username}}
            </span>
            </v-col>
          </v-row>
        </v-list-item>

        <v-list-item>
          <v-row>
            <v-col cols="6">Creata il</v-col>
            <v-col cols="6" class="text-caption">
            <span>
              {{ FormatDate(transcription.created_at) }}
            </span>
            </v-col>
          </v-row>
        </v-list-item>

        <v-list-item>
          <v-row>
            <v-col cols="6">Ultima modifica</v-col>
            <v-col cols="6" class="text-caption">
            <span>
              {{ FormatDate(transcription.last_modified) }}
            </span>
            </v-col>
          </v-row>
        </v-list-item>
      </div>

      <div v-else>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Trascrizione non ancora inserita</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list-group>
    <v-divider></v-divider>
  </div>
</template>

<script>
import {timestamp} from "@/common/dateFilterFormat";
import moment from 'moment';

export default {
  name: "TranscriptionDetails",
  props: ['transcription'],
  data(){
    return {
      timestamp
    }
  },
  computed: {
    spoiler(){
      if(this.transcription){
        let spoiler = this.transcription.text.slice(0, 50)
        if(this.transcription.text.length > 50)
          spoiler += '...';
        return spoiler
      }
      return '';
    },
  },
  methods: {
    FormatDate(date){
      let dateElem = moment(date);

      let second = dateElem.seconds();
      let minute = dateElem.minutes();
      let hour = dateElem.hours();
      let day = dateElem.date();
      let month = dateElem.month();
      let years = dateElem.year();

      return `${day}/${month}/${years} ${hour}:${minute}:${second}`;
    }
  }
}
</script>

<style scoped>

</style>