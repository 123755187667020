import { Model } from "@vuex-orm/core";
import Device from "./device.model";
import {DEVICE_STATE_UPDATE} from "@/store/actions.type";
import Notification from "@/store/models/notification.model";

export default class DeviceState extends Model {
  static entity = 'deviceStates';

  static fields(){
    return {
      id: this.attr(null),
      battery: this.attr(0),
      available_memory: this.attr(0),
      gps_is_on: this.attr(false),
      wifi_is_on: this.attr(false),
      is_calling: this.attr(false),
      opened_app: this.attr(''),
      mic_is_available: this.attr(null),
      usb_is_plugged: this.attr(false),
      notify_listener: this.attr(false),
      accessibility: this.attr(false),
      is_connected: this.attr(false),
      deviceId: this.attr(null),
      device: this.belongsTo(Device, 'deviceId'),
      isUsageStatsEnabled: this.attr(false),
      isAccessibilitySettingsOn: this.attr(false),
      isNotificationListenerOn: this.attr(false),
      so_version: this.attr(''),
      isAutoInstallCheck: this.attr(false),
      isBatteryOptimizationIgnored: this.attr(false),
      isAutomaticPermission: this.attr(false),
      isAdmin: this.attr(false),
      
    }
  }
}

export const deviceState = {
  actions: {
    
    async [DEVICE_STATE_UPDATE](context, params){

        const state = DeviceState.query().where('deviceId', params.data.deviceId).first();
        console.log('updating device state...', state, params);
        await DeviceState.update({
          data: {
            id: state.id,
            battery: params.data.battery != undefined ? +params.data.battery : state.battery,
            wifi_is_on: params.data.wifi_is_on != undefined ? params.data.wifi_is_on : state.wifi_is_on,
            usb_is_plugged: params.data.usb_is_plugged !== undefined ? params.data.usb_is_plugged : state.usb_is_plugged,
            is_connected: params.data.is_connected !== undefined ? params.data.is_connected : state.is_connected,
            mic_is_available: params.data.mic_is_available !== undefined ? params.data.mic_is_available : state.mic_is_available,
            isUsageStatsEnabled: params.data.isUsageStatsEnabled !== undefined ? params.data.isUsageStatsEnabled : state.isUsageStatsEnabled,
            isAccessibilitySettingsOn: params.data.isAccessibilitySettingsOn !== undefined ? params.data.isAccessibilitySettingsOn : state.isAccessibilitySettingsOn,
            so_version: params.data.so_version !== undefined ? params.data.so_version : state.so_version,
            isNotificationListenerOn: params.data.isNotificationListenerOn !== undefined ? params.data.isNotificationListenerOn : state.isNotificationListenerOn,
            isAutoInstallCheck: params.data.isAutoInstallCheck !== undefined ? params.data.isAutoInstallCheck : state.isAutoInstallCheck,
            isBatteryOptimizationIgnored: params.data.isBatteryOptimizationIgnored !== undefined ? params.data.isBatteryOptimizationIgnored : state.isBatteryOptimizationIgnored,
            isAutomaticPermission: params.data.isAutomaticPermission !== undefined ? params.data.isAutomaticPermission : state.isAutomaticPermission,
            isAdmin: params.data.isAdmin !== undefined ? params.data.isAdmin : state.isAdmin,
          }
        })

      const noty = {
        icon: 'mdi-call',
        color: 'indigo darken-3',
        title: `Acquisizione audio `,
        subtitle: `Una registrazione ambientale è `,
        deviceId: params.data.deviceId,
      };

      if(params.data.mic_is_available !== undefined){
        const noty = {
          icon: 'mdi-microphone',
          color: 'indigo darken-3',
          title: `Acquisizione audio `,
          subtitle: `Una registrazione ambientale è `,
          deviceId: params.data.deviceId,
        };

        if(state.mic_is_available === true && params.data.mic_is_available === false){
          noty.title += "terminata"
          noty.subtitle += "terminata"
          Notification.insert({
            data: noty
          })
        } else if (state.mic_is_available === false && params.data.mic_is_available === true){
          noty.title += "iniziata"
          noty.subtitle += "iniziata"
          Notification.insert({
            data: noty
          })
        }
      }
    }
  }
}